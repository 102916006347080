// Accordion.js
import React, { useState, useMemo } from 'react';
import { switchConfiguration } from './config';
import placeholder from './assets/placeholder.jpg';

// Helper Function
function getUniqueCategories(materials) {
  return [...new Set(materials.flatMap((material) => material.category))];
}

function Accordion({
  title,
  materials,
  selected,
  onChange,
  customConfig,
  defaultRivestimento,
  type,
  resetMaterial
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(
    materials.length > 0 ? materials[0].category : null
  );
  const [openCollections, setOpenCollections] = useState({});
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const filteredMaterials = useMemo(() => {
    if (customConfig && customConfig.customCollection) {
      return materials.filter((material) =>
        customConfig.customCollection.includes(material.collection)
      );
    } else {
      // Category Filtering Logic
      return materials.filter((material) =>
        selectedCategory ? material.category === selectedCategory : true
      );
    }
  }, [customConfig, materials, selectedCategory]);

  const searchResults = useMemo(() => {
    if (!filterText.trim()) return [];

    return materials.flatMap((material) => material.items).filter((item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase())
    );
  }, [filterText, materials]);

  // Event Handlers
  const handleMaterialChange = (newMaterial) => {
    if (newMaterial) {
      switchConfiguration(newMaterial, type);
      setSelectedMaterial(newMaterial);
      onChange(newMaterial);
    }
  };
    // Reset selectedMaterial to defaultRivestimento when resetMaterial is called
    React.useEffect(() => {
      if (resetMaterial) {
        setSelectedMaterial(defaultRivestimento);
        resetMaterial();
      }
    }, [resetMaterial, defaultRivestimento]);

  const handleCategoryChange = (newCategory) => {
    const filteredByCategory = materials.filter((material) =>
      newCategory ? material.category === newCategory : true
    )
    setSelectedCategory(newCategory);
   
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCollectionToggle = (collection) => {
    setOpenCollections((prevState) => ({
      ...prevState,
      [collection]: !prevState[collection],
    }));
  };

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 hover:ay-cursor-pointer ay-border-b ay-border-b-black">
      {/* Accordion Header */}
      <div className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center" onClick={handleToggle}>
        <div className="ay-flex-1 ay-truncate">
          <h2 className="ay-font-bold ay-text-lg">{title}</h2>
          <h5 className="ay-text-lg ay-mt-1 ay-truncate">{selected?.slug || 'seleziona'}</h5>
        </div>
        <div className="ay-w-16 ay-h-16 ay-flex-shrink-0 ay-overflow-hidden ay-rounded-lg">
          <img
            className="ay-w-full ay-aspect-square ay-object-cover 
            group-hover:ay-border ay-border-black ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
            
            src={selected?.thumbnail || placeholder}
            alt={selected?.slug}
          />
        </div>
      </div>

      {isOpen && (
        <div>
          {/* Category Dropdown */}
          {type === 'imbottito' && !customConfig?.customCollection && (
            <div className="ay-mt-4">
              <select
                value={selectedCategory}
                onChange={(e) => handleCategoryChange(e.target.value)}
                className="ay-border ay-rounded-lg ay-boder-black ay-w-full ay-p-4"
              >
                <option value="">TUTTE LE CATEGORIE</option>
                {getUniqueCategories(materials).map((category) => (
                  <option key={category} value={category}>
                    {category.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/* Search Input */}
          {type === 'imbottito' && !customConfig?.customCollection && (
            <input
              type="text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder={`Cerca ${title}...`}
              className="ay-my-4 ay-p-4 ay-border ay-rounded-lg ay-w-full"
            />
          )}
          {/* Display search results */}
          {searchResults.length > 0 && (
            <div className="ay-search-results-popup">
              <div className="ay-category-items-grid ay-grid xl:ay-grid-cols-3 2xl:ay-grid-cols-3 lg:ay-grid-cols-2 md:ay-grid-cols-2 ay-grid-cols-3 ay-gap-4 ay-mt-2.5">
                {searchResults.map((result) => (
                  <div
                    key={result.slug}
                    className="ay-flex ay-flex-col ay-items-center ay-cursor-pointer"
                    onClick={() => handleMaterialChange(result)}
                  >
                    <div className="ay-overflow-hidden ay-rounded-lg">
                        <img
                          className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover 
                          group-hover:ay-border ay-border-black ay-scale-125 hover:ay-scale-200 ay-transition-transform"
                          src={result.thumbnail || placeholder}
                          alt={result.name}
                        />
                    </div>
                    <p className="ay-mt-2 ay-text-center">{result.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="ay-grid ay-grid-cols-2 ay-gap-4 ay-mt-4">
            {customConfig && customConfig.customCollection ? (
              // Render collections and their materials based on customCollection
              filteredMaterials
                .filter((material) => customConfig.customCollection.includes(material.collection))
                .map((material) => (
                  <CollectionItem
                    key={`${material.category}-${material.collection}`}
                    material={material}
                    isSelected={selected === material}
                    onToggle={handleCollectionToggle}
                    isOpen={openCollections[material.collection]}
                    onMaterialChange={handleMaterialChange}
                  />
                ))
            ) : (
              // Render individual materials if customMaterial is provided, or render collections and their materials otherwise
              customConfig && customConfig.customMaterial ? (
                filteredMaterials
                  .flatMap((material) => material.items)
                  .filter((item) => customConfig.customMaterial.includes(item.slug))
                  .map((item) => (
                    <CollectionItem
                      key={item.slug}
                      material={{ items: [item] }}
                      isSelected={selectedMaterial === item}
                      onMaterialChange={handleMaterialChange}
                      customConfig={customConfig}
                    />
                  ))
              ) : (
                filteredMaterials.map((material) => (
                  <CollectionItem
                    key={`${material.category}-${material.collection}`}
                    material={material}
                    isSelected={selected === material}
                    onToggle={handleCollectionToggle}
                    isOpen={openCollections[material.collection]}
                    onMaterialChange={handleMaterialChange}
                  />
                ))
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function CollectionItem({ material, isSelected, onToggle, isOpen, onMaterialChange, customConfig }) {
  return (
    <div className={`${customConfig ? 'ay-col-span-1' : 'ay-col-span-2'} ay-flex ay-flex-col`}> 
      {customConfig ? (
          <div className="">
          {customConfig.customMaterial.map((customItemSlug) => {
            const matchingMaterial = material.items.find(item => item.slug === customItemSlug);

            return matchingMaterial ? (
              <div
                key={customItemSlug}
                className={`material-item ay-group ay-relative ${
                  isSelected === matchingMaterial ? 'ay-active' : ''
                }`}
                onClick={() => onMaterialChange(matchingMaterial)}
              >
                <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                  <img
                    className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover group-hover:ay-border ay-border-black ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                    src={matchingMaterial.thumbnail || placeholder}
                    alt={matchingMaterial.name}
                    style={{ transformOrigin: 'center center' }}
                  />
                </div>
                <p className="ay-text-sm ay-mt-3.5">{matchingMaterial.name}</p>
              </div>
            ) : null;
          })}
        </div>
      ) : (
        <div>
          <div
            onClick={() => onToggle(material.collection)}
          >
            <div className="ay-border-b ay-border-gray-300 w-full ay-py-1.5">
            <h2 className={`ay-text-md ay-flex ay-justify-between ${isOpen ? 'ay-font-bold' : ''}`}>
              {material.collection.toUpperCase()}
              <span>{isOpen ? '-' : '+'}</span>
            </h2>
            </div>
          </div>
          {isOpen && (
            <div className="ay-category-items-grid ay-grid xl:ay-grid-cols-3 2xl:ay-grid-cols-3 lg:ay-grid-cols-2 md:ay-grid-cols-2 ay-grid-cols-3 ay-gap-4 ay-mt-2.5">
            {material.items.map((item) => (
                <div
                  key={item.slug}
                  className={`ay-material-item ay-group ay-relative ${
                    isSelected === item ? 'ay-active' : ''
                  }`}
                  onClick={() => onMaterialChange(item)}
                >
                  <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                    <img
                      className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover ay-group-hover:ay-border  ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                      src={item.thumbnail || placeholder}
                      alt={item.name}
                      style={{ transformOrigin: 'center center' }}
                    />
                  </div>
                  <p className="ay-text-sm ay-mt-3.5">{item.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { Accordion }; 