import fame_60005 from '../assets/catB/Fame/FAME_60005_Diffuse.jpg'
import fame_60017 from '../assets/catB/Fame/FAME_60017_Diffuse.jpg'
import fame_60019 from '../assets/catB/Fame/FAME_60019_Diffuse.jpg'
import fame_60051 from '../assets/catB/Fame/FAME_60051_Diffuse.jpg'
import fame_60078 from '../assets/catB/Fame/FAME_60078_Diffuse.jpg'
import fame_61003 from '../assets/catB/Fame/FAME_61003_Diffuse.jpg'
import fame_61044 from '../assets/catB/Fame/FAME_61044_Diffuse.jpg'
import fame_61047 from '../assets/catB/Fame/FAME_61047_Diffuse.jpg'
import fame_61060 from '../assets/catB/Fame/FAME_61060_Diffuse.jpg'
import fame_61135 from '../assets/catB/Fame/FAME_61135_Diffuse.jpg'
import fame_61136 from '../assets/catB/Fame/FAME_61136_Diffuse.jpg'
import fame_62067 from '../assets/catB/Fame/FAME_62067_Diffuse.jpg'
import fame_62068 from '../assets/catB/Fame/FAME_62068_Diffuse.jpg'
import fame_63016 from '../assets/catB/Fame/FAME_63016_Diffuse.jpg'
import fame_64055 from '../assets/catB/Fame/FAME_64055_Diffuse.jpg'
import fame_64058 from '../assets/catB/Fame/FAME_64058_Diffuse.jpg'
import fame_64089 from '../assets/catB/Fame/FAME_64089_Diffuse.jpg'
import fame_64119 from '../assets/catB/Fame/FAME_64119_Diffuse.jpg'
import fame_64165 from '../assets/catB/Fame/FAME_64165_Diffuse.jpg'
import fame_64167 from '../assets/catB/Fame/FAME_64167_Diffuse.jpg'
import fame_64169 from '../assets/catB/Fame/FAME_64169_Diffuse.jpg'
import fame_65082 from '../assets/catB/Fame/FAME_65082_Diffuse.jpg'
import fame_65084 from '../assets/catB/Fame/FAME_65084_Diffuse.jpg'
import fame_66032 from '../assets/catB/Fame/FAME_66032_Diffuse.jpg'
import fame_66075 from '../assets/catB/Fame/FAME_66075_Diffuse.jpg'
import fame_66130 from '../assets/catB/Fame/FAME_66130_Diffuse.jpg'
import fame_66131 from '../assets/catB/Fame/FAME_66131_Diffuse.jpg'
import fame_66133 from '../assets/catB/Fame/FAME_66133_Diffuse.jpg'
import fame_67004 from '../assets/catB/Fame/FAME_67004_Diffuse.jpg'
import fame_67005 from '../assets/catB/Fame/FAME_67005_Diffuse.jpg'
import fame_67016 from '../assets/catB/Fame/FAME_67016_Diffuse.jpg'
import fame_67067 from '../assets/catB/Fame/FAME_67067_Diffuse.jpg'
import fame_67068 from '../assets/catB/Fame/FAME_67068_Diffuse.jpg'
import fame_67069 from '../assets/catB/Fame/FAME_67069_Diffuse.jpg'
import fame_68004 from '../assets/catB/Fame/FAME_68004_Diffuse.jpg'
import fame_68035 from '../assets/catB/Fame/FAME_68035_Diffuse.jpg'
import fame_68143 from '../assets/catB/Fame/FAME_68143_Diffuse.jpg'
import fame_68145 from '../assets/catB/Fame/FAME_68145_Diffuse.jpg'
import fame_68147 from '../assets/catB/Fame/FAME_68147_Diffuse.jpg'
import fame_68148 from '../assets/catB/Fame/FAME_68148_Diffuse.jpg'

import silvertex_0001_MACADEMIA from '../assets/catB/Silvertex/SILVERTEX_0001_MACADEMIA.jpg'
import silvertex_0009_TAUPE from '../assets/catB/Silvertex/SILVERTEX_TAUPE_122-0009.jpg'
import silvertex_0077_LUGGAGE from '../assets/catB/Silvertex/SILVERTEX_LUGGAGE_122-0073-1.jpg'
import silvertex_1051_SISAL from '../assets/catB/Silvertex/SILVERTEX_1051_SISAL.jpg'
import silvertex_1077_CREAM from '../assets/catB/Silvertex/SILVERTEX_CREAM_122-1077.jpg'
import silvertex_12526_TEMPESTA from '../assets/catB/Silvertex/SILVERTEX_STORM_122-4002-scaled.jpg'
import silvertex_13330_BEIGE from '../assets/catB/Silvertex/SILVERTEX_13330 BEIGE.jpg'
import silvertex_13331_MOCCA from '../assets/catB/Silvertex/SILVERTEX_MOCCA_122-0005.jpg'
import silvertex_13332_METEOR from '../assets/catB/Silvertex/SILVERTEX_-13332-METEOR.jpg'
import silvertex_13335_COBRE from '../assets/catB/Silvertex/SILVERTEX_13335_COBRE.jpg'
import silvertex_13341_PISTACHO from '../assets/catB/Silvertex/SILVERTEX-MET_PISTACHO_13341.jpg'
import silvertex_13342_BASIL from '../assets/catB/Silvertex/SILVERTEX_BASIL_122-5020.jpg'
import silvertex_13348_BLACK from '../assets/catB/Silvertex/SILVERTEX_13348_BLACK.jpg'
import silvertex_2011_RED from '../assets/catB/Silvertex/SILVERTEX-MET_RED_122-2011.jpg'
import silvertex_2016_RASPERRY from '../assets/catB/Silvertex/SILVERTEX_RASPBERRY_122-2016.jpg'
import silvertex_2064_WINE from '../assets/catB/Silvertex/SILVERTEX_WINE_122-2064.jpg'
import silvertex_3007_SAPPHIRE from '../assets/catB/Silvertex/SILVERTEX-SAPPHIRE_122-3007.jpg'
import silvertex_3066_BALTIC from '../assets/catB/Silvertex/SILVERTEX_3066 BALTIC.jpg'
import silvertex_3067_DELFT from '../assets/catB/Silvertex/SILVERTEX_DELFT_122-3067.jpg'
import silvertex_4001_PLATA from '../assets/catB/Silvertex/SILVERTEX_PLATA_122-4001.jpg'
import silvertex_4004_ICE from '../assets/catB/Silvertex/SILVERTEX_ICE_122-40004.jpg'
import silvertex_4011_STERLING from '../assets/catB/Silvertex/SILVERTEX_4011_STERLING.jpg'
import silvertex_5009_SAGE from '../assets/catB/Silvertex/SILVERTEX_-SAGE_122-5009.jpg'
import silvertex_5063_FOREST from '../assets/catB/Silvertex/SILVERTEX-5063_FOREST.jpg'
import silvertex_6061_MANDARIN from '../assets/catB/Silvertex/SILVERTEX_MANDARIN_122-6061.jpg'
import silvertex_6062_MELON from '../assets/catB/Silvertex/SILVERTEX_MELON_122-6062.jpg'
import silvertex_6065_SAFRAN from '../assets/catB/Silvertex/SILVERTEX_SAFRAN_122-6065.jpg'
import silvertex_9002_CARBON from '../assets/catB/Silvertex/SILVERTEX_CARBON_122-9002.jpg'


import hestan_01 from '../assets/catB/Hestan/7035.01_highres.jpg'
import hestan_02 from '../assets/catB/Hestan/7035.02_highres.jpg'
import hestan_03 from '../assets/catB/Hestan/7035.03_highres.jpg'
import hestan_04 from '../assets/catB/Hestan/7035.04_highres.jpg'
import hestan_05 from '../assets/catB/Hestan/7035.05_highres.jpg'
import hestan_06 from '../assets/catB/Hestan/7035.06_highres.jpg'
import hestan_07 from '../assets/catB/Hestan/7035.07_highres.jpg'
import hestan_08 from '../assets/catB/Hestan/7035.08_highres.jpg'
import hestan_09 from '../assets/catB/Hestan/7035.09_highres.jpg'
import hestan_10 from '../assets/catB/Hestan/7035.10_highres.jpg'
import hestan_11 from '../assets/catB/Hestan/7035.11_highres.jpg'
import hestan_12 from '../assets/catB/Hestan/7035.12_highres.jpg'
import hestan_13 from '../assets/catB/Hestan/7035.13_highres.jpg'
import hestan_14 from '../assets/catB/Hestan/7035.14_highres.jpg'
import hestan_15 from '../assets/catB/Hestan/7035.15_highres.jpg'
import hestan_16 from '../assets/catB/Hestan/7035.16_highres.jpg'
import hestan_17 from '../assets/catB/Hestan/7035.17_highres.jpg'
import hestan_18 from '../assets/catB/Hestan/7035.18_highres.jpg'
import hestan_19 from '../assets/catB/Hestan/7035.19_highres.jpg'
import hestan_20 from '../assets/catB/Hestan/7035.20_highres.jpg'
import hestan_21 from '../assets/catB/Hestan/7035.21_highres.jpg'
import hestan_22 from '../assets/catB/Hestan/7035.22_highres.jpg'
import hestan_24 from '../assets/catB/Hestan/7035.24_highres.jpg'
import hestan_25 from '../assets/catB/Hestan/7035.25_highres.jpg'
import hestan_26 from '../assets/catB/Hestan/7035.26_highres.jpg'
import hestan_27 from '../assets/catB/Hestan/7035.27_highres.jpg'
import hestan_28 from '../assets/catB/Hestan/7035.28_highres.jpg'
import hestan_29 from '../assets/catB/Hestan/7035.29_highres.jpg'

import mlf01 from '../assets/catB/Main-Line-Flax/MLF01_TILE.jpg'
import mlf02 from '../assets/catB/Main-Line-Flax/MLF02_TILE.jpg'
import mlf44 from '../assets/catB/Main-Line-Flax/MLF44_TILE.jpg'
import mlf23 from '../assets/catB/Main-Line-Flax/MLF23_TILE.jpg'
import mlf03 from '../assets/catB/Main-Line-Flax/MLF03_TILE.jpg'
import mlf45 from '../assets/catB/Main-Line-Flax/MLF45_TILE.jpg'
import mlf24 from '../assets/catB/Main-Line-Flax/MLF24_TILE.jpg'
import mlf25 from '../assets/catB/Main-Line-Flax/MLF25_TILE.jpg'
import mlf46 from '../assets/catB/Main-Line-Flax/MLF46_TILE.jpg'
import mlf35 from '../assets/catB/Main-Line-Flax/MLF35_TILE.jpg'
import mlf26 from '../assets/catB/Main-Line-Flax/MLF26_TILE.jpg'
import mlf48 from '../assets/catB/Main-Line-Flax/MLF48_TILE.jpg'
import mlf28 from '../assets/catB/Main-Line-Flax/MLF28_TILE.jpg'
import mlf50 from '../assets/catB/Main-Line-Flax/MLF50_TILE.jpg'
import mlf51 from '../assets/catB/Main-Line-Flax/MLF51_TILE.jpg'
import mlf29 from '../assets/catB/Main-Line-Flax/MLF29_TILE.jpg'
import mlf31 from '../assets/catB/Main-Line-Flax/MLF31_TILE.jpg'
import mlf32 from '../assets/catB/Main-Line-Flax/MLF32_TILE.jpg'
import mlf52 from '../assets/catB/Main-Line-Flax/MLF52_TILE.jpg'
import mlf53 from '../assets/catB/Main-Line-Flax/MLF53_TILE.jpg'
import mlf54 from '../assets/catB/Main-Line-Flax/MLF54_TILE.jpg'
import mlf05 from '../assets/catB/Main-Line-Flax/MLF05_TILE.jpg'
import mlf55 from '../assets/catB/Main-Line-Flax/MLF55_TILE.jpg'
import mlf07 from '../assets/catB/Main-Line-Flax/MLF07_TILE.jpg'
import mlf06 from '../assets/catB/Main-Line-Flax/MLF06_TILE.jpg'
import mlf08 from '../assets/catB/Main-Line-Flax/MLF08_TILE.jpg'
import mlf13 from '../assets/catB/Main-Line-Flax/MLF13_TILE.jpg'
import mlf09 from '../assets/catB/Main-Line-Flax/MLF09_TILE.jpg'
import mlf57 from '../assets/catB/Main-Line-Flax/MLF57_TILE.jpg'
import mlf12 from '../assets/catB/Main-Line-Flax/MLF12_TILE.jpg'
import mlf10 from '../assets/catB/Main-Line-Flax/MLF10_TILE.jpg'
import mlf40 from '../assets/catB/Main-Line-Flax/MLF40_TILE.jpg'
import mlf14 from '../assets/catB/Main-Line-Flax/MLF14_TILE.jpg'
import mlf59 from '../assets/catB/Main-Line-Flax/MLF59_TILE.jpg'
import mlf34 from '../assets/catB/Main-Line-Flax/MLF34_TILE.jpg'
import mlf62 from '../assets/catB/Main-Line-Flax/MLF62_TILE.jpg'
import mlf63 from '../assets/catB/Main-Line-Flax/MLF63_TILE.jpg'
import mlf15 from '../assets/catB/Main-Line-Flax/MLF15_TILE.jpg'
import mlf37 from '../assets/catB/Main-Line-Flax/MLF37_TILE.jpg'
import mlf64 from '../assets/catB/Main-Line-Flax/MLF64_TILE.jpg'
import mlf16 from '../assets/catB/Main-Line-Flax/MLF16_TILE.jpg'
import mlf17 from '../assets/catB/Main-Line-Flax/MLF17_TILE.jpg'
import mlf36 from '../assets/catB/Main-Line-Flax/MLF36_TILE.jpg'
import mlf20 from '../assets/catB/Main-Line-Flax/MLF20_TILE.jpg'
import mlf21 from '../assets/catB/Main-Line-Flax/MLF21_TILE.jpg'
import mlf22 from '../assets/catB/Main-Line-Flax/MLF22_TILE.jpg'
import mlf66 from '../assets/catB/Main-Line-Flax/MLF66_TILE.jpg'

import stretchy_1 from '../assets/catB/Stretchy/1_Stretchy-1.jpg'
import stretchy_10 from '../assets/catB/Stretchy/10_Stretchy-1.jpg'
import stretchy_11 from '../assets/catB/Stretchy/11_Stretchy-1.jpg'
import stretchy_12 from '../assets/catB/Stretchy/12_Stretchy-2.jpg'
import stretchy_13 from '../assets/catB/Stretchy/13_Stretchy-1.jpg'
import stretchy_14 from '../assets/catB/Stretchy/14_Stretchy-1.jpg'
import stretchy_15 from '../assets/catB/Stretchy/15_Stretchy-1.jpg'
import stretchy_16 from '../assets/catB/Stretchy/16_Stretchy-1.jpg'
import stretchy_17 from '../assets/catB/Stretchy/17_Stretchy-1.jpg'
import stretchy_18 from '../assets/catB/Stretchy/18_Stretchy-1.jpg'
import stretchy_19 from '../assets/catB/Stretchy/19_Stretchy-1.jpg'
import stretchy_2 from '../assets/catB/Stretchy/2_Stretchy-1.jpg'
import stretchy_20 from '../assets/catB/Stretchy/20_Stretchy-1.jpg'
/* import stretchy_21 from '../assets/catB/Stretchy/21_Stretchy-1.jpg' */
import stretchy_22 from '../assets/catB/Stretchy/22_Stretchy-1.jpg'
import stretchy_23 from '../assets/catB/Stretchy/23_Stretchy-1.jpg'
import stretchy_24 from '../assets/catB/Stretchy/24_Stretchy-1.jpg'
import stretchy_25 from '../assets/catB/Stretchy/25_Stretchy-2.jpg'
import stretchy_26 from '../assets/catB/Stretchy/26_Stretchy-1.jpg'
import stretchy_27 from '../assets/catB/Stretchy/27_Stretchy-1.jpg'
import stretchy_28 from '../assets/catB/Stretchy/28_Stretchy-1.jpg'
import stretchy_29 from '../assets/catB/Stretchy/29_Stretchy-1.jpg'
import stretchy_3 from '../assets/catB/Stretchy/3_Stretchy-1.jpg'
import stretchy_30 from '../assets/catB/Stretchy/30_Stretchy-1.jpg'
import stretchy_31 from '../assets/catB/Stretchy/31_Stretchy-1.jpg'
import stretchy_32 from '../assets/catB/Stretchy/32_Stretchy-1.jpg'
import stretchy_33 from '../assets/catB/Stretchy/33_Stretchy-1.jpg'
import stretchy_34 from '../assets/catB/Stretchy/34_Stretchy-1.jpg'
import stretchy_35 from '../assets/catB/Stretchy/35_Stretchy-1.jpg'
import stretchy_36 from '../assets/catB/Stretchy/36_Stretchy-1.jpg'
import stretchy_37 from '../assets/catB/Stretchy/37_Stretchy-1.jpg'
import stretchy_38 from '../assets/catB/Stretchy/38_Stretchy-1.jpg'
import stretchy_39 from '../assets/catB/Stretchy/39_Stretchy-1.jpg'
import stretchy_4 from '../assets/catB/Stretchy/4_Stretchy-1.jpg'
import stretchy_40 from '../assets/catB/Stretchy/40_Stretchy-1.jpg'
import stretchy_41 from '../assets/catB/Stretchy/41_Stretchy-1.jpg'
import stretchy_42 from '../assets/catB/Stretchy/42_Stretchy-1.jpg'
import stretchy_43 from '../assets/catB/Stretchy/43_Stretchy-1.jpg'
import stretchy_44 from '../assets/catB/Stretchy/44_Stretchy-1.jpg'
import stretchy_45 from '../assets/catB/Stretchy/45_Stretchy-1.jpg'
import stretchy_46 from '../assets/catB/Stretchy/46_Stretchy-1.jpg'
import stretchy_47 from '../assets/catB/Stretchy/47_Stretchy-1.jpg'
import stretchy_48 from '../assets/catB/Stretchy/48_Stretchy-1.jpg'
import stretchy_5 from '../assets/catB/Stretchy/5_Stretchy-1.jpg'
import stretchy_6 from '../assets/catB/Stretchy/6_Stretchy-1.jpg'
import stretchy_7 from '../assets/catB/Stretchy/7_Stretchy-2.jpg'
import stretchy_8 from '../assets/catB/Stretchy/8_Stretchy-1.jpg'
import stretchy_9 from '../assets/catB/Stretchy/9_Stretchy-1.jpg'

import wilson_01 from '../assets/catB/Wilson/7067.01_highres.jpg'
import wilson_02 from '../assets/catB/Wilson/7067.02_highres.jpg'
import wilson_03 from '../assets/catB/Wilson/7067.03_highres.jpg'
import wilson_04 from '../assets/catB/Wilson/7067.04_highres.jpg'
import wilson_05 from '../assets/catB/Wilson/7067.05_highres.jpg'
import wilson_06 from '../assets/catB/Wilson/7067.06_highres.jpg'
import wilson_07 from '../assets/catB/Wilson/7067.07_highres.jpg'
import wilson_08 from '../assets/catB/Wilson/7067.08_highres.jpg'
import wilson_09 from '../assets/catB/Wilson/7067.09_highres.jpg'
import wilson_10 from '../assets/catB/Wilson/7067.10_highres.jpg'
import wilson_11 from '../assets/catB/Wilson/7067.11_highres.jpg'
import wilson_12 from '../assets/catB/Wilson/7067.12_highres.jpg'
import wilson_13 from '../assets/catB/Wilson/7067.13_highres.jpg'
import wilson_14 from '../assets/catB/Wilson/7067.14_highres.jpg'
import wilson_15 from '../assets/catB/Wilson/7067.15_highres.jpg'
import wilson_16 from '../assets/catB/Wilson/7067.16_highres.jpg'
import wilson_17 from '../assets/catB/Wilson/7067.17_highres.jpg'
import wilson_18 from '../assets/catB/Wilson/7067.18_highres.jpg'
import wilson_19 from '../assets/catB/Wilson/7067.19_highres.jpg'
import wilson_20 from '../assets/catB/Wilson/7067.20_highres.jpg'
import wilson_21 from '../assets/catB/Wilson/7067.21_highres.jpg'

const catB = [
    {
        category: 'cat. B',
        collection: 'Hestan',
        items: [
          { name: "HESTAN 7035.01", slug: "HESTAN 7035.01", thumbnail: hestan_01,
          },
          { name: "HESTAN 7035.02", slug: "HESTAN 7035.02", thumbnail: hestan_02,
          },
          { name: "HESTAN 7035.03", slug: "HESTAN 7035.03", thumbnail: hestan_03,
          },
          { name: "HESTAN 7035.04", slug: "HESTAN 7035.04", thumbnail: hestan_04,
          },
          { name: "HESTAN 7035.05", slug: "HESTAN 7035.05", thumbnail: hestan_05,
          },
          { name: "HESTAN 7035.06", slug: "HESTAN 7035.06", thumbnail: hestan_06,
          },
          { name: "HESTAN 7035.07", slug: "HESTAN 7035.07", thumbnail: hestan_07,
          },
          { name: "HESTAN 7035.08", slug: "HESTAN 7035.08", thumbnail: hestan_08,
          },
          { name: "HESTAN 7035.09", slug: "HESTAN 7035.09", thumbnail: hestan_09,
          },
          { name: "HESTAN 7035.10", slug: "HESTAN 7035.10", thumbnail: hestan_10,
          },
          { name: "HESTAN 7035.11", slug: "HESTAN 7035.11", thumbnail: hestan_11,
          },
          { name: "HESTAN 7035.12", slug: "HESTAN 7035.12", thumbnail: hestan_12,
          },
          { name: "HESTAN 7035.13", slug: "HESTAN 7035.13", thumbnail: hestan_13,
          },
          { name: "HESTAN 7035.14", slug: "HESTAN 7035.14", thumbnail: hestan_14,
          },
          { name: "HESTAN 7035.15", slug: "HESTAN 7035.15", thumbnail: hestan_15,
          },
          { name: "HESTAN 7035.16", slug: "HESTAN 7035.16", thumbnail: hestan_16,
          },
          { name: "HESTAN 7035.17", slug: "HESTAN 7035.17", thumbnail: hestan_17,
          },
          { name: "HESTAN 7035.18", slug: "HESTAN 7035.18", thumbnail: hestan_18,
          },
          { name: "HESTAN 7035.19", slug: "HESTAN 7035.19", thumbnail: hestan_19,
          },
          { name: "HESTAN 7035.20", slug: "HESTAN 7035.20", thumbnail: hestan_20,
          },
          { name: "HESTAN 7035.21", slug: "HESTAN 7035.21", thumbnail: hestan_21,
          },
          { name: "HESTAN 7035.22", slug: "HESTAN 7035.22", thumbnail: hestan_22,
          },
          { name: "HESTAN 7035.24", slug: "HESTAN 7035.24", thumbnail: hestan_24,
          },
          { name: "HESTAN 7035.25", slug: "HESTAN 7035.25", thumbnail: hestan_25,
          },
          { name: "HESTAN 7035.26", slug: "HESTAN 7035.26", thumbnail: hestan_26,
          },
          { name: "HESTAN 7035.27", slug: "HESTAN 7035.27", thumbnail: hestan_27,
          },
          { name: "HESTAN 7035.28", slug: "HESTAN 7035.28", thumbnail: hestan_28,
          },
          { name: "HESTAN 7035.29", slug: "HESTAN 7035.29", thumbnail: hestan_29,
          },
          
        ]
      },
      {
        category: 'cat. B',
        collection: 'Main Line Flax',
        items: [
          { name: "MAIN LINE FLAX ALDGATE MLF01", slug: "MAIN LINE FLAX ALDGATE MLF01", thumbnail: mlf01,
          },
          { name: "MAIN LINE FLAX ARCHWAY MLF02", slug: "MAIN LINE FLAX ARCHWAY MLF02", thumbnail: mlf02,
          },
          { name: "MAIN LINE FLAX BALHAM MLF44", slug: "MAIN LINE FLAX BALHAM MLF44", thumbnail: mlf44,
          },
          { name: "MAIN LINE FLAX BANK MLF23", slug: "MAIN LINE FLAX BANK MLF23", thumbnail: mlf23,
          },/* 
          { name: "MAIN LINE FLAX BARBICAN MLF03", slug: "MAIN LINE FLAX BARBICAN MLF03", thumbnail: mlf03,
          }, */
          { name: "MAIN LINE FLAX BARKING MLF45", slug: "MAIN LINE FLAX BARKING MLF45", thumbnail: mlf45,
          },/* 
          { name: "MAIN LINE FLAX BAYSWATER MLF24", slug: "MAIN LINE FLAX BAYSWATER MLF24", thumbnail: mlf24,
          },
          { name: "MAIN LINE FLAX BETHNAL MLF25", slug: "MAIN LINE FLAX BETHNAL MLF25", thumbnail: mlf25,
          }, */
          { name: "MAIN LINE FLAX BLACKWALL MLF46", slug: "MAIN LINE FLAX BLACKWALL MLF46", thumbnail: mlf46,
          },
          { name: "MAIN LINE FLAX BROMPTON MLF35", slug: "MAIN LINE FLAX BROMPTON MLF35", thumbnail: mlf35,
          },/* 
          { name: "MAIN LINE FLAX CAMDEN MLF26", slug: "MAIN LINE FLAX CAMDEN MLF26", thumbnail: mlf26,
          }, *//* 
          { name: "MAIN LINE FLAX CHINGWELL MLF48", slug: "MAIN LINE FLAX CHINGWELL MLF48", thumbnail: mlf48,
          }, */
          { name: "MAIN LINE FLAX EDGWARE MLF28", slug: "MAIN LINE FLAX EDGWARE MLF28", thumbnail: mlf28,
          },/* 
          { name: "MAIN LINE FLAX EMBANKMENT MLF50", slug: "MAIN LINE FLAX EMBANKMENT MLF50", thumbnail: mlf50,
          }, */
          { name: "MAIN LINE FLAX EPPING MLF51", slug: "MAIN LINE FLAX EPPING MLF51", thumbnail: mlf51,
          },/* 
          { name: "MAIN LINE FLAX FARRINGDON MLF29", slug: "MAIN LINE FLAX FARRINGDON MLF29", thumbnail: mlf29,
          },
          { name: "MAIN LINE FLAX GOLDHAWK MLF31", slug: "MAIN LINE FLAX GOLDHAWK MLF31", thumbnail: mlf31,
          },
          { name: "MAIN LINE FLAX GREENFORD MLF32", slug: "MAIN LINE FLAX GREENFORD MLF32", thumbnail: mlf32,
          }, */
          { name: "MAIN LINE FLAX GREENWICH MLF52", slug: "MAIN LINE FLAX GREENWICH MLF52", thumbnail: mlf52,
          },
          { name: "MAIN LINE FLAX HAMPSTEAD MLF53", slug: "MAIN LINE FLAX HAMPSTEAD MLF53", thumbnail: mlf53,
          },
          { name: "MAIN LINE FLAX HARROW MLF54", slug: "MAIN LINE FLAX HARROW MLF54", thumbnail: mlf54,
          },
          { name: "MAIN LINE FLAX HOLBORN MLF05", slug: "MAIN LINE FLAX HOLBORN MLF05", thumbnail: mlf05,
          },
          { name: "MAIN LINE FLAX HORNCHURCH MLF55", slug: "MAIN LINE FLAX HORNCHURCH MLF55", thumbnail: mlf55,
          },
          { name: "MAIN LINE FLAX KENSINGTON MLF07", slug: "MAIN LINE FLAX KENSINGTON MLF07", thumbnail: mlf07,
          },
          { name: "MAIN LINE FLAX KILBUM MLF06", slug: "MAIN LINE FLAX KILBUM MLF06", thumbnail: mlf06,
          },
          { name: "MAIN LINE FLAX LAMBETH MLF08", slug: "MAIN LINE FLAX LAMBETH MLF08", thumbnail: mlf08,
          },
          { name: "MAIN LINE FLAX LEYTON MLF13", slug: "MAIN LINE FLAX LEYTON MLF13", thumbnail: mlf13,
          },/* 
          { name: "MAIN LINE FLAX MONUMENT MLF09", slug: "MAIN LINE FLAX MONUMENT MLF09", thumbnail: mlf09,
          },
          { name: "MAIN LINE FLAX MOORGATE MLF57", slug: "MAIN LINE FLAX MOORGATE MLF57", thumbnail: mlf57,
          }, */
          { name: "MAIN LINE FLAX MORDEN MLF12", slug: "MAIN LINE FLAX MORDEN MLF12", thumbnail: mlf12,
          },
          { name: "MAIN LINE FLAX NEWBURYMLF10", slug: "MAIN LINE FLAX NEWBURYMLF10", thumbnail: mlf10,
          },
          { name: "MAIN LINE FLAX NORTHFIELDS MLF40", slug: "MAIN LINE FLAX NORTHFIELDS MLF40", thumbnail: mlf40,
          },
          { name: "MAIN LINE FLAX PARSONS MLF14", slug: "MAIN LINE FLAX PARSONS MLF14", thumbnail: mlf14,
          },/* 
          { name: "MAIN LINE FLAX PERIVALE MLF59", slug: "MAIN LINE FLAX PERIVALE MLF59", thumbnail: mlf59,
          }, */
          { name: "MAIN LINE FLAX PIMLICO MLF34", slug: "MAIN LINE FLAX PIMLICO MLF34", thumbnail: mlf34,
          },/* 
          { name: "MAIN LINE FLAX RICHMOND MLF62", slug: "MAIN LINE FLAX RICHMOND MLF62", thumbnail: mlf62,
          },
          { name: "MAIN LINE FLAX SHADWELL MLF63", slug: "MAIN LINE FLAX SHADWELL MLF63", thumbnail: mlf63,
          }, */
          { name: "MAIN LINE FLAX STOCKWELL MLF15", slug: "MAIN LINE FLAX STOCKWELL MLF15", thumbnail: mlf15,
          },
          { name: "MAIN LINE FLAX STRANMORE MLF37", slug: "MAIN LINE FLAX STRANMORE MLF37", thumbnail: mlf37,
          },
          { name: "MAIN LINE FLAX STRATFORD MLF64", slug: "MAIN LINE FLAX STRATFORD MLF64", thumbnail: mlf64,
          },/* 
          { name: "MAIN LINE FLAX TEMPLE MLF16", slug: "MAIN LINE FLAX TEMPLE MLF16", thumbnail: mlf16,
          }, */
          { name: "MAIN LINE FLAX TOOTING MLF17", slug: "MAIN LINE FLAX TOOTING MLF17", thumbnail: mlf17,
          },
          { name: "MAIN LINE FLAX TOWER MLF36", slug: "MAIN LINE FLAX TOWER MLF36", thumbnail: mlf36,
          },
          { name: "MAIN LINE FLAX UPMINSTER MLF20", slug: "MAIN LINE FLAX UPMINSTER MLF20", thumbnail: mlf20,
          },
          { name: "MAIN LINE FLAX WATERLOO MLF21", slug: "MAIN LINE FLAX WATERLOO MLF21", thumbnail: mlf21,
          },
          { name: "MAIN LINE FLAX WATERLOO MLF57", slug: "MAIN LINE FLAX WATERLOO MLF57", thumbnail: mlf57,
          },
          { name: "MAIN LINE FLAX WESTMINSTER MLF22", slug: "MAIN LINE FLAX WESTMINSTER MLF22", thumbnail: mlf22,
          },
          { name: "MAIN LINE FLAX WHITECHAPEL MLF66", slug: "MAIN LINE FLAX WHITECHAPEL MLF66", thumbnail: mlf66,
          },
        ]
      },
      {
        category: 'cat. B',
        collection: 'Stretchy',
        items: [
          { name: "STRETCHY 1", slug: "STRETCHY 1", thumbnail: stretchy_1 ,
          },
          { name: "STRETCHY 10", slug: "STRETCHY 10", thumbnail: stretchy_10,
          },
          { name: "STRETCHY 11", slug: "STRETCHY 11", thumbnail: stretchy_11,
          },
          { name: "STRETCHY 12", slug: "STRETCHY 12", thumbnail: stretchy_12,
          },
          { name: "STRETCHY 13", slug: "STRETCHY 13", thumbnail: stretchy_13,
          },
          { name: "STRETCHY 14", slug: "STRETCHY 14", thumbnail: stretchy_14,
          },
          { name: "STRETCHY 15", slug: "STRETCHY 15", thumbnail: stretchy_15,
          },
          { name: "STRETCHY 16", slug: "STRETCHY 16", thumbnail: stretchy_16,
          },
          { name: "STRETCHY 17", slug: "STRETCHY 17", thumbnail: stretchy_17,
          },
          { name: "STRETCHY 18", slug: "STRETCHY 18", thumbnail: stretchy_18,
          },
          { name: "STRETCHY 19", slug: "STRETCHY 19", thumbnail: stretchy_19,
          },
          { name: "STRETCHY 2", slug: "STRETCHY 2", thumbnail: stretchy_2 ,
          },
          { name: "STRETCHY 20", slug: "STRETCHY 20", thumbnail: stretchy_20,
          },
          /* { name: "STRETCHY 21", slug: "STRETCHY 21", thumbnail: stretchy_21,
          }, */
          { name: "STRETCHY 22", slug: "STRETCHY 22", thumbnail: stretchy_22,
          },
          { name: "STRETCHY 23", slug: "STRETCHY 23", thumbnail: stretchy_23,
          },
          { name: "STRETCHY 24", slug: "STRETCHY 24", thumbnail: stretchy_24,
          },
          { name: "STRETCHY 25", slug: "STRETCHY 25", thumbnail: stretchy_25,
          },
          { name: "STRETCHY 26", slug: "STRETCHY 26", thumbnail: stretchy_26,
          },
          { name: "STRETCHY 27", slug: "STRETCHY 27", thumbnail: stretchy_27,
          },
          { name: "STRETCHY 28", slug: "STRETCHY 28", thumbnail: stretchy_28,
          },
          { name: "STRETCHY 29", slug: "STRETCHY 29", thumbnail: stretchy_29,
          },
          { name: "STRETCHY 3", slug: "STRETCHY 3", thumbnail: stretchy_3 ,
          },
          { name: "STRETCHY 30", slug: "STRETCHY 30", thumbnail: stretchy_30,
          },
          { name: "STRETCHY 31", slug: "STRETCHY 31", thumbnail: stretchy_31,
          },
          { name: "STRETCHY 32", slug: "STRETCHY 32", thumbnail: stretchy_32,
          },
          { name: "STRETCHY 33", slug: "STRETCHY 33", thumbnail: stretchy_33,
          },
          { name: "STRETCHY 34", slug: "STRETCHY 34", thumbnail: stretchy_34,
          },
          { name: "STRETCHY 35", slug: "STRETCHY 35", thumbnail: stretchy_35,
          },
          { name: "STRETCHY 36", slug: "STRETCHY 36", thumbnail: stretchy_36,
          },
          { name: "STRETCHY 37", slug: "STRETCHY 37", thumbnail: stretchy_37,
          },
          { name: "STRETCHY 38", slug: "STRETCHY 38", thumbnail: stretchy_38,
          },
          { name: "STRETCHY 39", slug: "STRETCHY 39", thumbnail: stretchy_39,
          },
          { name: "STRETCHY 4", slug: "STRETCHY 4", thumbnail: stretchy_4 ,
          },
          { name: "STRETCHY 40", slug: "STRETCHY 40", thumbnail: stretchy_40,
          },
          { name: "STRETCHY 41", slug: "STRETCHY 41", thumbnail: stretchy_41,
          },
          { name: "STRETCHY 42", slug: "STRETCHY 42", thumbnail: stretchy_42,
          },
          { name: "STRETCHY 43", slug: "STRETCHY 43", thumbnail: stretchy_43,
          },
          { name: "STRETCHY 44", slug: "STRETCHY 44", thumbnail: stretchy_44,
          },
          { name: "STRETCHY 45", slug: "STRETCHY 45", thumbnail: stretchy_45,
          },
          { name: "STRETCHY 46", slug: "STRETCHY 46", thumbnail: stretchy_46,
          },
          { name: "STRETCHY 47", slug: "STRETCHY 47", thumbnail: stretchy_47,
          },
          { name: "STRETCHY 48", slug: "STRETCHY 48", thumbnail: stretchy_48,
          },
          { name: "STRETCHY 5", slug: "STRETCHY 5", thumbnail: stretchy_5 ,
          },
          { name: "STRETCHY 6", slug: "STRETCHY 6", thumbnail: stretchy_6 ,
          },
          { name: "STRETCHY 7", slug: "STRETCHY 7", thumbnail: stretchy_7 ,
          },
          { name: "STRETCHY 8", slug: "STRETCHY 8", thumbnail: stretchy_8 ,
          },
          { name: "STRETCHY 9", slug: "STRETCHY 9", thumbnail: stretchy_9 ,
          },
        ]
      },
      {
        category: 'cat. B',
        collection: 'Wilson',
        items: [
          { name: "WILSON 7067.01", slug: "WILSON 7067.01", thumbnail: wilson_01 ,
          },
          { name: "WILSON 7067.02", slug: "WILSON 7067.02", thumbnail: wilson_02 ,
          },
          { name: "WILSON 7067.03", slug: "WILSON 7067.03", thumbnail: wilson_03 ,
          },
          { name: "WILSON 7067.04", slug: "WILSON 7067.04", thumbnail: wilson_04 ,
          },
          { name: "WILSON 7067.05", slug: "WILSON 7067.05", thumbnail: wilson_05 ,
          },
          { name: "WILSON 7067.06", slug: "WILSON 7067.06", thumbnail: wilson_06 ,
          },
          { name: "WILSON 7067.07", slug: "WILSON 7067.07", thumbnail: wilson_07 ,
          },
          { name: "WILSON 7067.08", slug: "WILSON 7067.08", thumbnail: wilson_08 ,
          },
          { name: "WILSON 7067.09", slug: "WILSON 7067.09", thumbnail: wilson_09 ,
          },
          { name: "WILSON 7067.10", slug: "WILSON 7067.10", thumbnail: wilson_10 ,
          },
          { name: "WILSON 7067.11", slug: "WILSON 7067.11", thumbnail: wilson_11 ,
          },
          { name: "WILSON 7067.12", slug: "WILSON 7067.12", thumbnail: wilson_12 ,
          },
          { name: "WILSON 7067.13", slug: "WILSON 7067.13", thumbnail: wilson_13 ,
          },
          { name: "WILSON 7067.14", slug: "WILSON 7067.14", thumbnail: wilson_14 ,
          },
          { name: "WILSON 7067.15", slug: "WILSON 7067.15", thumbnail: wilson_15 ,
          },
          { name: "WILSON 7067.16", slug: "WILSON 7067.16", thumbnail: wilson_16 ,
          },
          { name: "WILSON 7067.17", slug: "WILSON 7067.17", thumbnail: wilson_17 ,
          },
          { name: "WILSON 7067.18", slug: "WILSON 7067.18", thumbnail: wilson_18 ,
          },
          { name: "WILSON 7067.19", slug: "WILSON 7067.19", thumbnail: wilson_19 ,
          },
          { name: "WILSON 7067.20", slug: "WILSON 7067.20", thumbnail: wilson_20 ,
          },
          { name: "WILSON 7067.21", slug: "WILSON 7067.21", thumbnail: wilson_21 ,
          },
        ]
      },
      {
        category: 'cat. B',
        collection: 'Fame',
        items: [
          { name: "FAME 60005", slug: "FAME 60005", thumbnail: fame_60005 },
          { name: "FAME 60017", slug: "FAME 60017", thumbnail: fame_60017 },
          { name: "FAME 60019", slug: "FAME 60019", thumbnail: fame_60019 },
          { name: "FAME 60051", slug: "FAME 60051", thumbnail: fame_60051 },
          { name: "FAME 60078", slug: "FAME 60078", thumbnail: fame_60078 },
          { name: "FAME 61003", slug: "FAME 61003", thumbnail: fame_61003 },
          { name: "FAME 61044", slug: "FAME 61044", thumbnail: fame_61044 },
          { name: "FAME 61047", slug: "FAME 61047", thumbnail: fame_61047 },
          { name: "FAME 61060", slug: "FAME 61060", thumbnail: fame_61060 },
          { name: "FAME 61135", slug: "FAME 61135", thumbnail: fame_61135 },
          { name: "FAME 61136", slug: "FAME 61136", thumbnail: fame_61136 },
          { name: "FAME 62067", slug: "FAME 62067", thumbnail: fame_62067 },
          { name: "FAME 62068", slug: "FAME 62068", thumbnail: fame_62068 },
          { name: "FAME 63016", slug: "FAME 63016", thumbnail: fame_63016 },
          { name: "FAME 64055", slug: "FAME 64055", thumbnail: fame_64055 },
          { name: "FAME 64058", slug: "FAME 64058", thumbnail: fame_64058 },
          { name: "FAME 64089", slug: "FAME 64089", thumbnail: fame_64089 },
          { name: "FAME 64119", slug: "FAME 64119", thumbnail: fame_64119 },
          { name: "FAME 64165", slug: "FAME 64165", thumbnail: fame_64165 },
          { name: "FAME 64167", slug: "FAME 64167", thumbnail: fame_64167 },
          { name: "FAME 64169", slug: "FAME 64169", thumbnail: fame_64169 },
          { name: "FAME 65082", slug: "FAME 65082", thumbnail: fame_65082 },
          { name: "FAME 65084", slug: "FAME 65084", thumbnail: fame_65084 },
          { name: "FAME 66032", slug: "FAME 66032", thumbnail: fame_66032 },
          { name: "FAME 66075", slug: "FAME 66075", thumbnail: fame_66075 },
          { name: "FAME 66130", slug: "FAME 66130", thumbnail: fame_66130 },
          { name: "FAME 66131", slug: "FAME 66131", thumbnail: fame_66131 },
          { name: "FAME 66133", slug: "FAME 66133", thumbnail: fame_66133 },
          { name: "FAME 67004", slug: "FAME 67004", thumbnail: fame_67004 },
          { name: "FAME 67005", slug: "FAME 67005", thumbnail: fame_67005 },
          { name: "FAME 67016", slug: "FAME 67016", thumbnail: fame_67016 },
          { name: "FAME 67067", slug: "FAME 67067", thumbnail: fame_67067 },
          { name: "FAME 67068", slug: "FAME 67068", thumbnail: fame_67068 },
          { name: "FAME 67069", slug: "FAME 67069", thumbnail: fame_67069 },
          { name: "FAME 68004", slug: "FAME 68004", thumbnail: fame_68004 },
          { name: "FAME 68035", slug: "FAME 68035", thumbnail: fame_68035 },
          { name: "FAME 68143", slug: "FAME 68143", thumbnail: fame_68143 },
          { name: "FAME 68145", slug: "FAME 68145", thumbnail: fame_68145 },
          { name: "FAME 68147", slug: "FAME 68147", thumbnail: fame_68147 },
          { name: "FAME 68148", slug: "FAME 68148", thumbnail: fame_68148 }
        ]
      },
      {
        category: 'cat. B',
        collection: 'Silvertex',
        items: [
          { name: "SILVERTEX 0001 MACADEMIA", slug: "SILVERTEX 0001 MACADEMIA", thumbnail: silvertex_0001_MACADEMIA },
          { name: "SILVERTEX 0009 TAUPE", slug: "SILVERTEX 0009 TAUPE", thumbnail: silvertex_0009_TAUPE },
          { name: "SILVERTEX 0077 LUGGAGE", slug: "SILVERTEX 0077 LUGGAGE", thumbnail: silvertex_0077_LUGGAGE },
          { name: "SILVERTEX 1051 SISAL", slug: "SILVERTEX 1051 SISAL", thumbnail: silvertex_1051_SISAL },
          { name: "SILVERTEX 1077 CREAM", slug: "SILVERTEX 1077 CREAM", thumbnail: silvertex_1077_CREAM },
          { name: "SILVERTEX 12526 TEMPESTA", slug: "SILVERTEX 12526 TEMPESTA", thumbnail: silvertex_12526_TEMPESTA },
          { name: "SILVERTEX 13330 BEIGE", slug: "SILVERTEX 13330 BEIGE", thumbnail: silvertex_13330_BEIGE },
          { name: "SILVERTEX 13331 MOCCA", slug: "SILVERTEX 13331 MOCCA", thumbnail: silvertex_13331_MOCCA },
          { name: "SILVERTEX 13332 METEOR", slug: "SILVERTEX 13332 METEOR", thumbnail: silvertex_13332_METEOR },
          { name: "SILVERTEX 13335 COBRE", slug: "SILVERTEX 13335 COBRE", thumbnail: silvertex_13335_COBRE },
          { name: "SILVERTEX 13341 PISTACHO", slug: "SILVERTEX 13341 PISTACHO", thumbnail: silvertex_13341_PISTACHO },
          { name: "SILVERTEX 13342 BASIL", slug: "SILVERTEX 13342 BASIL", thumbnail: silvertex_13342_BASIL },
          { name: "SILVERTEX 13348 BLACK", slug: "SILVERTEX 13348 BLACK", thumbnail: silvertex_13348_BLACK },
          { name: "SILVERTEX 2011 RED", slug: "SILVERTEX 2011 RED", thumbnail: silvertex_2011_RED },
          { name: "SILVERTEX 2016 RASPERRY ", slug: "SILVERTEX 2016 RASPERRY ", thumbnail: silvertex_2016_RASPERRY },
          { name: "SILVERTEX 2064 WINE", slug: "SILVERTEX 2064 WINE", thumbnail: silvertex_2064_WINE },
          { name: "SILVERTEX 3007 SAPPHIRE ", slug: "SILVERTEX 3007 SAPPHIRE ", thumbnail: silvertex_3007_SAPPHIRE },
          { name: "SILVERTEX 3066 BALTIC", slug: "SILVERTEX 3066 BALTIC", thumbnail: silvertex_3066_BALTIC },
          { name: "SILVERTEX 3067 DELFT", slug: "SILVERTEX 3067 DELFT", thumbnail: silvertex_3067_DELFT },
          { name: "SILVERTEX 4001 PLATA", slug: "SILVERTEX 4001 PLATA", thumbnail: silvertex_4001_PLATA },
          { name: "SILVERTEX 4004 ICE", slug: "SILVERTEX 4004 ICE", thumbnail: silvertex_4004_ICE },
          { name: "SILVERTEX 4011 STERLING", slug: "SILVERTEX 4011 STERLING", thumbnail: silvertex_4011_STERLING },
          { name: "SILVERTEX 5009 SAGE", slug: "SILVERTEX 5009 SAGE", thumbnail: silvertex_5009_SAGE },
          { name: "SILVERTEX 5063 FOREST", slug: "SILVERTEX 5063 FOREST", thumbnail: silvertex_5063_FOREST },
          { name: "SILVERTEX 6061 MANDARIN", slug: "SILVERTEX 6061 MANDARIN", thumbnail: silvertex_6061_MANDARIN },
          { name: "SILVERTEX 6062 MELON", slug: "SILVERTEX 6062 MELON", thumbnail: silvertex_6062_MELON },
          { name: "SILVERTEX 6065 SAFRAN", slug: "SILVERTEX 6065 SAFRAN", thumbnail: silvertex_6065_SAFRAN },
          { name: "SILVERTEX 9002 CARBON", slug: "SILVERTEX 9002 CARBON", thumbnail: silvertex_9002_CARBON },
          ]
      }
]

export default catB