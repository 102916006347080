import React, { useState } from 'react';

function FamilySelector({ products, selectedFamily, onFamilyChange, onProductChange }) {
  const [currentFamily, setCurrentFamily] = useState(selectedFamily || '');

  const families = Array.from(new Set(products.map((product) => product.family || '').filter(Boolean)));

  const handleFamilyChange = (e) => {
    const selectedFamilyName = e.target.value;
    const familyProducts = selectedFamilyName === '' ? products : products.filter((product) => product.family === selectedFamilyName);

    setCurrentFamily(selectedFamilyName);
    onFamilyChange(selectedFamilyName);

    // If there's only one product in the selected family, call onProductChange with its code
    if (familyProducts.length === 1) {
      onProductChange(familyProducts[0].code);
    }
  };

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 hover:ay-cursor-pointer ay-border-b ay-border-b-black">
      <div className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center">
        <h2 className="ay-font-bold ay-text-lg">Famiglia</h2>
        {currentFamily && <p className="ay-uppercase">{selectedFamily}</p>}
      </div>
      <select
        value={currentFamily|| ''}
        onChange={handleFamilyChange}
        className="ay-border ay-rounded-lg ay-boder-black ay-w-full ay-p-4 ay-mt-2"
      >
        <option value="">TUTTI PRODOTTI</option>
        {families.map((family) => (
          <option key={family} value={family}>
            {family.toUpperCase()} {/* ({products.filter((product) => product.family === family).length}) */}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FamilySelector;