
import ARDA_0131 from '../assets/catTop/Arda/F_13031_C0131_W24.tif.jpg'
import ARDA_0201 from '../assets/catTop/Arda/F_13031_C0201_W24.tif.jpg'
import ARDA_0211 from '../assets/catTop/Arda/F_13031_C0211_W24.tif.jpg'
import ARDA_0321 from '../assets/catTop/Arda/F_13031_C0321_W24.tif.jpg'
import ARDA_0351 from '../assets/catTop/Arda/F_13031_C0351_W24.tif.jpg'
import ARDA_0671 from '../assets/catTop/Arda/F_13031_C0671_W24.tif.jpg'
import ARDA_0771 from '../assets/catTop/Arda/F_13031_C0771_W24.tif.jpg'
import ARDA_0791 from '../assets/catTop/Arda/F_13031_C0791_W24.tif.jpg'
import ARDA_0931 from '../assets/catTop/Arda/F_13031_C0931_W24.tif.jpg'
import ARDA_0961 from '../assets/catTop/Arda/F_13031_C0961_W24.tif.jpg'
import ARDA_0991 from '../assets/catTop/Arda/F_13031_C0991_W24.tif.jpg'

const catTop = [
  
  {
    category: 'cat. Top',
    collection: 'Arda',
    items: [
      { slug: "ARDA 0131", name: "ARDA 0131", thumbnail: ARDA_0131 },
      { slug: "ARDA 0201", name: "ARDA 0201", thumbnail: ARDA_0201 },
      { slug: "ARDA 0211", name: "ARDA 0211", thumbnail: ARDA_0211 },
      { slug: "ARDA 0321", name: "ARDA 0321", thumbnail: ARDA_0321 },
      { slug: "ARDA 0351", name: "ARDA 0351", thumbnail: ARDA_0351 },
      { slug: "ARDA 0671", name: "ARDA 0671", thumbnail: ARDA_0671 },
      { slug: "ARDA 0771", name: "ARDA 0771", thumbnail: ARDA_0771 },
      { slug: "ARDA 0791", name: "ARDA 0791", thumbnail: ARDA_0791 },
      { slug: "ARDA 0931", name: "ARDA 0931", thumbnail: ARDA_0931 },
      { slug: "ARDA 0961", name: "ARDA 0961", thumbnail: ARDA_0961 },
      { slug: "ARDA 0991", name: "ARDA 0991", thumbnail: ARDA_0991 },
    ]
  }
]

export default catTop