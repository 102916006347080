import React, { useState, useRef, useEffect } from 'react';
import placeholder from './assets/placeholder.jpg';

function FamilyAccordion({ products, selectedProduct, onProductChange, selectedFamily }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [previewImages, setPreviewImages] = useState({});
  const [familyProducts, setFamilyProducts] = useState([]);

  const initialFamily = selectedProduct.family || '';
  const currentFamilyProducts = selectedFamily === ''
    ? products
    : selectedFamily || initialFamily
    ? products.filter((product) => product.family === (selectedFamily || initialFamily))
    : [];

  const handleProductChange = (product) => {
    onProductChange(product.code);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setFamilyProducts(currentFamilyProducts);
  }, [products, selectedFamily, initialFamily]);

  useEffect(() => {
    const fetchImage = async (product) => {
      const productUrl = `https://emersya.com/jsShowcase/${product.code}?container=emersyaIframe`;
      const response = await fetch(productUrl);
      const data = await response.text();
      const viewerPreviewImageMatch = data.match(/src="(https:\/\/cdn\.emersya\.com\/cl\/[^"]+\/preview\.png\?date=[^"]+)"/);
      if (viewerPreviewImageMatch) {
        const previewImageUrl = viewerPreviewImageMatch[1];
        setPreviewImages(prevImages => ({
          ...prevImages,
          [product.code]: previewImageUrl
        }));
      }
    };

    familyProducts.forEach(product => {
      if (!previewImages[product.code]) {
        fetchImage(product);
      }
    });
  }, [familyProducts]);

  return (
    <div className="ay-w-full ay-pt-4 ay-pb-6 ay-border-b ay-border-b-black">
      <div className="ay-group ay-gap-x-4 ay-flex ay-justify-between ay-items-center">
        <h2 className="ay-text-lg ay-font-bold">Modello</h2>
        {selectedProduct.name && (
          <p className="ay-uppercase">
            {selectedProduct.name}{selectedProduct.id ? ` - cod. ${selectedProduct.id}` : ''}
          </p>
        )}
      </div>

      <div className="ay-mt-2 ay-relative" ref={dropdownRef}>
        <div
          className="ay-border ay-rounded-lg ay-border-black ay-w-full ay-p-4 ay-flex ay-justify-between ay-items-center ay-cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{selectedProduct.name || 'Seleziona modello'}</span>
          <span className={`ay-transition-transform ay-duration-300 ${isOpen ? 'ay-rotate-180' : ''}`}>
            ▼
          </span>
        </div>
        
        {isOpen && (
          <div className="ay-absolute ay-z-10 ay-w-full ay-mt-1 ay-bg-white ay-border ay-border-black ay-rounded-lg ay-shadow-lg ay-max-h-60 ay-overflow-auto">
            {familyProducts.map((product) => (
              <div
                key={`${product.name}-${product.code}`}
                className="ay-p-4 ay-hover:bg-gray-100 ay-cursor-pointer ay-flex ay-items-center"
                onClick={() => handleProductChange(product)}
              >
                {previewImages[product.code] ? (
                  <img
                    src={previewImages[product.code]}
                    alt={product.name}
                    className="ay-w-12 ay-h-12 ay-object-cover ay-mr-4"
                  />
                ) : (
                  <img src={placeholder} alt={product.name} className="ay-w-12 ay-h-12 ay-object-cover ay-mr-4" />
                )}
                <span> {product.name}{product.id ? ` - cod. ${product.id}` : ''}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default FamilyAccordion;
