import COLLINE_0127 from '../assets/catExtra/Colline2/F_1217_C0127_W24.tif.jpg'
import COLLINE_0147 from '../assets/catExtra/Colline2/F_1217_C0147_W24.tif.jpg'
import COLLINE_0177 from '../assets/catExtra/Colline2/F_1217_C0177_W24.tif.jpg'
import COLLINE_0227 from '../assets/catExtra/Colline2/F_1217_C0227_W24.tif.jpg'
import COLLINE_0347 from '../assets/catExtra/Colline2/F_1217_C0347_W24.tif.jpg'
import COLLINE_0397 from '../assets/catExtra/Colline2/F_1217_C0397_W24.tif.jpg'
import COLLINE_0657 from '../assets/catExtra/Colline2/F_1217_C0657_W24.tif.jpg'
import COLLINE_0677 from '../assets/catExtra/Colline2/F_1217_C0677_W24.tif.jpg'
import COLLINE_0737 from '../assets/catExtra/Colline2/F_1217_C0737_W24.tif.jpg'
import COLLINE_0787 from '../assets/catExtra/Colline2/F_1217_C0787_W24.tif.jpg'
import COLLINE_0797 from '../assets/catExtra/Colline2/F_1217_C0797_W24.tif.jpg'
import COLLINE_0987 from '../assets/catExtra/Colline2/F_1217_C0987_W24.tif.jpg'


import MOLLY_0110 from '../assets/catExtra/Molly2/F_2959_C0110_W24.jpg'
import MOLLY_0112 from '../assets/catExtra/Molly2/F_2959_C0112_W24.jpg'
import MOLLY_0114 from '../assets/catExtra/Molly2/F_2959_C0114_W24.jpg'
import MOLLY_0116 from '../assets/catExtra/Molly2/F_2959_C0116_W24.jpg'
import MOLLY_0122 from '../assets/catExtra/Molly2/F_2959_C0122_W24.jpg'
import MOLLY_0152 from '../assets/catExtra/Molly2/F_2959_C0152_W24.jpg'
import MOLLY_0154 from '../assets/catExtra/Molly2/F_2959_C0154_W24.jpg'
import MOLLY_0160 from '../assets/catExtra/Molly2/F_2959_C0160_W24.jpg'
import MOLLY_0164 from '../assets/catExtra/Molly2/F_2959_C0164_W24.jpg'
import MOLLY_0166 from '../assets/catExtra/Molly2/F_2959_C0166_W24.jpg'
import MOLLY_0170 from '../assets/catExtra/Molly2/F_2959_C0170_W24.jpg'
import MOLLY_0190 from '../assets/catExtra/Molly2/F_2959_C0190_W24.jpg'
import MOLLY_0192 from '../assets/catExtra/Molly2/F_2959_C0192_W24.jpg'
import MOLLY_0194 from '../assets/catExtra/Molly2/F_2959_C0194_W24.jpg'
import MOLLY_0196 from '../assets/catExtra/Molly2/F_2959_C0196_W24.jpg'

import STEELCUT_0105 from '../assets/catExtra/SteelcutTrio/F_2965_C0105_W24.tif.jpg'
import STEELCUT_0113 from '../assets/catExtra/SteelcutTrio/F_2965_C0113_W24.tif.jpg'
import STEELCUT_0124 from '../assets/catExtra/SteelcutTrio/F_2965_C0124_W24.tif.jpg'
import STEELCUT_0133 from '../assets/catExtra/SteelcutTrio/F_2965_C0133_W24.tif.jpg'
import STEELCUT_0153 from '../assets/catExtra/SteelcutTrio/F_2965_C0153_W24.tif.jpg'
import STEELCUT_0176 from '../assets/catExtra/SteelcutTrio/F_2965_C0176_W24.tif.jpg'
import STEELCUT_0195 from '../assets/catExtra/SteelcutTrio/F_2965_C0195_W24.tif.jpg'
import STEELCUT_0205 from '../assets/catExtra/SteelcutTrio/F_2965_C0205_W24.tif.jpg'
import STEELCUT_0213 from '../assets/catExtra/SteelcutTrio/F_2965_C0213_W24.tif.jpg'
import STEELCUT_0246 from '../assets/catExtra/SteelcutTrio/F_2965_C0246_W24.tif.jpg'
import STEELCUT_0253 from '../assets/catExtra/SteelcutTrio/F_2965_C0253_W24.tif.jpg'
import STEELCUT_0266 from '../assets/catExtra/SteelcutTrio/F_2965_C0266_W24.tif.jpg'
import STEELCUT_0276 from '../assets/catExtra/SteelcutTrio/F_2965_C0276_W24.tif.jpg'
import STEELCUT_0283 from '../assets/catExtra/SteelcutTrio/F_2965_C0283_W24.tif.jpg'
import STEELCUT_0336 from '../assets/catExtra/SteelcutTrio/F_2965_C0336_W24.tif.jpg'
import STEELCUT_0376 from '../assets/catExtra/SteelcutTrio/F_2965_C0376_W24.tif.jpg'
import STEELCUT_0383 from '../assets/catExtra/SteelcutTrio/F_2965_C0383_W24.tif.jpg'
import STEELCUT_0416 from '../assets/catExtra/SteelcutTrio/F_2965_C0416_W24.tif.jpg'
import STEELCUT_0426 from '../assets/catExtra/SteelcutTrio/F_2965_C0426_W24.tif.jpg'
import STEELCUT_0446 from '../assets/catExtra/SteelcutTrio/F_2965_C0446_W24.tif.jpg'
import STEELCUT_0453 from '../assets/catExtra/SteelcutTrio/F_2965_C0453_W24.tif.jpg'
import STEELCUT_0466 from '../assets/catExtra/SteelcutTrio/F_2965_C0466_W24.tif.jpg'
import STEELCUT_0476 from '../assets/catExtra/SteelcutTrio/F_2965_C0476_W24.tif.jpg'
import STEELCUT_0515 from '../assets/catExtra/SteelcutTrio/F_2965_C0515_W24.tif.jpg'
import STEELCUT_0526 from '../assets/catExtra/SteelcutTrio/F_2965_C0526_W24.tif.jpg'
import STEELCUT_0533 from '../assets/catExtra/SteelcutTrio/F_2965_C0533_W24.tif.jpg'
import STEELCUT_0576 from '../assets/catExtra/SteelcutTrio/F_2965_C0576_W24.tif.jpg'
import STEELCUT_0616 from '../assets/catExtra/SteelcutTrio/F_2965_C0616_W24.tif.jpg'
import STEELCUT_0636 from '../assets/catExtra/SteelcutTrio/F_2965_C0636_W24.tif.jpg'
import STEELCUT_0645 from '../assets/catExtra/SteelcutTrio/F_2965_C0645_W24.tif.jpg'
import STEELCUT_0666 from '../assets/catExtra/SteelcutTrio/F_2965_C0666_W24.tif.jpg'
import STEELCUT_0686 from '../assets/catExtra/SteelcutTrio/F_2965_C0686_W24.tif.jpg'
import STEELCUT_0713 from '../assets/catExtra/SteelcutTrio/F_2965_C0713_W24.tif.jpg'
import STEELCUT_0716 from '../assets/catExtra/SteelcutTrio/F_2965_C0716_W24.tif.jpg'
import STEELCUT_0746 from '../assets/catExtra/SteelcutTrio/F_2965_C0746_W24.tif.jpg'
import STEELCUT_0756 from '../assets/catExtra/SteelcutTrio/F_2965_C0756_W24.tif.jpg'
import STEELCUT_0776 from '../assets/catExtra/SteelcutTrio/F_2965_C0776_W24.tif.jpg'
import STEELCUT_0796 from '../assets/catExtra/SteelcutTrio/F_2965_C0796_W24.tif.jpg'
import STEELCUT_0806 from '../assets/catExtra/SteelcutTrio/F_2965_C0806_W24.tif.jpg'
import STEELCUT_0883 from '../assets/catExtra/SteelcutTrio/F_2965_C0883_W24.tif.jpg'
import STEELCUT_0906 from '../assets/catExtra/SteelcutTrio/F_2965_C0906_W24.tif.jpg'
import STEELCUT_0946 from '../assets/catExtra/SteelcutTrio/F_2965_C0946_W24.tif.jpg'
import STEELCUT_0953 from '../assets/catExtra/SteelcutTrio/F_2965_C0953_W24.tif.jpg'
import STEELCUT_0966 from '../assets/catExtra/SteelcutTrio/F_2965_C0966_W24.tif.jpg'
import STEELCUT_0976 from '../assets/catExtra/SteelcutTrio/F_2965_C0976_W24.tif.jpg'
import STEELCUT_0983 from '../assets/catExtra/SteelcutTrio/F_2965_C0983_W24.tif.jpg'
import STEELCUT_0996 from '../assets/catExtra/SteelcutTrio/F_2965_C0996_W24_HIGH_CMYK.jpg'


import ARIANA_01 from '../assets/catExtra/Ariana/7061.01_highres.jpg'
import ARIANA_02 from '../assets/catExtra/Ariana/7061.02_highres.jpg'
import ARIANA_03 from '../assets/catExtra/Ariana/7061.03_highres.jpg'
import ARIANA_05 from '../assets/catExtra/Ariana/7061.05_highres.jpg'
import ARIANA_06 from '../assets/catExtra/Ariana/7061.06_highres.jpg'
import ARIANA_07 from '../assets/catExtra/Ariana/7061.07_highres.jpg'
import ARIANA_08 from '../assets/catExtra/Ariana/7061.08_highres.jpg'
import ARIANA_09 from '../assets/catExtra/Ariana/7061.09_highres.jpg'
import ARIANA_10 from '../assets/catExtra/Ariana/7061.10_highres.jpg'
import ARIANA_11 from '../assets/catExtra/Ariana/7061.11_highres.jpg'
import ARIANA_12 from '../assets/catExtra/Ariana/7061.12_highres.jpg'
import ARIANA_13 from '../assets/catExtra/Ariana/7061.13_highres.jpg'
import ARIANA_14 from '../assets/catExtra/Ariana/7061.14_highres.jpg'
import ARIANA_17 from '../assets/catExtra/Ariana/7061.17_highres.jpg'
import ARIANA_18 from '../assets/catExtra/Ariana/7061.18_highres.jpg'
import ARIANA_19 from '../assets/catExtra/Ariana/7061.19_highres.jpg'
import ARIANA_20 from '../assets/catExtra/Ariana/7061.20_highres.jpg'
import ARIANA_21 from '../assets/catExtra/Ariana/7061.21_highres.jpg'
import ARIANA_22 from '../assets/catExtra/Ariana/7061.22_highres.jpg'
import ARIANA_24 from '../assets/catExtra/Ariana/7061.24_highres.jpg'
import ARIANA_27 from '../assets/catExtra/Ariana/7061.27_highres.jpg'
import ARIANA_28 from '../assets/catExtra/Ariana/7061.28_highres.jpg'
import ARIANA_29 from '../assets/catExtra/Ariana/7061.29_highres.jpg'
import ARIANA_30 from '../assets/catExtra/Ariana/7061.30_highres.jpg'
import ARIANA_31 from '../assets/catExtra/Ariana/7061.31_highres.jpg'
import ARIANA_32 from '../assets/catExtra/Ariana/7061.32_highres.jpg'
import ARIANA_35 from '../assets/catExtra/Ariana/7061.35_highres.jpg'

import BIT_100 from '../assets/catExtra/Bit/B-chew BIT 100.png'
import BIT_102 from '../assets/catExtra/Bit/B-chew BIT 102.png'
import BIT_103 from '../assets/catExtra/Bit/B-chew BIT 103.png'
import BIT_105 from '../assets/catExtra/Bit/B-chew BIT 105.png'
import BIT_106 from '../assets/catExtra/Bit/B-chew BIT 106.png'
import BIT_108 from '../assets/catExtra/Bit/B-chew BIT 108.png'
import BIT_109 from '../assets/catExtra/Bit/B-chew BIT 109.png'
import BIT_306 from '../assets/catExtra/Bit/B-chew BIT 306.png'
import BIT_307 from '../assets/catExtra/Bit/B-chew BIT 307.png'
import BIT_603 from '../assets/catExtra/Bit/B-chew BIT 603.png'
import BIT_607 from '../assets/catExtra/Bit/B-chew BIT 607.png'
import BIT_608 from '../assets/catExtra/Bit/B-chew BIT 608.png'
import BIT_703 from '../assets/catExtra/Bit/B-chew BIT 703.png'
import BIT_704 from '../assets/catExtra/Bit/B-chew BIT 704.png'
import BIT_705 from '../assets/catExtra/Bit/B-chew BIT 705.png'
import BIT_708 from '../assets/catExtra/Bit/B-chew BIT 708.png'
import BIT_803 from '../assets/catExtra/Bit/B-chew BIT 803.png'
import BIT_807 from '../assets/catExtra/Bit/B-chew BIT 807.png'

import TRIO_104 from '../assets/catExtra/Trio/B-chew TRIO 104.png'
import TRIO_105 from '../assets/catExtra/Trio/B-chew TRIO 105.png'
import TRIO_106 from '../assets/catExtra/Trio/B-chew TRIO 106.png'
import TRIO_107 from '../assets/catExtra/Trio/B-chew TRIO 107.png'
import TRIO_108 from '../assets/catExtra/Trio/B-chew TRIO 108.png'
import TRIO_305 from '../assets/catExtra/Trio/B-chew TRIO 305.png'
import TRIO_307 from '../assets/catExtra/Trio/B-chew TRIO 307.png'
import TRIO_605 from '../assets/catExtra/Trio/B-chew TRIO 605.png'
import TRIO_607 from '../assets/catExtra/Trio/B-chew TRIO 607.png'
import TRIO_703 from '../assets/catExtra/Trio/B-chew TRIO 703.png'
import TRIO_705 from '../assets/catExtra/Trio/B-chew TRIO 705.png'
import TRIO_803 from '../assets/catExtra/Trio/B-chew TRIO 803.png'
import TRIO_804 from '../assets/catExtra/Trio/B-chew TRIO 804.png'
import TRIO_807 from '../assets/catExtra/Trio/B-chew TRIO 807.png'


import CLEO_ANGORA from '../assets/catExtra/Cleo/CLEO_ANGORA.jpg'
import CLEO_ANTHRAZIT from '../assets/catExtra/Cleo/CLEO_ANTHRAZIT.jpg'
import CLEO_CARBON from '../assets/catExtra/Cleo/CLEO_CARBON.jpg'
import CLEO_COAL from '../assets/catExtra/Cleo/CLEO_COAL.jpg'
import CLEO_FLAMINGO from '../assets/catExtra/Cleo/CLEO_FLAMINGO.jpg'
import CLEO_GOLD from '../assets/catExtra/Cleo/CLEO_GOLD.jpg'
import CLEO_INK from '../assets/catExtra/Cleo/CLEO_INK.jpg'
import CLEO_LAMPION from '../assets/catExtra/Cleo/CLEO_LAMPION.jpg'
import CLEO_LANOSO from '../assets/catExtra/Cleo/CLEO_LANOSO.jpg'
import CLEO_LEMON from '../assets/catExtra/Cleo/CLEO_LEMON.jpg'
import CLEO_LINEN from '../assets/catExtra/Cleo/CLEO_LINEN.jpg'
import CLEO_MAGMA from '../assets/catExtra/Cleo/CLEO_MAGMA.jpg'
import CLEO_MARONE from '../assets/catExtra/Cleo/CLEO_MARONE.jpg'
import CLEO_OCEAN from '../assets/catExtra/Cleo/CLEO_OCEAN.jpg'
import CLEO_PASSION from '../assets/catExtra/Cleo/CLEO_PASSION.jpg'
import CLEO_PEACH from '../assets/catExtra/Cleo/CLEO_PEACH.jpg'
import CLEO_PEARL from '../assets/catExtra/Cleo/CLEO_PEARL.jpg'
import CLEO_PEPPER from '../assets/catExtra/Cleo/CLEO_PEPPER.jpg'
import CLEO_ROCK from '../assets/catExtra/Cleo/CLEO_ROCK.jpg'
import CLEO_SALBEI from '../assets/catExtra/Cleo/CLEO_SALBEI.jpg'
import CLEO_SKY from '../assets/catExtra/Cleo/CLEO_SKY.jpg'
import CLEO_WALNUT from '../assets/catExtra/Cleo/CLEO_WALNUT.jpg'
import CLEO_WATER from '../assets/catExtra/Cleo/CLEO_WATER.jpg'


import CREDO_ALOE from '../assets/catExtra/Credo/CREDO_ALOE.jpg'
import CREDO_ASPHALT from '../assets/catExtra/Credo/CREDO_ASPHALT.jpg'
import CREDO_AUREO from '../assets/catExtra/Credo/CREDO_AUREO.jpg'
import CREDO_BLACK from '../assets/catExtra/Credo/CREDO_BLACK.jpg'
import CREDO_BREVA from '../assets/catExtra/Credo/CREDO_BREVA.jpg'
import CREDO_CARBON from '../assets/catExtra/Credo/CREDO_CARBON.jpg'
import CREDO_CHALK from '../assets/catExtra/Credo/CREDO_CHALK.jpg'
import CREDO_DIAMOND from '../assets/catExtra/Credo/CREDO_DIAMOND.jpg'
import CREDO_FANGO from '../assets/catExtra/Credo/CREDO_FANGO.jpg'
import CREDO_FELS from '../assets/catExtra/Credo/CREDO_FELS.jpg'
import CREDO_FOREST from '../assets/catExtra/Credo/CREDO_FOREST.jpg'
import CREDO_GRAPEFRUIT from '../assets/catExtra/Credo/CREDO_GRAPEFRUIT.jpg'
import CREDO_JASPIS from '../assets/catExtra/Credo/CREDO_JASPIS.jpg'
import CREDO_KIPFER from '../assets/catExtra/Credo/CREDO_KIPFER.jpg'
import CREDO_LAKE from '../assets/catExtra/Credo/CREDO_LAKE.jpg'
import CREDO_LAMPION from '../assets/catExtra/Credo/CREDO_LAMPION.jpg'
import CREDO_MACADAMIA from '../assets/catExtra/Credo/CREDO_MACADAMIA.jpg'
import CREDO_NAVY from '../assets/catExtra/Credo/CREDO_NAVY.jpg'
import CREDO_PEPPER from '../assets/catExtra/Credo/CREDO_PEPPER.jpg'
import CREDO_RUBIN from '../assets/catExtra/Credo/CREDO_RUBIN.jpg'
import CREDO_SCHIEFER from '../assets/catExtra/Credo/CREDO_SCHIEFER.jpg'
import CREDO_SEA from '../assets/catExtra/Credo/CREDO_SEA.jpg'
import CREDO_SNOW from '../assets/catExtra/Credo/CREDO_SNOW.jpg'
import CREDO_TAUPE from '../assets/catExtra/Credo/CREDO_TAUPE.jpg'
import CREDO_WALNUT from '../assets/catExtra/Credo/CREDO_WALNUT.jpg'
import CREDO_ZINN from '../assets/catExtra/Credo/CREDO_ZINN.jpg'


import OPERA_ALOE from '../assets/catExtra/Opera/OPERA_ALOE.jpg'
import OPERA_ANTHRAZIT from '../assets/catExtra/Opera/OPERA_ANTHRAZIT.jpg'
import OPERA_AUREO from '../assets/catExtra/Opera/OPERA_AUREO.jpg'
import OPERA_BLEU from '../assets/catExtra/Opera/OPERA_BLEU.jpg'
import OPERA_BRICK from '../assets/catExtra/Opera/OPERA_BRICK.jpg'
import OPERA_CALLA from '../assets/catExtra/Opera/OPERA_CALLA.jpg'
import OPERA_CLEMENTINE from '../assets/catExtra/Opera/OPERA_CLEMENTINE.jpg'
import OPERA_DELFT from '../assets/catExtra/Opera/OPERA_DELFT.jpg'
import OPERA_DELPHI from '../assets/catExtra/Opera/OPERA_DELPHI.jpg'
import OPERA_EBONY from '../assets/catExtra/Opera/OPERA_EBONY.jpg'
import OPERA_FANGO from '../assets/catExtra/Opera/OPERA_FANGO.jpg'
import OPERA_FLANELL from '../assets/catExtra/Opera/OPERA_FLANELL.jpg'
import OPERA_GUAVE from '../assets/catExtra/Opera/OPERA_GUAVE.jpg'
import OPERA_INDIO from '../assets/catExtra/Opera/OPERA_INDIO.jpg'
import OPERA_JADE from '../assets/catExtra/Opera/OPERA_JADE.jpg'
import OPERA_KIES from '../assets/catExtra/Opera/OPERA_KIES.jpg'
import OPERA_KRISTALL from '../assets/catExtra/Opera/OPERA_KRISTALL.jpg'
import OPERA_LAKE from '../assets/catExtra/Opera/OPERA_LAKE.jpg'
import OPERA_MACADAMIA from '../assets/catExtra/Opera/OPERA_MACADAMIA.jpg'
import OPERA_MAPLE from '../assets/catExtra/Opera/OPERA_MAPLE.jpg'
import OPERA_MIRROR from '../assets/catExtra/Opera/OPERA_MIRROR.jpg'
import OPERA_NAVY from '../assets/catExtra/Opera/OPERA_NAVY.jpg'
import OPERA_PEPPER from '../assets/catExtra/Opera/OPERA_PEPPER.jpg'
import OPERA_PRIME from '../assets/catExtra/Opera/OPERA_PRIME.jpg'
import OPERA_ROSEWOOD from '../assets/catExtra/Opera/OPERA_ROSEWOOD.jpg'
import OPERA_SMARAGD from '../assets/catExtra/Opera/OPERA_SMARAGD.jpg'
import OPERA_SORBET from '../assets/catExtra/Opera/OPERA_SORBET.jpg'
import OPERA_TAUPE from '../assets/catExtra/Opera/OPERA_TAUPE.jpg'

import ARCO_AQUA from '../assets/catExtra/Arco/ARCO_AQUA.jpg'
import ARCO_ARCTIC from '../assets/catExtra/Arco/ARCO_ARCTIC.jpg'
import ARCO_BLOSSOM from '../assets/catExtra/Arco/ARCO_BLOSSOM.jpg'
import ARCO_BREEZE from '../assets/catExtra/Arco/ARCO_BREEZE.jpg'
import ARCO_CHERRY from '../assets/catExtra/Arco/ARCO_CHERRY.jpg'
import ARCO_CIRCUS from '../assets/catExtra/Arco/ARCO_CIRCUS.jpg'
import ARCO_CITRON from '../assets/catExtra/Arco/ARCO_CITRON.jpg'
import ARCO_COAST from '../assets/catExtra/Arco/ARCO_COAST.jpg'
/* import ARCO_COBALT from '../assets/catExtra/Arco/ARCO_COBALT.JPG' jpg*/
import ARCO_COFFEE from '../assets/catExtra/Arco/ARCO_COFFE.jpg'
import ARCO_CORN from '../assets/catExtra/Arco/ARCO_CORN.jpg'
import ARCO_EBONY from '../assets/catExtra/Arco/ARCO_EBONY.jpg'
import ARCO_FELS from '../assets/catExtra/Arco/ARCO_FELS.jpg'
import ARCO_FIRE from '../assets/catExtra/Arco/ARCO_FIRE.jpg'
import ARCO_GARDEN from '../assets/catExtra/Arco/ARCO_GARDEN.jpg'
import ARCO_GRAVEL from '../assets/catExtra/Arco/ARCO_GRAVEL.jpg'
import ARCO_INK from '../assets/catExtra/Arco/ARCO_INK.jpg'
import ARCO_JADE from '../assets/catExtra/Arco/ARCO_JADE.jpg'
import ARCO_KIES from '../assets/catExtra/Arco/ARCO_KIES.jpg'
import ARCO_LANOSO from '../assets/catExtra/Arco/ARCO_LANOSO.jpg'
import ARCO_MAGENTA from '../assets/catExtra/Arco/ARCO_MAGENTA.jpg'
import ARCO_MARE from '../assets/catExtra/Arco/ARCO_MARE.jpg'
import ARCO_PEPPLE from '../assets/catExtra/Arco/ARCO_PEBBLE.jpg'
import ARCO_POND from '../assets/catExtra/Arco/ARCO_POND.jpg'
import ARCO_SAFRAN from '../assets/catExtra/Arco/ARCO_SAFRAN.jpg'
import ARCO_SECRET from '../assets/catExtra/Arco/ARCO_SECRET.jpg'
import ARCO_SNOW from '../assets/catExtra/Arco/ARCO_SNOW.jpg'
import ARCO_TRUFFLE from '../assets/catExtra/Arco/ARCO_TRUFFLE.jpg'

const catExtra = [
  {
    category: 'cat. Extra',
    collection: 'Arco',
    items: [
      {
        name: "ARCO AQUA",
        slug: "ARCO AQUA",
        thumbnail: ARCO_AQUA,
      },
      {
        name: "ARCO ARCTIC",
        slug: "ARCO ARCTIC",
        thumbnail: ARCO_ARCTIC,
      },
      {
        name: "ARCO BLOSSOM",
        slug: "ARCO BLOSSOM",
        thumbnail: ARCO_BLOSSOM,
      },
      {
        name: "ARCO BREEZE",
        slug: "ARCO BREEZE",
        thumbnail: ARCO_BREEZE,
      },
      {
        name: "ARCO CHERRY",
        slug: "ARCO CHERRY",
        thumbnail: ARCO_CHERRY,
      },
      {
        name: "ARCO CIRCUS",
        slug: "ARCO CIRCUS",
        thumbnail: ARCO_CIRCUS,
      },
      {
        name: "ARCO CITRON",
        slug: "ARCO CITRON",
        thumbnail: ARCO_CITRON,
      },
      {
        name: "ARCO COAST",
        slug: "ARCO COAST",
        thumbnail: ARCO_COAST,
      },
 /*      {
        name: "ARCO COBALT",
        slug: "ARCO COBALT",
        thumbnail: ARCO_COBALT,
      }, */
      {
        name: "ARCO COFFEE",
        slug: "ARCO COFFEE",
        thumbnail: ARCO_COFFEE,
      },
      {
        name: "ARCO CORN",
        slug: "ARCO CORN",
        thumbnail: ARCO_CORN,
      },
      {
        name: "ARCO EBONY",
        slug: "ARCO EBONY",
        thumbnail: ARCO_EBONY,
      },
      {
        name: "ARCO FELS",
        slug: "ARCO FELS",
        thumbnail: ARCO_FELS,
      },
      {
        name: "ARCO FIRE",
        slug: "ARCO FIRE",
        thumbnail: ARCO_FIRE,
      },
      {
        name: "ARCO GARDEN",
        slug: "ARCO GARDEN",
        thumbnail: ARCO_GARDEN,
      },
      {
        name: "ARCO GRAVEL",
        slug: "ARCO GRAVEL",
        thumbnail: ARCO_GRAVEL,
      },
      {
        name: "ARCO INK",
        slug: "ARCO INK",
        thumbnail: ARCO_INK,
      },
      {
        name: "ARCO JADE",
        slug: "ARCO JADE",
        thumbnail: ARCO_JADE,
      },
      {
        name: "ARCO KIES",
        slug: "ARCO KIES",
        thumbnail: ARCO_KIES,
      },
      {
        name: "ARCO LANOSO",
        slug: "ARCO LANOSO",
        thumbnail: ARCO_LANOSO,
      },
      {
        name: "ARCO MAGENTA",
        slug: "ARCO MAGENTA",
        thumbnail: ARCO_MAGENTA,
      },
      {
        name: "ARCO MARE",
        slug: "ARCO MARE",
        thumbnail: ARCO_MARE,
      },
      {
        name: "ARCO PEPPLE",
        slug: "ARCO PEPPLE",
        thumbnail: ARCO_PEPPLE,
      },
      {
        name: "ARCO POND",
        slug: "ARCO POND",
        thumbnail: ARCO_POND,
      },
      {
        name: "ARCO SAFRAN",
        slug: "ARCO SAFRAN",
        thumbnail: ARCO_SAFRAN,
      },
      {
        name: "ARCO SECRET",
        slug: "ARCO SECRET",
        thumbnail: ARCO_SECRET,
      },
      {
        name: "ARCO SNOW",
        slug: "ARCO SNOW",
        thumbnail: ARCO_SNOW,
      },
      {
        name: "ARCO TRUFFLE",
        slug: "ARCO TRUFFLE",
        thumbnail: ARCO_TRUFFLE,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Opera',
    items: [
      {
        name: "OPERA ALOE",
        slug: "OPERA ALOE",
        thumbnail: OPERA_ALOE,
      },
      {
        name: "OPERA ANTHRAZITE",
        slug: "OPERA ANTHRAZIT",
        thumbnail: OPERA_ANTHRAZIT,
      },
      {
        name: "OPERA AUREOE",
        slug: "OPERA AUREO",
        thumbnail: OPERA_AUREO,
      },
      {
        name: "OPERA BLEU",
        slug: "OPERA BLEU",
        thumbnail: OPERA_BLEU,
      },
      {
        name: "OPERA BRICK",
        slug: "OPERA BRICK",
        thumbnail: OPERA_BRICK,
      },
      {
        name: "OPERA CALLA",
        slug: "OPERA CALLA",
        thumbnail: OPERA_CALLA,
      },
      {
        name: "OPERA CLEMENTINE",
        slug: "OPERA CLEMENTINE",
        thumbnail: OPERA_CLEMENTINE,
      },
      {
        name: "OPERA DELFT",
        slug: "OPERA DELFT",
        thumbnail: OPERA_DELFT,
      },
      {
        name: "OPERA DELPHI",
        slug: "OPERA DELPHI",
        thumbnail: OPERA_DELPHI,
      },
      {
        name: "OPERA EBONY",
        slug: "OPERA EBONY",
        thumbnail: OPERA_EBONY,
      },
      {
        name: "OPERA FANGO",
        slug: "OPERA FANGO",
        thumbnail: OPERA_FANGO,
      },
      {
        name: "OPERA FLANELL",
        slug: "OPERA FLANELL",
        thumbnail: OPERA_FLANELL,
      },
      {
        name: "OPERA GUAVE",
        slug: "OPERA GUAVE",
        thumbnail: OPERA_GUAVE,
      },
      {
        name: "OPERA INDIO",
        slug: "OPERA INDIO",
        thumbnail: OPERA_INDIO,
      },
      {
        name: "OPERA JADE",
        slug: "OPERA JADE",
        thumbnail: OPERA_JADE,
      },
      {
        name: "OPERA KIES",
        slug: "OPERA KIES",
        thumbnail: OPERA_KIES,
      },
      {
        name: "OPERA KRISTALL",
        slug: "OPERA KRISTALL",
        thumbnail: OPERA_KRISTALL,
      },
      {
        name: "OPERA LAKE",
        slug: "OPERA LAKE",
        thumbnail: OPERA_LAKE,
      },
      {
        name: "OPERA MACADAMIA",
        slug: "OPERA MACADAMIA",
        thumbnail: OPERA_MACADAMIA,
      },
      {
        name: "OPERA MAPLE",
        slug: "OPERA MAPLE",
        thumbnail: OPERA_MAPLE,
      },
      {
        name: "OPERA MIRROR",
        slug: "OPERA MIRROR",
        thumbnail: OPERA_MIRROR,
      },
      {
        name: "OPERA NAVY",
        slug: "OPERA NAVY",
        thumbnail: OPERA_NAVY,
      },
      {
        name: "OPERA PEPPER",
        slug: "OPERA PEPPER",
        thumbnail: OPERA_PEPPER,
      },
      {
        name: "OPERA PRIME",
        slug: "OPERA PRIME",
        thumbnail: OPERA_PRIME,
      },
      {
        name: "OPERA ROSEWOODE",
        slug: "OPERA ROSEWOOD",
        thumbnail: OPERA_ROSEWOOD,
      },
      {
        name: "OPERA SMARAGD",
        slug: "OPERA SMARAGD",
        thumbnail: OPERA_SMARAGD,
      },
      {
        name: "OPERA SORBET",
        slug: "OPERA SORBET",
        thumbnail: OPERA_SORBET,
      },
      {
        name: "OPERA TAUPE",
        slug: "OPERA TAUPE",
        thumbnail: OPERA_TAUPE,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Credo',
    items: [
      {
        name: "CREDO ALOE",
        slug: "CREDO ALOE",
        thumbnail: CREDO_ALOE,
      },
      {
        name: "CREDO ASPHALT",
        slug: "CREDO ASPHALT",
        thumbnail: CREDO_ASPHALT,
      },
      {
        name: "CREDO AUREO",
        slug: "CREDO AUREO",
        thumbnail: CREDO_AUREO,
      },
      {
        name: "CREDO BLACK",
        slug: "CREDO BLACK",
        thumbnail: CREDO_BLACK,
      },
      {
        name: "CREDO BREVA",
        slug: "CREDO BREVA",
        thumbnail: CREDO_BREVA,
      },
      {
        name: "CREDO CARBON",
        slug: "CREDO CARBON",
        thumbnail: CREDO_CARBON,
      },
      {
        name: "CREDO CHALK",
        slug: "CREDO CHALK",
        thumbnail: CREDO_CHALK,
      },
      {
        name: "CREDO DIAMOND",
        slug: "CREDO DIAMOND",
        thumbnail: CREDO_DIAMOND,
      },
      {
        name: "CREDO FANGO",
        slug: "CREDO FANGO",
        thumbnail: CREDO_FANGO,
      },
      {
        name: "CREDO FELS",
        slug: "CREDO FELS",
        thumbnail: CREDO_FELS,
      },
      {
        name: "CREDO FOREST",
        slug: "CREDO FOREST",
        thumbnail: CREDO_FOREST,
      },
      {
        name: "CREDO GRAPEFRUIT",
        slug: "CREDO GRAPEFRUIT",
        thumbnail: CREDO_GRAPEFRUIT,
      },
      {
        name: "CREDO JASPIS",
        slug: "CREDO JASPIS",
        thumbnail: CREDO_JASPIS,
      },
      {
        name: "CREDO KIPFER",
        slug: "CREDO KIPFER",
        thumbnail: CREDO_KIPFER,
      },
      {
        name: "CREDO LAKE",
        slug: "CREDO LAKE",
        thumbnail: CREDO_LAKE,
      },
      {
        name: "CREDO LAMPION",
        slug: "CREDO LAMPION",
        thumbnail: CREDO_LAMPION,
      },
      {
        name: "CREDO MACADAMIA",
        slug: "CREDO MACADAMIA",
        thumbnail: CREDO_MACADAMIA,
      },
      {
        name: "CREDO NAVY",
        slug: "CREDO NAVY",
        thumbnail: CREDO_NAVY,
      },
      {
        name: "CREDO PEPPER",
        slug: "CREDO PEPPER",
        thumbnail: CREDO_PEPPER,
      },
      {
        name: "CREDO RUBIN",
        slug: "CREDO RUBIN",
        thumbnail: CREDO_RUBIN,
      },
      {
        name: "CREDO SCHIEFER",
        slug: "CREDO SCHIEFER",
        thumbnail: CREDO_SCHIEFER,
      },
      {
        name: "CREDO SEA",
        slug: "CREDO SEA",
        thumbnail: CREDO_SEA,
      },
      {
        name: "CREDO SNOW",
        slug: "CREDO SNOW",
        thumbnail: CREDO_SNOW,
      },
      {
        name: "CREDO TAUPE",
        slug: "CREDO TAUPE",
        thumbnail: CREDO_TAUPE,
      },
      {
        name: "CREDO WALNUT",
        slug: "CREDO WALNUT",
        thumbnail: CREDO_WALNUT,
      },
      {
        name: "CREDO ZINN",
        slug: "CREDO ZINN",
        thumbnail: CREDO_ZINN,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Cleo',
    items: [
      {
        name: "CLEO ANGORA",
        slug: "CLEO ANGORA",
        thumbnail: CLEO_ANGORA,
      },
      {
        name: "CLEO ANTHRAZIT",
        slug: "CLEO ANTHRAZIT",
        thumbnail: CLEO_ANTHRAZIT,
      },
      {
        name: "CLEO CARBON",
        slug: "CLEO CARBON",
        thumbnail: CLEO_CARBON,
      },
      {
        name: "CLEO COAL",
        slug: "CLEO COAL",
        thumbnail: CLEO_COAL,
      },
      {
        name: "CLEO FLAMINGO",
        slug: "CLEO FLAMINGO",
        thumbnail: CLEO_FLAMINGO,
      },
      {
        name: "CLEO GOLD",
        slug: "CLEO GOLD",
        thumbnail: CLEO_GOLD,
      },
      {
        name: "CLEO INK",
        slug: "CLEO INK",
        thumbnail: CLEO_INK,
      },
      {
        name: "CLEO LAMPION",
        slug: "CLEO LAMPION",
        thumbnail: CLEO_LAMPION,
      },
      {
        name: "CLEO LANOSO",
        slug: "CLEO LANOSO",
        thumbnail: CLEO_LANOSO,
      },
      {
        name: "CLEO LEMON",
        slug: "CLEO LEMON",
        thumbnail: CLEO_LEMON,
      },
      {
        name: "CLEO LINEN",
        slug: "CLEO LINEN",
        thumbnail: CLEO_LINEN,
      },
      {
        name: "CLEO MAGMA",
        slug: "CLEO MAGMA",
        thumbnail: CLEO_MAGMA,
      },
      {
        name: "CLEO MARONE",
        slug: "CLEO MARONE",
        thumbnail: CLEO_MARONE,
      },
      {
        name: "CLEO OCEAN",
        slug: "CLEO OCEAN",
        thumbnail: CLEO_OCEAN,
      },
      {
        name: "CLEO PASSION",
        slug: "CLEO PASSION",
        thumbnail: CLEO_PASSION,
      },
      {
        name: "CLEO PEACH",
        slug: "CLEO PEACH",
        thumbnail: CLEO_PEACH,
      },
      {
        name: "CLEO PEARL",
        slug: "CLEO PEARL",
        thumbnail: CLEO_PEARL,
      },
      {
        name: "CLEO PEPPER",
        slug: "CLEO PEPPER",
        thumbnail: CLEO_PEPPER,
      },
      {
        name: "CLEO ROCK",
        slug: "CLEO ROCK",
        thumbnail: CLEO_ROCK,
      },
      {
        name: "CLEO SALBEI",
        slug: "CLEO SALBEI",
        thumbnail: CLEO_SALBEI,
      },
      {
        name: "CLEO SKY",
        slug: "CLEO SKY",
        thumbnail: CLEO_SKY,
      },
      {
        name: "CLEO WALNUT",
        slug: "CLEO WALNUT",
        thumbnail: CLEO_WALNUT,
      },
      {
        name: "CLEO WATER",
        slug: "CLEO WATER",
        thumbnail: CLEO_WATER,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Trio',
    items: [
      {
        name: "TRIO 104",
        slug: "TRIO 104",
        thumbnail: TRIO_104,
      },
      {
        name: "TRIO 105",
        slug: "TRIO 105",
        thumbnail: TRIO_105,
      },
      {
        name: "TRIO 106",
        slug: "TRIO 106",
        thumbnail: TRIO_106,
      },
      {
        name: "TRIO 107",
        slug: "TRIO 107",
        thumbnail: TRIO_107,
      },
      {
        name: "TRIO 108",
        slug: "TRIO 108",
        thumbnail: TRIO_108,
      },
      {
        name: "TRIO 305",
        slug: "TRIO 305",
        thumbnail: TRIO_305,
      },
      {
        name: "TRIO 307",
        slug: "TRIO 307",
        thumbnail: TRIO_307,
      },
      {
        name: "TRIO 605",
        slug: "TRIO 605",
        thumbnail: TRIO_605,
      },
      {
        name: "TRIO 607",
        slug: "TRIO 607",
        thumbnail: TRIO_607,
      },
      {
        name: "TRIO 703",
        slug: "TRIO 703",
        thumbnail: TRIO_703,
      },
      {
        name: "TRIO 705",
        slug: "TRIO 705",
        thumbnail: TRIO_705,
      },
      {
        name: "TRIO 803",
        slug: "TRIO 803",
        thumbnail: TRIO_803,
      },
      {
        name: "TRIO 804",
        slug: "TRIO 804",
        thumbnail: TRIO_804,
      },
      {
        name: "TRIO 807",
        slug: "TRIO 807",
        thumbnail: TRIO_807,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Bit',
    items: [
      {
        name: "BIT 100",
        slug: "BIT 100",
        thumbnail: BIT_100,
      },
      {
        name: "BIT 102",
        slug: "BIT 102",
        thumbnail: BIT_102,
      },
      {
        name: "BIT 103",
        slug: "BIT 103",
        thumbnail: BIT_103,
      },
      {
        name: "BIT 105",
        slug: "BIT 105",
        thumbnail: BIT_105,
      },
      {
        name: "BIT 106",
        slug: "BIT 106",
        thumbnail: BIT_106,
      },
      {
        name: "BIT 108",
        slug: "BIT 108",
        thumbnail: BIT_108,
      },
      {
        name: "BIT 109",
        slug: "BIT 109",
        thumbnail: BIT_109,
      },
      {
        name: "BIT 306",
        slug: "BIT 306",
        thumbnail: BIT_306,
      },
      {
        name: "BIT 307",
        slug: "BIT 307",
        thumbnail: BIT_307,
      },
      {
        name: "BIT 603",
        slug: "BIT 603",
        thumbnail: BIT_603,
      },
      {
        name: "BIT 607",
        slug: "BIT 607",
        thumbnail: BIT_607,
      },
      {
        name: "BIT 608",
        slug: "BIT 608",
        thumbnail: BIT_608,
      },
      {
        name: "BIT 703",
        slug: "BIT 703",
        thumbnail: BIT_703,
      },
      {
        name: "BIT 704",
        slug: "BIT 704",
        thumbnail: BIT_704,
      },
      {
        name: "BIT 705",
        slug: "BIT 705",
        thumbnail: BIT_705,
      },
      {
        name: "BIT 708",
        slug: "BIT 708",
        thumbnail: BIT_708,
      },
      {
        name: "BIT 803",
        slug: "BIT 803",
        thumbnail: BIT_803,
      },
      {
        name: "BIT 807",
        slug: "BIT 807",
        thumbnail: BIT_807,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Ariana',
    items: [
      {
        name: "ARIANA 7061.01",
        slug: "ARIANA 7061.01",
        thumbnail: ARIANA_01,
      },
      {
        name: "ARIANA 7061.02",
        slug: "ARIANA 7061.02",
        thumbnail: ARIANA_02,
      },
      {
        name: "ARIANA 7061.03",
        slug: "ARIANA 7061.03",
        thumbnail: ARIANA_03,
      },
      {
        name: "ARIANA 7061.05",
        slug: "ARIANA 7061.05",
        thumbnail: ARIANA_05,
      },
      {
        name: "ARIANA 7061.06",
        slug: "ARIANA 7061.06",
        thumbnail: ARIANA_06,
      },
      {
        name: "ARIANA 7061.07",
        slug: "ARIANA 7061.07",
        thumbnail: ARIANA_07,
      },
      {
        name: "ARIANA 7061.08",
        slug: "ARIANA 7061.08",
        thumbnail: ARIANA_08,
      },
      {
        name: "ARIANA 7061.09",
        slug: "ARIANA 7061.09",
        thumbnail: ARIANA_09,
      },
      {
        name: "ARIANA 7061.10",
        slug: "ARIANA 7061.10",
        thumbnail: ARIANA_10,
      },
      {
        name: "ARIANA 7061.11",
        slug: "ARIANA 7061.11",
        thumbnail: ARIANA_11,
      },
      {
        name: "ARIANA 7061.12",
        slug: "ARIANA 7061.12",
        thumbnail: ARIANA_12,
      },
      {
        name: "ARIANA 7061.13",
        slug: "ARIANA 7061.13",
        thumbnail: ARIANA_13,
      },
      {
        name: "ARIANA 7061.14",
        slug: "ARIANA 7061.14",
        thumbnail: ARIANA_14,
      },
      {
        name: "ARIANA 7061.17",
        slug: "ARIANA 7061.17",
        thumbnail: ARIANA_17,
      },
      {
        name: "ARIANA 7061.18",
        slug: "ARIANA 7061.18",
        thumbnail: ARIANA_18,
      },
      {
        name: "ARIANA 7061.19",
        slug: "ARIANA 7061.19",
        thumbnail: ARIANA_19,
      },
      {
        name: "ARIANA 7061.20",
        slug: "ARIANA 7061.20",
        thumbnail: ARIANA_20,
      },
      {
        name: "ARIANA 7061.21",
        slug: "ARIANA 7061.21",
        thumbnail: ARIANA_21,
      },
      {
        name: "ARIANA 7061.22",
        slug: "ARIANA 7061.22",
        thumbnail: ARIANA_22,
      },
      {
        name: "ARIANA 7061.24",
        slug: "ARIANA 7061.24",
        thumbnail: ARIANA_24,
      },
      {
        name: "ARIANA 7061.27",
        slug: "ARIANA 7061.27",
        thumbnail: ARIANA_27,
      },
      {
        name: "ARIANA 7061.28",
        slug: "ARIANA 7061.28",
        thumbnail: ARIANA_28,
      },
      {
        name: "ARIANA 7061.29",
        slug: "ARIANA 7061.29",
        thumbnail: ARIANA_29,
      },
      {
        name: "ARIANA 7061.30",
        slug: "ARIANA 7061.30",
        thumbnail: ARIANA_30,
      },
      {
        name: "ARIANA 7061.31",
        slug: "ARIANA 7061.31",
        thumbnail: ARIANA_31,
      },
      {
        name: "ARIANA 7061.32",
        slug: "ARIANA 7061.32",
        thumbnail: ARIANA_32,
      },
      {
        name: "ARIANA 7061.35",
        slug: "ARIANA 7061.35",
        thumbnail: ARIANA_35,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Steelcut Trio',
    items: [
      {
        name: "STEELCUT TRIO 0105",
        slug: "STEELCUT TRIO 0105",
        thumbnail:  STEELCUT_0105,
      },
      {
        name: "STEELCUT TRIO 0113",
        slug: "STEELCUT TRIO 0113",
        thumbnail:  STEELCUT_0113,
      },
      {
        name: "STEELCUT TRIO 0124",
        slug: "STEELCUT TRIO 0124",
        thumbnail:  STEELCUT_0124,
      },
      {
        name: "STEELCUT TRIO 0133",
        slug: "STEELCUT TRIO 0133",
        thumbnail:  STEELCUT_0133,
      },
      {
        name: "STEELCUT TRIO 0153",
        slug: "STEELCUT TRIO 0153",
        thumbnail:  STEELCUT_0153,
      },
      {
        name: "STEELCUT TRIO 0176",
        slug: "STEELCUT TRIO 0176",
        thumbnail:  STEELCUT_0176,
      },
      {
        name: "STEELCUT TRIO 0195",
        slug: "STEELCUT TRIO 0195",
        thumbnail:  STEELCUT_0195,
      },
      {
        name: "STEELCUT TRIO 0205",
        slug: "STEELCUT TRIO 0205",
        thumbnail:  STEELCUT_0205,
      },
      {
        name: "STEELCUT TRIO 0213",
        slug: "STEELCUT TRIO 0213",
        thumbnail:  STEELCUT_0213,
      },
      {
        name: "STEELCUT TRIO 0246",
        slug: "STEELCUT TRIO 0246",
        thumbnail:  STEELCUT_0246,
      },
      {
        name: "STEELCUT TRIO 0253",
        slug: "STEELCUT TRIO 0253",
        thumbnail:  STEELCUT_0253,
      },
      {
        name: "STEELCUT TRIO 0266",
        slug: "STEELCUT TRIO 0266",
        thumbnail:  STEELCUT_0266,
      },
      {
        name: "STEELCUT TRIO 0276",
        slug: "STEELCUT TRIO 0276",
        thumbnail:  STEELCUT_0276,
      },
      {
        name: "STEELCUT TRIO 0283",
        slug: "STEELCUT TRIO 0283",
        thumbnail:  STEELCUT_0283,
      },
      {
        name: "STEELCUT TRIO 0336",
        slug: "STEELCUT TRIO 0336",
        thumbnail:  STEELCUT_0336,
      },
      {
        name: "STEELCUT TRIO 0376",
        slug: "STEELCUT TRIO 0376",
        thumbnail:  STEELCUT_0376,
      },
      {
        name: "STEELCUT TRIO 0383",
        slug: "STEELCUT TRIO 0383",
        thumbnail:  STEELCUT_0383,
      },
      {
        name: "STEELCUT TRIO 0416",
        slug: "STEELCUT TRIO 0416",
        thumbnail:  STEELCUT_0416,
      },
      {
        name: "STEELCUT TRIO 0426",
        slug: "STEELCUT TRIO 0426",
        thumbnail:  STEELCUT_0426,
      },
      {
        name: "STEELCUT TRIO 0446",
        slug: "STEELCUT TRIO 0446",
        thumbnail:  STEELCUT_0446,
      },
      {
        name: "STEELCUT TRIO 0453",
        slug: "STEELCUT TRIO 0453",
        thumbnail:  STEELCUT_0453,
      },
      {
        name: "STEELCUT TRIO 0466",
        slug: "STEELCUT TRIO 0466",
        thumbnail:  STEELCUT_0466,
      },
      {
        name: "STEELCUT TRIO 0476",
        slug: "STEELCUT TRIO 0476",
        thumbnail:  STEELCUT_0476,
      },
      {
        name: "STEELCUT TRIO 0515",
        slug: "STEELCUT TRIO 0515",
        thumbnail:  STEELCUT_0515,
      },
      {
        name: "STEELCUT TRIO 0526",
        slug: "STEELCUT TRIO 0526",
        thumbnail:  STEELCUT_0526,
      },
      {
        name: "STEELCUT TRIO 0533",
        slug: "STEELCUT TRIO 0533",
        thumbnail:  STEELCUT_0533,
      },
      {
        name: "STEELCUT TRIO 0576",
        slug: "STEELCUT TRIO 0576",
        thumbnail:  STEELCUT_0576,
      },
      {
        name: "STEELCUT TRIO 0616",
        slug: "STEELCUT TRIO 0616",
        thumbnail:  STEELCUT_0616,
      },
      {
        name: "STEELCUT TRIO 0636",
        slug: "STEELCUT TRIO 0636",
        thumbnail:  STEELCUT_0636,
      },
      {
        name: "STEELCUT TRIO 0645",
        slug: "STEELCUT TRIO 0645",
        thumbnail:  STEELCUT_0645,
      },
      {
        name: "STEELCUT TRIO 0666",
        slug: "STEELCUT TRIO 0666",
        thumbnail:  STEELCUT_0666,
      },
      {
        name: "STEELCUT TRIO 0686",
        slug: "STEELCUT TRIO 0686",
        thumbnail:  STEELCUT_0686,
      },
      {
        name: "STEELCUT TRIO 0713",
        slug: "STEELCUT TRIO 0713",
        thumbnail:  STEELCUT_0713,
      },
      {
        name: "STEELCUT TRIO 0716",
        slug: "STEELCUT TRIO 0716",
        thumbnail:  STEELCUT_0716,
      },
      {
        name: "STEELCUT TRIO 0746",
        slug: "STEELCUT TRIO 0746",
        thumbnail:  STEELCUT_0746,
      },
      {
        name: "STEELCUT TRIO 0756",
        slug: "STEELCUT TRIO 0756",
        thumbnail:  STEELCUT_0756,
      },
      {
        name: "STEELCUT TRIO 0776",
        slug: "STEELCUT TRIO 0776",
        thumbnail:  STEELCUT_0776,
      },
      {
        name: "STEELCUT TRIO 0796",
        slug: "STEELCUT TRIO 0796",
        thumbnail:  STEELCUT_0796,
      },
      {
        name: "STEELCUT TRIO 0806",
        slug: "STEELCUT TRIO 0806",
        thumbnail:  STEELCUT_0806,
      },
      {
        name: "STEELCUT TRIO 0883",
        slug: "STEELCUT TRIO 0883",
        thumbnail:  STEELCUT_0883,
      },
      {
        name: "STEELCUT TRIO 0906",
        slug: "STEELCUT TRIO 0906",
        thumbnail:  STEELCUT_0906,
      },
      {
        name: "STEELCUT TRIO 0946",
        slug: "STEELCUT TRIO 0946",
        thumbnail:  STEELCUT_0946,
      },
      {
        name: "STEELCUT TRIO 0953",
        slug: "STEELCUT TRIO 0953",
        thumbnail:  STEELCUT_0953,
      },
      {
        name: "STEELCUT TRIO 0966",
        slug: "STEELCUT TRIO 0966",
        thumbnail:  STEELCUT_0966,
      },
      {
        name: "STEELCUT TRIO 0976",
        slug: "STEELCUT TRIO 0976",
        thumbnail:  STEELCUT_0976,
      },
      {
        name: "STEELCUT TRIO 0983",
        slug: "STEELCUT TRIO 0983",
        thumbnail:  STEELCUT_0983,
      },
      {
        name: "STEELCUT TRIO 0996",
        slug: "STEELCUT TRIO 0996",
        thumbnail:  STEELCUT_0996,
      }
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Molly 2',
    items: [
      {
        name: "MOLLY 2 0110",
        slug: "MOLLY 2 0110",
        thumbnail: MOLLY_0110,
      },
      {
        name: "MOLLY 2 0112",
        slug: "MOLLY 2 0112",
        thumbnail: MOLLY_0112,
      },
      {
        name: "MOLLY 2 0114",
        slug: "MOLLY 2 0114",
        thumbnail: MOLLY_0114,
      },
      {
        name: "MOLLY 2 0116",
        slug: "MOLLY 2 0116",
        thumbnail: MOLLY_0116,
      },
      {
        name: "MOLLY 2 0122",
        slug: "MOLLY 2 0122",
        thumbnail: MOLLY_0122,
      },
      {
        name: "MOLLY 2 0152",
        slug: "MOLLY 2 0152",
        thumbnail: MOLLY_0152,
      },
      {
        name: "MOLLY 2 0154",
        slug: "MOLLY 2 0154",
        thumbnail: MOLLY_0154,
      },
      {
        name: "MOLLY 2 0160",
        slug: "MOLLY 2 0160",
        thumbnail: MOLLY_0160,
      },
      {
        name: "MOLLY 2 0164",
        slug: "MOLLY 2 0164",
        thumbnail: MOLLY_0164,
      },
      {
        name: "MOLLY 2 0166",
        slug: "MOLLY 2 0166",
        thumbnail: MOLLY_0166,
      },
      {
        name: "MOLLY 2 0170",
        slug: "MOLLY 2 0170",
        thumbnail: MOLLY_0170,
      },
      {
        name: "MOLLY 2 0190",
        slug: "MOLLY 2 0190",
        thumbnail: MOLLY_0190,
      },
      {
        name: "MOLLY 2 0192",
        slug: "MOLLY 2 0192",
        thumbnail: MOLLY_0192,
      },
      {
        name: "MOLLY 2 0194",
        slug: "MOLLY 2 0194",
        thumbnail: MOLLY_0194,
      },
      {
        name: "MOLLY 2 0196",
        slug: "MOLLY 2 0196",
        thumbnail: MOLLY_0196,
      },
    ]
  },
  {
    category: 'cat. Extra',
    collection: 'Colline 2',
    items: [
      {
        name: "COLLINE 0127",
        slug: "COLLINE 0127",
        thumbnail: COLLINE_0127,
      },
      {
        name: "COLLINE 0147",
        slug: "COLLINE 0147",
        thumbnail: COLLINE_0147,
      },
      {
        name: "COLLINE 0177",
        slug: "COLLINE 0177",
        thumbnail: COLLINE_0177,
      },
      {
        name: "COLLINE 0227",
        slug: "COLLINE 0227",
        thumbnail: COLLINE_0227,
      },
      {
        name: "COLLINE 0347",
        slug: "COLLINE 0347",
        thumbnail: COLLINE_0347,
      },
      {
        name: "COLLINE 0397",
        slug: "COLLINE 0397",
        thumbnail: COLLINE_0397,
      },
      {
        name: "COLLINE 0657",
        slug: "COLLINE 0657",
        thumbnail: COLLINE_0657,
      },
      {
        name: "COLLINE 0677",
        slug: "COLLINE 0677",
        thumbnail: COLLINE_0677,
      },
      {
        name: "COLLINE 0737",
        slug: "COLLINE 0737",
        thumbnail: COLLINE_0737,
      },
      {
        name: "COLLINE 0787",
        slug: "COLLINE 0787",
        thumbnail: COLLINE_0787,
      },
      {
        name: "COLLINE 0797",
        slug: "COLLINE 0797",
        thumbnail: COLLINE_0797,
      },
      {
        name: "COLLINE 0987",
        slug: "COLLINE 0987",
        thumbnail: COLLINE_0987,
      },
    ]
  }
]
  export default catExtra