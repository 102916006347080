//cat Maxi


import DIVINA_0106 from '../assets/catMaxi/Divina3/F_1200_C0106_W24.tif.jpg'
import DIVINA_0154 from '../assets/catMaxi/Divina3/F_1200_C0154_W24.tif.jpg'
import DIVINA_0171 from '../assets/catMaxi/Divina3/F_1200_C0171_W24.tif.jpg'
import DIVINA_0173 from '../assets/catMaxi/Divina3/F_1200_C0173_W24.tif.jpg'
import DIVINA_0181 from '../assets/catMaxi/Divina3/F_1200_C0181_W24.tif.jpg'
import DIVINA_0191 from '../assets/catMaxi/Divina3/F_1200_C0191_W24.tif.jpg'
import DIVINA_0224 from '../assets/catMaxi/Divina3/F_1200_C0224_W24.tif.jpg'
import DIVINA_0246 from '../assets/catMaxi/Divina3/F_1200_C0246_W24.tif.jpg'
import DIVINA_0346 from '../assets/catMaxi/Divina3/F_1200_C0346_W24.tif.jpg'
import DIVINA_0356 from '../assets/catMaxi/Divina3/F_1200_C0356_W24.tif.jpg'
import DIVINA_0384 from '../assets/catMaxi/Divina3/F_1200_C0384_W24.tif.jpg'
import DIVINA_0393 from '../assets/catMaxi/Divina3/F_1200_C0393_W24.tif.jpg'
import DIVINA_0444 from '../assets/catMaxi/Divina3/F_1200_C0444_W24.tif.jpg'
import DIVINA_0462 from '../assets/catMaxi/Divina3/F_1200_C0426_W24.tif.jpg'
import DIVINA_0536 from '../assets/catMaxi/Divina3/F_1200_C0536_W24.tif.jpg'
import DIVINA_0542 from '../assets/catMaxi/Divina3/F_1200_C0542_W24.tif.jpg'
import DIVINA_0623 from '../assets/catMaxi/Divina3/F_1200_C0623_W24.tif.jpg'
import DIVINA_0636 from '../assets/catMaxi/Divina3/F_1200_C0636_W24.tif.jpg'
import DIVINA_0652 from '../assets/catMaxi/Divina3/F_1200_C0652_W24.tif.jpg'
import DIVINA_0666 from '../assets/catMaxi/Divina3/F_1200_C0666_W24.tif.jpg'
import DIVINA_0676 from '../assets/catMaxi/Divina3/F_1200_C0676_W24.tif.jpg'
import DIVINA_0686 from '../assets/catMaxi/Divina3/F_1200_C0686_W24.tif.jpg'
import DIVINA_0691 from '../assets/catMaxi/Divina3/F_1200_C0691_W24.tif.jpg'
import DIVINA_0692 from '../assets/catMaxi/Divina3/F_1200_C0692_W24.tif.jpg'
import DIVINA_0696 from '../assets/catMaxi/Divina3/F_1200_C0696_W24.tif.jpg'
import DIVINA_0712 from '../assets/catMaxi/Divina3/F_1200_C0712_W24.tif.jpg'
import DIVINA_0756 from '../assets/catMaxi/Divina3/F_1200_C0756_W24.tif.jpg'
import DIVINA_0782 from '../assets/catMaxi/Divina3/F_1200_C0782_W24.tif.jpg'
import DIVINA_0791 from '../assets/catMaxi/Divina3/F_1200_C0791_W24.tif.jpg'
import DIVINA_0826 from '../assets/catMaxi/Divina3/F_1200_C0826_W24.tif.jpg'
import DIVINA_0846 from '../assets/catMaxi/Divina3/F_1200_C0846_W24.tif.jpg'
import DIVINA_0876 from '../assets/catMaxi/Divina3/F_1200_C0876_W24.tif.jpg'
import DIVINA_0922 from '../assets/catMaxi/Divina3/F_1200_C0922_W24.tif.jpg'
import DIVINA_0936 from '../assets/catMaxi/Divina3/F_1200_C0936_W24.tif.jpg'
import DIVINA_0956 from '../assets/catMaxi/Divina3/F_1200_C0956_W24.tif.jpg'


import HALLINGDAL_0100 from '../assets/catMaxi/Hallingdal65/F_1000_C0100_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0103 from '../assets/catMaxi/Hallingdal65/F_1000_C0103_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0110 from '../assets/catMaxi/Hallingdal65/F_1000_C0110_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0113 from '../assets/catMaxi/Hallingdal65/F_1000_C0113_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0116 from '../assets/catMaxi/Hallingdal65/F_1000_C0116_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0123 from '../assets/catMaxi/Hallingdal65/F_1000_C0123_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0130 from '../assets/catMaxi/Hallingdal65/F_1000_C0130_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0143 from '../assets/catMaxi/Hallingdal65/F_1000_C0143_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0153 from '../assets/catMaxi/Hallingdal65/F_1000_C0153_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0166 from '../assets/catMaxi/Hallingdal65/F_1000_C0166_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0173 from '../assets/catMaxi/Hallingdal65/F_1000_C0173_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0180 from '../assets/catMaxi/Hallingdal65/F_1000_C0180_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0190 from '../assets/catMaxi/Hallingdal65/F_1000_C0190_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0200 from '../assets/catMaxi/Hallingdal65/F_1000_C0200_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0220 from '../assets/catMaxi/Hallingdal65/F_1000_C0220_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0227 from '../assets/catMaxi/Hallingdal65/F_1000_C0227_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0270 from '../assets/catMaxi/Hallingdal65/F_1000_C0270_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0368 from '../assets/catMaxi/Hallingdal65/F_1000_C0368_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0370 from '../assets/catMaxi/Hallingdal65/F_1000_C0370_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0376 from '../assets/catMaxi/Hallingdal65/F_1000_C0376_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0407 from '../assets/catMaxi/Hallingdal65/F_1000_C0407_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0420 from '../assets/catMaxi/Hallingdal65/F_1000_C0420_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0457 from '../assets/catMaxi/Hallingdal65/F_1000_C0457_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0526 from '../assets/catMaxi/Hallingdal65/F_1000_C0526_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0547 from '../assets/catMaxi/Hallingdal65/F_1000_C0547_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0563 from '../assets/catMaxi/Hallingdal65/F_1000_C0563_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0573 from '../assets/catMaxi/Hallingdal65/F_1000_C0573_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0590 from '../assets/catMaxi/Hallingdal65/F_1000_C0590_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0600 from '../assets/catMaxi/Hallingdal65/F_1000_C0600_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0657 from '../assets/catMaxi/Hallingdal65/F_1000_C0657_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0674 from '../assets/catMaxi/Hallingdal65/F_1000_C0674_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0680 from '../assets/catMaxi/Hallingdal65/F_1000_C0680_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0687 from '../assets/catMaxi/Hallingdal65/F_1000_C0687_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0702 from '../assets/catMaxi/Hallingdal65/F_1000_C0702_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0723 from '../assets/catMaxi/Hallingdal65/F_1000_C0723_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0733 from '../assets/catMaxi/Hallingdal65/F_1000_C0733_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0750 from '../assets/catMaxi/Hallingdal65/F_1000_C0750_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0753 from '../assets/catMaxi/Hallingdal65/F_1000_C0753_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0754 from '../assets/catMaxi/Hallingdal65/F_1000_C0754_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0763 from '../assets/catMaxi/Hallingdal65/F_1000_C0763_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0764 from '../assets/catMaxi/Hallingdal65/F_1000_C0764_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0773 from '../assets/catMaxi/Hallingdal65/F_1000_C0773_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0810 from '../assets/catMaxi/Hallingdal65/F_1000_C0810_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0840 from '../assets/catMaxi/Hallingdal65/F_1000_C0840_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0850 from '../assets/catMaxi/Hallingdal65/F_1000_C0850_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0890 from '../assets/catMaxi/Hallingdal65/F_1000_C0890_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0960 from '../assets/catMaxi/Hallingdal65/F_1000_C0960_W24_HIGH_CMYK.jpg'
import HALLINGDAL_0980 from '../assets/catMaxi/Hallingdal65/F_1000_C0980_W24_HIGH_CMYK.jpg'


import MELANGE_0913 from '../assets/catMaxi/Uniform-Melange/F_13004_C0913_W24.tif.jpg'
import MELANGE_0133 from '../assets/catMaxi/Uniform-Melange/F_13004_C0133_W24.tif.jpg'
import MELANGE_0553 from '../assets/catMaxi/Uniform-Melange/F_13004_C0553_W24.tif.jpg'
import MELANGE_0683 from '../assets/catMaxi/Uniform-Melange/F_13004_C0683_W24.tif.jpg'
import MELANGE_0993 from '../assets/catMaxi/Uniform-Melange/F_13004_C0993_W24.tif.jpg'
import MELANGE_0283 from '../assets/catMaxi/Uniform-Melange/F_13004_C0283_W24.tif.jpg'
import MELANGE_0183 from '../assets/catMaxi/Uniform-Melange/F_13004_C0183_W24.tif.jpg'
import MELANGE_0193 from '../assets/catMaxi/Uniform-Melange/F_13004_C0193_W24.tif.jpg'
import MELANGE_0153 from '../assets/catMaxi/Uniform-Melange/F_13004_C0153_W24.tif.jpg'
import MELANGE_0353 from '../assets/catMaxi/Uniform-Melange/F_13004_C0353_W24.tif.jpg'
import MELANGE_0623 from '../assets/catMaxi/Uniform-Melange/F_13004_C0623_W24.tif.jpg'
import MELANGE_0833 from '../assets/catMaxi/Uniform-Melange/F_13004_C0833_W24.tif.jpg'
import MELANGE_0883 from '../assets/catMaxi/Uniform-Melange/F_13004_C0883_W24.tif.jpg'
import MELANGE_0813 from '../assets/catMaxi/Uniform-Melange/F_13004_C0813_W24.tif.jpg'
import MELANGE_0793 from '../assets/catMaxi/Uniform-Melange/F_13004_C0793_W24.tif.jpg'
import MELANGE_0723 from '../assets/catMaxi/Uniform-Melange/F_13004_C0723_W24.tif.jpg'
import MELANGE_0963 from '../assets/catMaxi/Uniform-Melange/F_13004_C0963_W24.tif.jpg'
import MELANGE_0983 from '../assets/catMaxi/Uniform-Melange/F_13004_C0983_W24.tif.jpg'
import MELANGE_0563 from '../assets/catMaxi/Uniform-Melange/F_13004_C0563_W24.tif.jpg'
import MELANGE_0753 from '../assets/catMaxi/Uniform-Melange/F_13004_C0753_W24.tif.jpg'
import MELANGE_0103 from '../assets/catMaxi/Uniform-Melange/F_13004_C0103_W24.tif.jpg'
import MELANGE_0123 from '../assets/catMaxi/Uniform-Melange/F_13004_C0123_W24.tif.jpg'
import MELANGE_0453 from '../assets/catMaxi/Uniform-Melange/F_13004_C0453_W24.tif.jpg'
import MELANGE_0163 from '../assets/catMaxi/Uniform-Melange/F_13004_C0163_W24.tif.jpg'
import MELANGE_0463 from '../assets/catMaxi/Uniform-Melange/F_13004_C0463_W24.tif.jpg'
import MELANGE_0633 from '../assets/catMaxi/Uniform-Melange/F_13004_C0633_W24.tif.jpg'


import MOSAIC2_0182 from '../assets/catMaxi/Mosaic2/F_13019_C0182_W24.tif.jpg'
import MOSAIC2_0222 from '../assets/catMaxi/Mosaic2/F_13019_C0222_W24.tif.jpg'
import MOSAIC2_0262 from '../assets/catMaxi/Mosaic2/F_13019_C0262_W24.tif.jpg'
import MOSAIC2_0422 from '../assets/catMaxi/Mosaic2/F_13019_C0422_W24.tif.jpg'
import MOSAIC2_0472 from '../assets/catMaxi/Mosaic2/F_13019_C0472_W24.tif.jpg'
import MOSAIC2_0532 from '../assets/catMaxi/Mosaic2/F_13019_C0532_W24.tif.jpg'
import MOSAIC2_0642 from '../assets/catMaxi/Mosaic2/F_13019_C0642_W24.tif.jpg'
import MOSAIC2_0662 from '../assets/catMaxi/Mosaic2/F_13019_C0662_W24.tif.jpg'
import MOSAIC2_0672 from '../assets/catMaxi/Mosaic2/F_13019_C0672_W24.tif.jpg'
import MOSAIC2_0682 from '../assets/catMaxi/Mosaic2/F_13019_C0682_W24.tif.jpg'
import MOSAIC2_0692 from '../assets/catMaxi/Mosaic2/F_13019_C0692_W24.tif.jpg'
import MOSAIC2_0722 from '../assets/catMaxi/Mosaic2/F_13019_C0722_W24.tif.jpg'
import MOSAIC2_0772 from '../assets/catMaxi/Mosaic2/F_13019_C0772_W24.tif.jpg'
import MOSAIC2_0922 from '../assets/catMaxi/Mosaic2/F_13019_C0922_W24.tif.jpg'
import MOSAIC2_0972 from '../assets/catMaxi/Mosaic2/F_13019_C0972_W24.tif.jpg'

const catMaxi = [
  {
    category: 'cat. Maxi',
    collection: 'Mosaic 2',
    items: [
      {
        name: "MOSAIC2 0182",
        slug: "MOSAIC2 0182",
        thumbnail: MOSAIC2_0182,
      },
      {
        name: "MOSAIC2 0222",
        slug: "MOSAIC2 0222",
        thumbnail: MOSAIC2_0222,
      },
      {
        name: "MOSAIC2 0262",
        slug: "MOSAIC2 0262",
        thumbnail: MOSAIC2_0262,
      },
      {
        name: "MOSAIC2 0422",
        slug: "MOSAIC2 0422",
        thumbnail: MOSAIC2_0422,
      },
      {
        name: "MOSAIC2 0472",
        slug: "MOSAIC2 0472",
        thumbnail: MOSAIC2_0472,
      },
      {
        name: "MOSAIC2 0532",
        slug: "MOSAIC2 0532",
        thumbnail: MOSAIC2_0532,
      },
      {
        name: "MOSAIC2 0642",
        slug: "MOSAIC2 0642",
        thumbnail: MOSAIC2_0642,
      },
      {
        name: "MOSAIC2 0662",
        slug: "MOSAIC2 0662",
        thumbnail: MOSAIC2_0662,
      },
      {
        name: "MOSAIC2 0672",
        slug: "MOSAIC2 0672",
        thumbnail: MOSAIC2_0672,
      },
      {
        name: "MOSAIC2 0682",
        slug: "MOSAIC2 0682",
        thumbnail: MOSAIC2_0682,
      },
      {
        name: "MOSAIC2 0692",
        slug: "MOSAIC2 0692",
        thumbnail: MOSAIC2_0692,
      },
      {
        name: "MOSAIC2 0722",
        slug: "MOSAIC2 0722",
        thumbnail: MOSAIC2_0722,
      },
      {
        name: "MOSAIC2 0772",
        slug: "MOSAIC2 0772",
        thumbnail: MOSAIC2_0772,
      },
      {
        name: "MOSAIC2 0922",
        slug: "MOSAIC2 0922",
        thumbnail: MOSAIC2_0922,
      },
      {
        name: "MOSAIC2 0972",
        slug: "MOSAIC2 0972",
        thumbnail: MOSAIC2_0972,
      },
    ]
  },
  {
    category: 'cat. Maxi',
    collection: 'Uniform Mèlange',
    items: [
      {
        name: "UNIFORM MELANGE ASH 0133",
        slug: "UNIFORM MELANGE ASH 0133",
        thumbnail: MELANGE_0133,
      },
      {
        name: "UNIFORM MELANGE AUTUMN 0553",
        slug: "UNIFORM MELANGE AUTUMN 0553",
        thumbnail: MELANGE_0553,
      },
      {
        name: "UNIFORM MELANGE BLUEBERRY 0683",
        slug: "UNIFORM MELANGE BLUEBERRY 0683",
        thumbnail: MELANGE_0683,
      },
      {
        name: "UNIFORM MELANGE CACTUS 0993",
        slug: "UNIFORM MELANGE CACTUS 0993",
        thumbnail: MELANGE_0993,
      },
      {
        name: "UNIFORM MELANGE CAFE 0283",
        slug: "UNIFORM MELANGE CAFE 0283",
        thumbnail: MELANGE_0283,
      },
      {
        name: "UNIFORM MELANGE CHALK 0183",
        slug: "UNIFORM MELANGE CHALK 0183",
        thumbnail: MELANGE_0183,
      },
      {
        name: "UNIFORM MELANGE CHARCOAL 0193",
        slug: "UNIFORM MELANGE CHARCOAL 0193",
        thumbnail: MELANGE_0193,
      },
      {
        name: "UNIFORM MELANGE CLAY 0153",
        slug: "UNIFORM MELANGE CLAY 0153",
        thumbnail: MELANGE_0153,
      },
      {
        name: "UNIFORM MELANGE COPPER 0353",
        slug: "UNIFORM MELANGE COPPER 0353",
        thumbnail: MELANGE_0353,
      },
      {
        name: "UNIFORM MELANGE CORAL 0623",
        slug: "UNIFORM MELANGE CORAL 0623",
        thumbnail: MELANGE_0623,
      },
      {
        name: "UNIFORM MELANGE ALOE 0913",
        slug: "UNIFORM MELANGE ALOE 0913",
        thumbnail: MELANGE_0913,
      },
      {
        name: "UNIFORM MELANGE CYAN 0833",
        slug: "UNIFORM MELANGE CYAN 0833",
        thumbnail: MELANGE_0833,
      },
      {
        name: "UNIFORM MELANGE HYDRO 0883",
        slug: "UNIFORM MELANGE HYDRO 0883",
        thumbnail: MELANGE_0883,
      },
      {
        name: "UNIFORM MELANGE ICE 0813",
        slug: "UNIFORM MELANGE ICE 0813",
        thumbnail: MELANGE_0813,
      },
      {
        name: "UNIFORM MELANGE INK 0793",
        slug: "UNIFORM MELANGE INK 0793",
        thumbnail: MELANGE_0793,
      },
      {
        name: "UNIFORM MELANGE JEANS 0723",
        slug: "UNIFORM MELANGE JEANS 0723",
        thumbnail: MELANGE_0723,
      },
      {
        name: "UNIFORM MELANGE OASE 0963",
        slug: "UNIFORM MELANGE OASE 0963",
        thumbnail: MELANGE_0963,
      },
      {
        name: "UNIFORM MELANGE PEACOCK 0983",
        slug: "UNIFORM MELANGE PEACOCK 0983",
        thumbnail: MELANGE_0983,
      },
      {
        name: "UNIFORM MELANGE RUMBA 0563",
        slug: "UNIFORM MELANGE RUMBA 0563",
        thumbnail: MELANGE_0563,
      },
      {
        name: "UNIFORM MELANGE SEA 0753",
        slug: "UNIFORM MELANGE SEA 0753",
        thumbnail: MELANGE_0753,
      },
      {
        name: "UNIFORM MELANGE SHELL 0103",
        slug: "UNIFORM MELANGE SHELL 0103",
        thumbnail: MELANGE_0103,
      },
      {
        name: "UNIFORM MELANGE SMOKE 0123",
        slug: "UNIFORM MELANGE SMOKE 0123",
        thumbnail: MELANGE_0123,
      },
      {
        name: "UNIFORM MELANGE SOLAR 0453",
        slug: "UNIFORM MELANGE SOLAR 0453",
        thumbnail: MELANGE_0453,
      },
      {
        name: "UNIFORM MELANGE STONE 0163",
        slug: "UNIFORM MELANGE STONE 0163",
        thumbnail: MELANGE_0163,
      },
      {
        name: "UNIFORM MELANGE SUNSET 0463",
        slug: "UNIFORM MELANGE SUNSET 0463",
        thumbnail: MELANGE_0463,
      },
      {
        name: "UNIFORM MELANGE VIOLET 0633",
        slug: "UNIFORM MELANGE VIOLET 0633",
        thumbnail: MELANGE_0633,
      },
    ]
  },
  {
    category: 'cat. Maxi',
    collection: 'Hallingdal 65',
    items: [
      {
        slug: "HALLINGDAL 65 0100",
        name: "HALLINGDAL 65 0100",
        thumbnail: HALLINGDAL_0100,
      },
      {
        name: "HALLINGDAL 65 0103",
        slug: "HALLINGDAL 65 0103",
        thumbnail: HALLINGDAL_0103,
      },
      {
        name: "HALLINGDAL 65 0110",
        slug: "HALLINGDAL 65 0110",
        thumbnail: HALLINGDAL_0110,
      },
      {
        name: "HALLINGDAL 65 0113",
        slug: "HALLINGDAL 65 0113",
        thumbnail: HALLINGDAL_0113,
      },
      {
        name: "HALLINGDAL 65 0116",
        slug: "HALLINGDAL 65 0116",
        thumbnail: HALLINGDAL_0116,
      },
      {
        name: "HALLINGDAL 65 0123",
        slug: "HALLINGDAL 65 0123",
        thumbnail: HALLINGDAL_0123,
      },
      {
        name: "HALLINGDAL 65 0130",
        slug: "HALLINGDAL 65 0130",
        thumbnail: HALLINGDAL_0130,
      },
      {
        name: "HALLINGDAL 65 0143",
        slug: "HALLINGDAL 65 0143",
        thumbnail: HALLINGDAL_0143,
      },
      {
        name: "HALLINGDAL 65 0153",
        slug: "HALLINGDAL 65 0153",
        thumbnail: HALLINGDAL_0153,
      },
      {
        name: "HALLINGDAL 65 0166",
        slug: "HALLINGDAL 65 0166",
        thumbnail: HALLINGDAL_0166,
      },
      {
        name: "HALLINGDAL 65 0173",
        slug: "HALLINGDAL 65 0173",
        thumbnail: HALLINGDAL_0173,
      },
      {
        name: "HALLINGDAL 65 0180",
        slug: "HALLINGDAL 65 0180",
        thumbnail: HALLINGDAL_0180,
      },
      {
        name: "HALLINGDAL 65 0190",
        slug: "HALLINGDAL 65 0190",
        thumbnail: HALLINGDAL_0190,
      },
      {
        name: "HALLINGDAL 65 0200",
        slug: "HALLINGDAL 65 0200",
        thumbnail: HALLINGDAL_0200,
      },
      {
        name: "HALLINGDAL 65 0220",
        slug: "HALLINGDAL 65 0220",
        thumbnail: HALLINGDAL_0220,
      },
      {
        name: "HALLINGDAL 65 0227",
        slug: "HALLINGDAL 65 0227",
        thumbnail: HALLINGDAL_0227,
      },
      {
        name: "HALLINGDAL 65 0270",
        slug: "HALLINGDAL 65 0270",
        thumbnail: HALLINGDAL_0270,
      },
      {
        name: "HALLINGDAL 65 0368",
        slug: "HALLINGDAL 65 0368",
        thumbnail: HALLINGDAL_0368,
      },
      {
        name: "HALLINGDAL 65 0370",
        slug: "HALLINGDAL 65 0370",
        thumbnail: HALLINGDAL_0370,
      },
      {
        name: "HALLINGDAL 65 0376",
        slug: "HALLINGDAL 65 0376",
        thumbnail: HALLINGDAL_0376,
      },
      {
        name: "HALLINGDAL 65 0407",
        slug: "HALLINGDAL 65 0407",
        thumbnail: HALLINGDAL_0407,
      },
      {
        name: "HALLINGDAL 65 0420",
        slug: "HALLINGDAL 65 0420",
        thumbnail: HALLINGDAL_0420,
      },
      {
        name: "HALLINGDAL 65 0457",
        slug: "HALLINGDAL 65 0457",
        thumbnail: HALLINGDAL_0457,
      },
      {
        name: "HALLINGDAL 65 0526",
        slug: "HALLINGDAL 65 0526",
        thumbnail: HALLINGDAL_0526,
      },
      {
        name: "HALLINGDAL 65 0547",
        slug: "HALLINGDAL 65 0547",
        thumbnail: HALLINGDAL_0547,
      },
      {
        name: "HALLINGDAL 65 0563",
        slug: "HALLINGDAL 65 0563",
        thumbnail: HALLINGDAL_0563,
      },
      {
        name: "HALLINGDAL 65 0573",
        slug: "HALLINGDAL 65 0573",
        thumbnail: HALLINGDAL_0573,
      },
      {
        name: "HALLINGDAL 65 0590",
        slug: "HALLINGDAL 65 0590",
        thumbnail: HALLINGDAL_0590,
      },
      {
        name: "HALLINGDAL 65 0600",
        slug: "HALLINGDAL 65 0600",
        thumbnail: HALLINGDAL_0600,
      },
      {
        name: "HALLINGDAL 65 0657",
        slug: "HALLINGDAL 65 0657",
        thumbnail: HALLINGDAL_0657,
      },
      {
        name: "HALLINGDAL 65 0674",
        slug: "HALLINGDAL 65 0674",
        thumbnail: HALLINGDAL_0674,
      },
      {
        name: "HALLINGDAL 65 0680",
        slug: "HALLINGDAL 65 0680",
        thumbnail: HALLINGDAL_0680,
      },
      {
        name: "HALLINGDAL 65 0687",
        slug: "HALLINGDAL 65 0687",
        thumbnail: HALLINGDAL_0687,
      },
      {
        name: "HALLINGDAL 65 0702",
        slug: "HALLINGDAL 65 0702",
        thumbnail: HALLINGDAL_0702,
      },
      {
        name: "HALLINGDAL 65 0723",
        slug: "HALLINGDAL 65 0723",
        thumbnail: HALLINGDAL_0723,
      },
      {
        name: "HALLINGDAL 65 0733",
        slug: "HALLINGDAL 65 0733",
        thumbnail: HALLINGDAL_0733,
      },
      {
        name: "HALLINGDAL 65 0750",
        slug: "HALLINGDAL 65 0750",
        thumbnail: HALLINGDAL_0750,
      },
      {
        name: "HALLINGDAL 65 0753",
        slug: "HALLINGDAL 65 0753",
        thumbnail: HALLINGDAL_0753,
      },
      {
        name: "HALLINGDAL 65 0754",
        slug: "HALLINGDAL 65 0754",
        thumbnail: HALLINGDAL_0754,
      },
      {
        name: "HALLINGDAL 65 0763",
        slug: "HALLINGDAL 65 0763",
        thumbnail: HALLINGDAL_0763,
      },
      {
        name: "HALLINGDAL 65 0764",
        slug: "HALLINGDAL 65 0764",
        thumbnail: HALLINGDAL_0764,
      },
      {
        name: "HALLINGDAL 65 0773",
        slug: "HALLINGDAL 65 0773",
        thumbnail: HALLINGDAL_0773,
      },
      {
        name: "HALLINGDAL 65 0810",
        slug: "HALLINGDAL 65 0810",
        thumbnail: HALLINGDAL_0810,
      },
      {
        name: "HALLINGDAL 65 0840",
        slug: "HALLINGDAL 65 0840",
        thumbnail: HALLINGDAL_0840,
      },
      {
        name: "HALLINGDAL 65 0850",
        slug: "HALLINGDAL 65 0850",
        thumbnail: HALLINGDAL_0850,
      },
      {
        name: "HALLINGDAL 65 0890",
        slug: "HALLINGDAL 65 0890",
        thumbnail: HALLINGDAL_0890,
      },
      {
        name: "HALLINGDAL 65 0960",
        slug: "HALLINGDAL 65 0960",
        thumbnail: HALLINGDAL_0960,
      },
      {
        name: "HALLINGDAL 65 0980",
        slug: "HALLINGDAL 65 0980",
        thumbnail: HALLINGDAL_0980,
      },
    ]
  },
  {
    category: 'cat. Maxi',
    collection: 'Divina 3',
    items: [
      { slug: "DIVINA 3 0106", name: "DIVINA 3 0106", thumbnail: DIVINA_0106 },
      { slug: "DIVINA 3 0154", name: "DIVINA 3 0154", thumbnail: DIVINA_0154 },
      { slug: "DIVINA 3 0171", name: "DIVINA 3 0171", thumbnail: DIVINA_0171 },
      { slug: "DIVINA 3 0173", name: "DIVINA 3 0173", thumbnail: DIVINA_0173 },
      { slug: "DIVINA 3 0181", name: "DIVINA 3 0181", thumbnail: DIVINA_0181 },
      { slug: "DIVINA 3 0191", name: "DIVINA 3 0191", thumbnail: DIVINA_0191 },
      { slug: "DIVINA 3 0224", name: "DIVINA 3 0224", thumbnail: DIVINA_0224 },
      { slug: "DIVINA 3 0246", name: "DIVINA 3 0246", thumbnail: DIVINA_0246 },
      { slug: "DIVINA 3 0346", name: "DIVINA 3 0346", thumbnail: DIVINA_0346 },
      { slug: "DIVINA 3 0356", name: "DIVINA 3 0356", thumbnail: DIVINA_0356 },
      { slug: "DIVINA 3 0384", name: "DIVINA 3 0384", thumbnail: DIVINA_0384 },
      { slug: "DIVINA 3 0393", name: "DIVINA 3 0393", thumbnail: DIVINA_0393 },
      { slug: "DIVINA 3 0444", name: "DIVINA 3 0444", thumbnail: DIVINA_0444 },
      { slug: "DIVINA 3 0462", name: "DIVINA 3 0462", thumbnail: DIVINA_0462 },
      { slug: "DIVINA 3 0536", name: "DIVINA 3 0536", thumbnail: DIVINA_0536 },
      { slug: "DIVINA 3 0542", name: "DIVINA 3 0542", thumbnail: DIVINA_0542 },
      { slug: "DIVINA 3 0623", name: "DIVINA 3 0623", thumbnail: DIVINA_0623 },
      { slug: "DIVINA 3 0636", name: "DIVINA 3 0636", thumbnail: DIVINA_0636 },
      { slug: "DIVINA 3 0652", name: "DIVINA 3 0652", thumbnail: DIVINA_0652 },
      { slug: "DIVINA 3 0666", name: "DIVINA 3 0666", thumbnail: DIVINA_0666 },
      { slug: "DIVINA 3 0676", name: "DIVINA 3 0676", thumbnail: DIVINA_0676 },
      { slug: "DIVINA 3 0686", name: "DIVINA 3 0686", thumbnail: DIVINA_0686 },
      { slug: "DIVINA 3 0691", name: "DIVINA 3 0691", thumbnail: DIVINA_0691 },
      { slug: "DIVINA 3 0692", name: "DIVINA 3 0692", thumbnail: DIVINA_0692 },
      { slug: "DIVINA 3 0696", name: "DIVINA 3 0696", thumbnail: DIVINA_0696 },
      { slug: "DIVINA 3 0712", name: "DIVINA 3 0712", thumbnail: DIVINA_0712 },
      { slug: "DIVINA 3 0756", name: "DIVINA 3 0756", thumbnail: DIVINA_0756 },
      { slug: "DIVINA 3 0782", name: "DIVINA 3 0782", thumbnail: DIVINA_0782 },
      { slug: "DIVINA 3 0791", name: "DIVINA 3 0791", thumbnail: DIVINA_0791 },
      { slug: "DIVINA 3 0826", name: "DIVINA 3 0826", thumbnail: DIVINA_0826 },
      { slug: "DIVINA 3 0846", name: "DIVINA 3 0846", thumbnail: DIVINA_0846 },
      { slug: "DIVINA 3 0876", name: "DIVINA 3 0876", thumbnail: DIVINA_0876 },
      { slug: "DIVINA 3 0922", name: "DIVINA 3 0922", thumbnail: DIVINA_0922 },
      { slug: "DIVINA 3 0936", name: "DIVINA 3 0936", thumbnail: DIVINA_0936 },
      { slug: "DIVINA 3 0956", name: "DIVINA 3 0956", thumbnail: DIVINA_0956 },
    ]
  }
]

export default catMaxi