

import JAAK_0952 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_952.jpg'
import JAAK_0992 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_992.jpg'
import JAAK_105 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_105.jpg'
import JAAK_193 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_193.jpg'
import JAAK_34 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_34_Basecolor.jpg'
import JAAK_41 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_41.jpg'
import JAAK_512 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_512.jpg'
import JAAK_518 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_518.jpg'
import JAAK_54 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_54.jpg'
import JAAK_55 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_55.jpg'
import JAAK_608 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_608.jpg'
import JAAK_77 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_77.jpg'
import JAAK_89 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_89.jpg'
import JAAK_904 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_904.jpg'
import JAAK_905 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_905.jpg'
import JAAK_919 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_919.jpg'
import JAAK_921 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_921.jpg'
import JAAK_925 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_925.jpg'
import JAAK_926 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_926.jpg'
import JAAK_953 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_953.jpg'
import JAAK_962 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_962.jpg'
import JAAK_971 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_971.jpg'
import JAAK_976 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_976.jpg'
import JAAK_993 from '../assets/catJaak/Jaak/LCV_FABRIC_BK_JAAK_993.jpg'

const catJaak = [
  
  {
    category: 'cat. Jaak',
    collection: 'Jaak',
    items: [
    { slug: "JAAK 0952", name: "JAAK 0952", thumbnail: JAAK_0952 },
    { slug: "JAAK 0992", name: "JAAK 0992", thumbnail: JAAK_0992 },
    { slug: "JAAK 105", name: "JAAK 105", thumbnail: JAAK_105 },
    { slug: "JAAK 193", name: "JAAK 193", thumbnail: JAAK_193 },
    { slug: "JAAK 34", name: "JAAK 34", thumbnail: JAAK_34 },
    { slug: "JAAK 41", name: "JAAK 41", thumbnail: JAAK_41 },
    { slug: "JAAK 512", name: "JAAK 512", thumbnail: JAAK_512 },
    { slug: "JAAK 518", name: "JAAK 518", thumbnail: JAAK_518 },
    { slug: "JAAK 54", name: "JAAK 54", thumbnail: JAAK_54 },
    { slug: "JAAK 55", name: "JAAK 55", thumbnail: JAAK_55 },
    { slug: "JAAK 608", name: "JAAK 608", thumbnail: JAAK_608 },
    { slug: "JAAK 77", name: "JAAK 77", thumbnail: JAAK_77 },
    { slug: "JAAK 89", name: "JAAK 89", thumbnail: JAAK_89 },
    { slug: "JAAK 904", name: "JAAK 904", thumbnail: JAAK_904 },
    { slug: "JAAK 905", name: "JAAK 905", thumbnail: JAAK_905 },
    { slug: "JAAK 919", name: "JAAK 919", thumbnail: JAAK_919 },
    { slug: "JAAK 921", name: "JAAK 921", thumbnail: JAAK_921 },
    { slug: "JAAK 925", name: "JAAK 925", thumbnail: JAAK_925 },
    { slug: "JAAK 926", name: "JAAK 926", thumbnail: JAAK_926 },
    { slug: "JAAK 953", name: "JAAK 953", thumbnail: JAAK_953 },
    { slug: "JAAK 962", name: "JAAK 962", thumbnail: JAAK_962 },
    { slug: "JAAK 971", name: "JAAK 971", thumbnail: JAAK_971 },
    { slug: "JAAK 976", name: "JAAK 976", thumbnail: JAAK_976 },
    { slug: "JAAK 993", name: "JAAK 993", thumbnail: JAAK_993 },
    ]
  }
]

export default catJaak