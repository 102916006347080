// cat c

import SUPERB_5101 from '../assets/catC/Superb/5101-Superb-Trevira.jpg'
import SUPERB_5103 from '../assets/catC/Superb/5103-Superb-Trevira.jpg'
import SUPERB_5106 from '../assets/catC/Superb/5106-Superb-Trevira.jpg'
import SUPERB_5107 from '../assets/catC/Superb/5107-Superb-Trevira.jpg'
import SUPERB_5110 from '../assets/catC/Superb/5110-Superb-Trevira.jpg'
import SUPERB_5111 from '../assets/catC/Superb/5111-Superb-Trevira.jpg'
import SUPERB_5112 from '../assets/catC/Superb/5112-Superb-Trevira.jpg'
import SUPERB_5113 from '../assets/catC/Superb/5113-Superb-Trevira.jpg'
import SUPERB_5114 from '../assets/catC/Superb/5114-Superb-Trevira.jpg'
import SUPERB_5116 from '../assets/catC/Superb/5116-Superb-Trevira.jpg'
import SUPERB_5118 from '../assets/catC/Superb/5118-Superb-Trevira.jpg'
import SUPERB_5119 from '../assets/catC/Superb/5119-Superb-Trevira.jpg'
import SUPERB_5121 from '../assets/catC/Superb/5121-Superb-Trevira.jpg'
import SUPERB_5123 from '../assets/catC/Superb/5123-Superb-Trevira.jpg'
import SUPERB_5124 from '../assets/catC/Superb/5124-Superb-Trevira.jpg'
import SUPERB_5125 from '../assets/catC/Superb/5125-Superb-Trevira.jpg'
import SUPERB_5126 from '../assets/catC/Superb/5126-Superb-Trevira.jpg'
import SUPERB_5127 from '../assets/catC/Superb/5127-Superb-Trevira.jpg'
import SUPERB_5128 from '../assets/catC/Superb/5128-Superb-Trevira.jpg'
import SUPERB_5130 from '../assets/catC/Superb/5130-Superb-Trevira.jpg'
import SUPERB_5131 from '../assets/catC/Superb/5131-Superb-Trevira.jpg'
import SUPERB_5132 from '../assets/catC/Superb/5132-Superb-Trevira.jpg'
import SUPERB_5133 from '../assets/catC/Superb/5133-Superb-Trevira.jpg'
import SUPERB_5134 from '../assets/catC/Superb/5134-Superb-Trevira.jpg'
import SUPERB_5135 from '../assets/catC/Superb/5135-Superb-Trevira.jpg'
import SUPERB_5136 from '../assets/catC/Superb/5136-Superb-Trevira.jpg'
import SUPERB_5137 from '../assets/catC/Superb/5137-Superb-Trevira.jpg'
import SUPERB_5139 from '../assets/catC/Superb/5139-Superb-Trevira.jpg'
import SUPERB_5140 from '../assets/catC/Superb/5140-Superb-Trevira.jpg'
import SUPERB_5141 from '../assets/catC/Superb/5141-Superb-Trevira.jpg'
import SUPERB_5142 from '../assets/catC/Superb/5142-Superb-Trevira.jpg'
import SUPERB_5143 from '../assets/catC/Superb/5143-Superb-Trevira.jpg'
import SUPERB_5144 from '../assets/catC/Superb/5144-Superb-Trevira.jpg'
import SUPERB_5145 from '../assets/catC/Superb/5145-Superb-Trevira.jpg'
import SUPERB_5146 from '../assets/catC/Superb/5146-Superb-Trevira.jpg'
import SUPERB_5147 from '../assets/catC/Superb/5147-Superb-Trevira.jpg'
import SUPERB_5149 from '../assets/catC/Superb/5149-Superb-Trevira.jpg'
import SUPERB_5150 from '../assets/catC/Superb/5150-Superb-Trevira.jpg'
import SUPERB_5151 from '../assets/catC/Superb/5151-Superb-Trevira.jpg'
import SUPERB_5152 from '../assets/catC/Superb/5152-Superb-Trevira.jpg'

const catC = [
  {
    category: 'cat. C',
    collection: 'Superb',
    items: [
      {
        name: "SUPERB 5101",
        slug: "SUPERB 5101",
        thumbnail: SUPERB_5101,
      },
      {
        name: "SUPERB 5103",
        slug: "SUPERB 5103",
        thumbnail: SUPERB_5103,
      },
      {
        name: "SUPERB 5106",
        slug: "SUPERB 5106",
        thumbnail: SUPERB_5106,
      },
      {
        name: "SUPERB 5107",
        slug: "SUPERB 5107",
        thumbnail: SUPERB_5107,
      },
      {
        name: "SUPERB 5110",
        slug: "SUPERB 5110",
        thumbnail: SUPERB_5110,
      },
      {
        name: "SUPERB 5111",
        slug: "SUPERB 5111",
        thumbnail: SUPERB_5111,
      },
      {
        name: "SUPERB 5112",
        slug: "SUPERB 5112",
        thumbnail: SUPERB_5112,
      },
      {
        name: "SUPERB 5113",
        slug: "SUPERB 5113",
        thumbnail: SUPERB_5113,
      },
      {
        name: "SUPERB 5114",
        slug: "SUPERB 5114",
        thumbnail: SUPERB_5114,
      },
      {
        name: "SUPERB 5116",
        slug: "SUPERB 5116",
        thumbnail: SUPERB_5116,
      },
      {
        name: "SUPERB 5118",
        slug: "SUPERB 5118",
        thumbnail: SUPERB_5118,
      },
      {
        name: "SUPERB 5119",
        slug: "SUPERB 5119",
        thumbnail: SUPERB_5119,
      },
      {
        name: "SUPERB 5121",
        slug: "SUPERB 5121",
        thumbnail: SUPERB_5121,
      },
      {
        name: "SUPERB 5123",
        slug: "SUPERB 5123",
        thumbnail: SUPERB_5123,
      },
      {
        name: "SUPERB 5124",
        slug: "SUPERB 5124",
        thumbnail: SUPERB_5124,
      },
      {
        name: "SUPERB 5125",
        slug: "SUPERB 5125",
        thumbnail: SUPERB_5125,
      },
      {
        name: "SUPERB 5126",
        slug: "SUPERB 5126",
        thumbnail: SUPERB_5126,
      },
      {
        name: "SUPERB 5127",
        slug: "SUPERB 5127",
        thumbnail: SUPERB_5127,
      },
      {
        name: "SUPERB 5128",
        slug: "SUPERB 5128",
        thumbnail: SUPERB_5128,
      },
      {
        name: "SUPERB 5130",
        slug: "SUPERB 5130",
        thumbnail: SUPERB_5130,
      },
      {
        name: "SUPERB 5131",
        slug: "SUPERB 5131",
        thumbnail: SUPERB_5131,
      },
      {
        name: "SUPERB 5132",
        slug: "SUPERB 5132",
        thumbnail: SUPERB_5132,
      },
      {
        name: "SUPERB 5133",
        slug: "SUPERB 5133",
        thumbnail: SUPERB_5133,
      },
      {
        name: "SUPERB 5134",
        slug: "SUPERB 5134",
        thumbnail: SUPERB_5134,
      },
      {
        name: "SUPERB 5135",
        slug: "SUPERB 5135",
        thumbnail: SUPERB_5135,
      },
      {
        name: "SUPERB 5136",
        slug: "SUPERB 5136",
        thumbnail: SUPERB_5136,
      },
      {
        name: "SUPERB 5137",
        slug: "SUPERB 5137",
        thumbnail: SUPERB_5137,
      },
      {
        name: "SUPERB 5139",
        slug: "SUPERB 5139",
        thumbnail: SUPERB_5139,
      },
      {
        name: "SUPERB 5140",
        slug: "SUPERB 5140",
        thumbnail: SUPERB_5140,
      },
      {
        name: "SUPERB 5141",
        slug: "SUPERB 5141",
        thumbnail: SUPERB_5141,
      },
      {
        name: "SUPERB 5142",
        slug: "SUPERB 5142",
        thumbnail: SUPERB_5142,
      },
      {
        name: "SUPERB 5143",
        slug: "SUPERB 5143",
        thumbnail: SUPERB_5143,
      },
      {
        name: "SUPERB 5144",
        slug: "SUPERB 5144",
        thumbnail: SUPERB_5144,
      },
      {
        name: "SUPERB 5145",
        slug: "SUPERB 5145",
        thumbnail: SUPERB_5145,
      },
      {
        name: "SUPERB 5146",
        slug: "SUPERB 5146",
        thumbnail: SUPERB_5146,
      },
      {
        name: "SUPERB 5147",
        slug: "SUPERB 5147",
        thumbnail: SUPERB_5147,
      },
      {
        name: "SUPERB 5149",
        slug: "SUPERB 5149",
        thumbnail: SUPERB_5149,
      },
      {
        name: "SUPERB 5150",
        slug: "SUPERB 5150",
        thumbnail: SUPERB_5150,
      },
      {
        name: "SUPERB 5151",
        slug: "SUPERB 5151",
        thumbnail: SUPERB_5151,
      },
      {
        name: "SUPERB 5152",
        slug: "SUPERB 5152",
        thumbnail: SUPERB_5152,
      },
    ]
  }
]

export default catC