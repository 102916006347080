//cat A

import tile_Avon_706814 from '../assets/catA/Avon/7068.14.jpg'
import tile_Avon_706802 from '../assets/catA/Avon/7068.02.jpg'
import tile_Avon_706803 from '../assets/catA/Avon/7068.03.jpg'
import tile_Avon_706804 from '../assets/catA/Avon/7068.04.jpg'
import tile_Avon_706805 from '../assets/catA/Avon/7068.05.jpg'
import tile_Avon_706806 from '../assets/catA/Avon/7068.06.jpg'
import tile_Avon_706807 from '../assets/catA/Avon/7068.07.jpg'
import tile_Avon_706808 from '../assets/catA/Avon/7068.08.jpg'
import tile_Avon_706809 from '../assets/catA/Avon/7068.09.jpg'
import tile_Avon_706810 from '../assets/catA/Avon/7068.10.jpg'
import tile_Avon_706811 from '../assets/catA/Avon/7068.11.jpg'
import tile_Avon_706812 from '../assets/catA/Avon/7068.12.jpg'
import tile_Avon_706813 from '../assets/catA/Avon/7068.13.jpg'
import tile_Avon_706801 from '../assets/catA/Avon/7068.01.jpg'
import crazy_1001 from '../assets/catA/Crazy/1001-Crazy.jpg'
import crazy_1002 from '../assets/catA/Crazy/1002-Crazy.jpg'
import crazy_1003 from '../assets/catA/Crazy/1003-Crazy.jpg'
import crazy_1004 from '../assets/catA/Crazy/1004-Crazy.jpg'
import crazy_1005 from '../assets/catA/Crazy/1005-Crazy.jpg'
import crazy_1006 from '../assets/catA/Crazy/1006-Crazy.jpg'
import crazy_1007 from '../assets/catA/Crazy/1007-Crazy.jpg'
import crazy_1009 from '../assets/catA/Crazy/1009-Crazy.jpg'
import crazy_1010 from '../assets/catA/Crazy/1010-Crazy.jpg'
import crazy_1011 from '../assets/catA/Crazy/1011-Crazy.jpg'
import crazy_1012 from '../assets/catA/Crazy/1012-Crazy.jpg'
import crazy_1014 from '../assets/catA/Crazy/1014-Crazy.jpg'
import crazy_1015 from '../assets/catA/Crazy/1015-Crazy.jpg'
import magnum_001 from '../assets/catA/Magnum/Magnum_001.png'
import magnum_005 from '../assets/catA/Magnum/Magnum_005.png'
import magnum_006 from '../assets/catA/Magnum/Magnum_006.png'
import magnum_100 from '../assets/catA/Magnum/Magnum_100.png'
import magnum_101 from '../assets/catA/Magnum/Magnum_101.png'
import magnum_102 from '../assets/catA/Magnum/Magnum_102.png'
import magnum_105 from '../assets/catA/Magnum/Magnum_105.png'
import magnum_111 from '../assets/catA/Magnum/Magnum_111.png'
import magnum_300 from '../assets/catA/Magnum/Magnum_300.png'
import magnum_302 from '../assets/catA/Magnum/Magnum_302.png'
import magnum_304 from '../assets/catA/Magnum/Magnum_304.png'
import magnum_307 from '../assets/catA/Magnum/Magnum_307.png'
import magnum_310 from '../assets/catA/Magnum/Magnum_310.png'
import magnum_400 from '../assets/catA/Magnum/Magnum_400.png'
import magnum_401 from '../assets/catA/Magnum/Magnum_401.png'
import magnum_402 from '../assets/catA/Magnum/Magnum_402.png'
import magnum_403 from '../assets/catA/Magnum/Magnum_403.png'
import magnum_404 from '../assets/catA/Magnum/Magnum_404.png'
import magnum_405 from '../assets/catA/Magnum/Magnum_405.png'
import magnum_406 from '../assets/catA/Magnum/Magnum_406.png'
import magnum_407 from '../assets/catA/Magnum/Magnum_407.png'
import magnum_500 from '../assets/catA/Magnum/Magnum_500.png'
import magnum_501 from '../assets/catA/Magnum/Magnum_501.png'
import magnum_503 from '../assets/catA/Magnum/Magnum_503.png'
import magnum_508 from '../assets/catA/Magnum/Magnum_508.png'
import magnum_600 from '../assets/catA/Magnum/Magnum_600.png'
import magnum_601 from '../assets/catA/Magnum/Magnum_601.png'
import magnum_602 from '../assets/catA/Magnum/Magnum_602.png'
import magnum_603 from '../assets/catA/Magnum/Magnum_603.png'
import magnum_604 from '../assets/catA/Magnum/Magnum_604.png'
import magnum_606 from '../assets/catA/Magnum/Magnum_606.png'
import magnum_607 from '../assets/catA/Magnum/Magnum_607.png'
import magnum_701 from '../assets/catA/Magnum/Magnum_701.png'
import magnum_703 from '../assets/catA/Magnum/Magnum_703.png'
import magnum_707 from '../assets/catA/Magnum/Magnum_707.png'
import magnum_708 from '../assets/catA/Magnum/Magnum_708.png'
import magnum_802 from '../assets/catA/Magnum/Magnum_802.png'
import magnum_803 from '../assets/catA/Magnum/Magnum_803.png'
import magnum_804 from '../assets/catA/Magnum/Magnum_804.png'
import magnum_805 from '../assets/catA/Magnum/Magnum_805.png'
import magnum_806 from '../assets/catA/Magnum/Magnum_806.png'
import magnum_808 from '../assets/catA/Magnum/Magnum_808.png'
import magnum_810 from '../assets/catA/Magnum/Magnum_810.png'
import tile_Samar_705201 from '../assets/catA/Samar/7052.01.jpg'
import tile_Samar_705202 from '../assets/catA/Samar/7052.02.jpg'
import tile_Samar_705204 from '../assets/catA/Samar/7052.04.jpg'
import tile_Samar_705205 from '../assets/catA/Samar/7052.05.jpg'
import tile_Samar_705207 from '../assets/catA/Samar/7052.07.jpg'
import tile_Samar_705208 from '../assets/catA/Samar/7052.08.jpg'
import tile_Samar_705209 from '../assets/catA/Samar/7052.09.jpg'
import tile_Samar_705210 from '../assets/catA/Samar/7052.10.jpg'
import tile_Samar_705211 from '../assets/catA/Samar/7052.11.jpg'
import tile_Samar_705212 from '../assets/catA/Samar/7052.12.jpg'
import tile_Samar_705213 from '../assets/catA/Samar/7052.13.jpg'
import tile_Samar_705214 from '../assets/catA/Samar/7052.14.jpg'
import tile_Samar_705216 from '../assets/catA/Samar/7052.16.jpg'
import tile_Samar_705217 from '../assets/catA/Samar/7052.17.jpg'
import tile_Samar_705218 from '../assets/catA/Samar/7052.18.jpg'
import tile_Samar_705219 from '../assets/catA/Samar/7052.19.jpg'
import tile_Samar_705221 from '../assets/catA/Samar/7052.21.jpg'
import tile_Samar_705222 from '../assets/catA/Samar/7052.22.jpg'
import tile_Samar_705223 from '../assets/catA/Samar/7052.23.jpg'
import tile_Samar_705224 from '../assets/catA/Samar/7052.24.jpg'
import tile_Samar_705225 from '../assets/catA/Samar/7052.25.jpg'
import tile_Samar_705226 from '../assets/catA/Samar/7052.26.jpg'
import tile_Samar_705227 from '../assets/catA/Samar/7052.27.jpg'
import tile_Samar_705229 from '../assets/catA/Samar/7052.29.jpg'
import tile_Samar_705230 from '../assets/catA/Samar/7052.30.jpg'
import tile_Samar_705231 from '../assets/catA/Samar/7052.31.jpg'
import tile_Samar_705233 from '../assets/catA/Samar/7052.33.jpg'
import tile_Samar_705234 from '../assets/catA/Samar/7052.34.jpg'
import tile_Samar_705235 from '../assets/catA/Samar/7052.35.jpg'
import tile_Samar_705236 from '../assets/catA/Samar/7052.36.jpg'
import tile_Samar_705237 from '../assets/catA/Samar/7052.37.jpg'
import tile_Samar_705238 from '../assets/catA/Samar/7052.38.jpg'
import tile_Samar_705239 from '../assets/catA/Samar/7052.39.jpg'
import tile_Samar_705240 from '../assets/catA/Samar/7052.40.jpg'
import tile_Samar_705241 from '../assets/catA/Samar/7052.41.jpg'
import tile_Samar_705242 from '../assets/catA/Samar/7052.42.jpg'
import tile_Samar_705243 from '../assets/catA/Samar/7052.43.jpg'
import tile_Samar_705244 from '../assets/catA/Samar/7052.44.jpg'
import tile_Samar_705246 from '../assets/catA/Samar/7052.46.jpg'
import tile_Samar_705247 from '../assets/catA/Samar/7052.47.jpg'
import tile_Samar_705248 from '../assets/catA/Samar/7052.48.jpg'
import tile_Samar_705249 from '../assets/catA/Samar/7052.49.jpg'
import tile_Samar_705250 from '../assets/catA/Samar/7052.50.jpg'
import tile_Samar_705251 from '../assets/catA/Samar/7052.51.jpg'
import tile_4050_TAILOR from '../assets/catA/Tailor/4050_Tailor.jpg'
import tile_250_TAILOR from '../assets/catA/Tailor/250_Tailor.jpg'
import tile_350_TAILOR from '../assets/catA/Tailor/350_Tailor.jpg'
import tile_450_TAILOR from '../assets/catA/Tailor/450_Tailor.jpg'
import tile_550_TAILOR from '../assets/catA/Tailor/550_Tailor.jpg'
import tile_650_TAILOR from '../assets/catA/Tailor/650_Tailor.jpg'
import tile_750_TAILOR from '../assets/catA/Tailor/750_Tailor.jpg'
import tile_850_TAILOR from '../assets/catA/Tailor/850_Tailor.jpg'
import tile_950_TAILOR from '../assets/catA/Tailor/950_Tailor.jpg'
import tile_1050_TAILOR from '../assets/catA/Tailor/1050_Tailor.jpg'
import tile_1150_TAILOR from '../assets/catA/Tailor/1150_Tailor.jpg'
import tile_1250_TAILOR from '../assets/catA/Tailor/1250_Tailor.jpg'
import tile_1350_TAILOR from '../assets/catA/Tailor/1350_Tailor.jpg'
import tile_1450_TAILOR from '../assets/catA/Tailor/1450_Tailor.jpg'
import tile_1550_TAILOR from '../assets/catA/Tailor/1550_Tailor.jpg'
import tile_1650_TAILOR from '../assets/catA/Tailor/1650_Tailor.jpg'
import tile_1750_TAILOR from '../assets/catA/Tailor/1750_Tailor.jpg'
import tile_1850_TAILOR from '../assets/catA/Tailor/1850_Tailor.jpg'
import tile_1950_TAILOR from '../assets/catA/Tailor/1950_Tailor.jpg'
import tile_2050_TAILOR from '../assets/catA/Tailor/2050_Tailor.jpg'
import tile_2150_TAILOR from '../assets/catA/Tailor/2150_Tailor.jpg'
import tile_2250_TAILOR from '../assets/catA/Tailor/2250_Tailor.jpg'
import tile_2350_TAILOR from '../assets/catA/Tailor/2350_Tailor.jpg'
import tile_2450_TAILOR from '../assets/catA/Tailor/2450_Tailor.jpg'
import tile_2550_TAILOR from '../assets/catA/Tailor/2550_Tailor.jpg'
import tile_2650_TAILOR from '../assets/catA/Tailor/2650_Tailor.jpg'
import tile_2750_TAILOR from '../assets/catA/Tailor/2750_Tailor.jpg'
import tile_2850_TAILOR from '../assets/catA/Tailor/2850_Tailor.jpg'
import tile_2950_TAILOR from '../assets/catA/Tailor/2950_Tailor.jpg'
import tile_3050_TAILOR from '../assets/catA/Tailor/3050_Tailor.jpg'
import tile_3150_TAILOR from '../assets/catA/Tailor/3150_Tailor.jpg'
import tile_3250_TAILOR from '../assets/catA/Tailor/3250_Tailor.jpg'
import tile_3350_TAILOR from '../assets/catA/Tailor/3350_Tailor.jpg'
import tile_3450_TAILOR from '../assets/catA/Tailor/3450_Tailor.jpg'
import tile_3550_TAILOR from '../assets/catA/Tailor/3550_Tailor.jpg'
import tile_3650_TAILOR from '../assets/catA/Tailor/3650_Tailor.jpg'
import tile_3750_TAILOR from '../assets/catA/Tailor/3750_Tailor.jpg'
import tile_3850_TAILOR from '../assets/catA/Tailor/3850_Tailor.jpg'
import tile_3950_TAILOR from '../assets/catA/Tailor/3950_Tailor.jpg'
import tile_150_TAILOR from '../assets/catA/Tailor/150_Tailor.jpg'
import YS004_TILE from '../assets/catA/Xtreme/YS004_TILE.jpg'
import YS005_TILE from '../assets/catA/Xtreme/YS005_TILE.jpg'
import YS009_TILE from '../assets/catA/Xtreme/YS009_TILE.jpg'
import YS024_TILE from '../assets/catA/Xtreme/YS024_TILE.jpg'
import YS026_TILE from '../assets/catA/Xtreme/YS026_TILE.jpg'
import YS030_TILE from '../assets/catA/Xtreme/YS030_TILE.jpg'
import YS035_TILE from '../assets/catA/Xtreme/YS035_TILE.jpg'
import YS045_TILE from '../assets/catA/Xtreme/YS045_TILE.jpg'
import YS046_TILE from '../assets/catA/Xtreme/YS046_TILE.jpg'
import YS047_TILE from '../assets/catA/Xtreme/YS047_TILE.jpg'
import YS071_TILE from '../assets/catA/Xtreme/YS071_TILE.jpg'
import YS072_TILE from '../assets/catA/Xtreme/YS072_TILE.jpg'
import YS073_TILE from '../assets/catA/Xtreme/YS073_TILE.jpg'
import YS074_TILE from '../assets/catA/Xtreme/YS074_TILE.jpg'
import YS076_TILE from '../assets/catA/Xtreme/YS076_TILE.jpg'
import YS077_TILE from '../assets/catA/Xtreme/YS077_TILE.jpg'
import YS079_TILE from '../assets/catA/Xtreme/YS079_TILE.jpg'
import YS081_TILE from '../assets/catA/Xtreme/YS081_TILE.jpg'
import YS082_TILE from '../assets/catA/Xtreme/YS082_TILE.jpg'
import YS084_TILE from '../assets/catA/Xtreme/YS084_TILE.jpg'
import YS091_TILE from '../assets/catA/Xtreme/YS091_TILE.jpg'
import YS094_TILE from '../assets/catA/Xtreme/YS094_TILE.jpg'
import YS095_TILE from '../assets/catA/Xtreme/YS095_TILE.jpg'
import YS096_TILE from '../assets/catA/Xtreme/YS096_TILE.jpg'
import YS097_TILE from '../assets/catA/Xtreme/YS097_TILE.jpg'
import YS100_TILE from '../assets/catA/Xtreme/YS100_TILE.jpg'
import YS101_TILE from '../assets/catA/Xtreme/YS101_TILE.jpg'
import YS102_TILE from '../assets/catA/Xtreme/YS102_TILE.jpg'
import YS105_TILE from '../assets/catA/Xtreme/YS105_TILE.jpg'
import YS108_TILE from '../assets/catA/Xtreme/YS108_TILE.jpg'
import YS136_TILE from '../assets/catA/Xtreme/YS136_TILE.jpg'
import YS141_TILE from '../assets/catA/Xtreme/YS141_TILE.jpg'
import YS145_TILE from '../assets/catA/Xtreme/YS145_TILE.jpg'
import YS156_TILE from '../assets/catA/Xtreme/YS156_TILE.jpg'
import YS159_TILE from '../assets/catA/Xtreme/YS159_TILE.jpg'
import YS160_TILE from '../assets/catA/Xtreme/YS160_TILE.jpg'
import YS165_TILE from '../assets/catA/Xtreme/YS165_TILE.jpg'
import YS168_TILE from '../assets/catA/Xtreme/YS168_TILE.jpg'
import YS171_TILE from '../assets/catA/Xtreme/YS171_TILE.jpg'
import YS172_TILE from '../assets/catA/Xtreme/YS172_TILE.jpg'
import YS173_TILE from '../assets/catA/Xtreme/YS173_TILE.jpg'
import angel901 from '../assets/catA/Angel/ANGEL-901.png'
import angel001 from '../assets/catA/Angel/ANGEL-001.png'
import angel101 from '../assets/catA/Angel/ANGEL-101.png'
import angel102 from '../assets/catA/Angel/ANGEL-102.png'
import angel111 from '../assets/catA/Angel/ANGEL-111.png'
import angel200 from '../assets/catA/Angel/ANGEL-200.png'
import angel201 from '../assets/catA/Angel/ANGEL-201.png'
import angel203 from '../assets/catA/Angel/ANGEL-203.png'
import angel300 from '../assets/catA/Angel/ANGEL-300.png'
import angel301 from '../assets/catA/Angel/ANGEL-301.png'
import angel302 from '../assets/catA/Angel/ANGEL-302.png'
import angel303 from '../assets/catA/Angel/ANGEL-303.png'
import angel304 from '../assets/catA/Angel/ANGEL-304.png'
import angel305 from '../assets/catA/Angel/ANGEL-305.png'
import angel306 from '../assets/catA/Angel/ANGEL-306.png'
import angel307 from '../assets/catA/Angel/ANGEL-307.png'
import angel308 from '../assets/catA/Angel/ANGEL-308.png'
import angel309 from '../assets/catA/Angel/ANGEL-309.png'
import angel310 from '../assets/catA/Angel/ANGEL-310.png'
import angel311 from '../assets/catA/Angel/ANGEL-311.png'
import angel400 from '../assets/catA/Angel/ANGEL-400.png'
import angel401 from '../assets/catA/Angel/ANGEL-401.png'
import angel402 from '../assets/catA/Angel/ANGEL-402.png'
import angel403 from '../assets/catA/Angel/ANGEL-403.png'
import angel404 from '../assets/catA/Angel/ANGEL-404.png'
import angel405 from '../assets/catA/Angel/ANGEL-405.png'
import angel406 from '../assets/catA/Angel/ANGEL-406.png'
import angel407 from '../assets/catA/Angel/ANGEL-407.png'
import angel408 from '../assets/catA/Angel/ANGEL-408.png'
import angel500 from '../assets/catA/Angel/ANGEL-500.png'
import angel501 from '../assets/catA/Angel/ANGEL-501.png'
import angel503 from '../assets/catA/Angel/ANGEL-503.png'
import angel600 from '../assets/catA/Angel/ANGEL-600.png'
import angel601 from '../assets/catA/Angel/ANGEL-601.png'
import angel700 from '../assets/catA/Angel/ANGEL-700.png'
import angel701 from '../assets/catA/Angel/ANGEL-701.png'
import angel702 from '../assets/catA/Angel/ANGEL-702.png'
import angel703 from '../assets/catA/Angel/ANGEL-703.png'
import angel705 from '../assets/catA/Angel/ANGEL-705.png'
import angel706 from '../assets/catA/Angel/ANGEL-706.png'
import angel707 from '../assets/catA/Angel/ANGEL-707.png'
import angel800 from '../assets/catA/Angel/ANGEL-800.png'
import angel801 from '../assets/catA/Angel/ANGEL-801.png'
import angel802 from '../assets/catA/Angel/ANGEL-802.png'
import angel803 from '../assets/catA/Angel/ANGEL-803.png'
import angel900 from '../assets/catA/Angel/ANGEL-900.png'

const catA = [
{
    category: 'cat. A',
    collection: 'Avon',
    items: [
      { name: "AVON 7068.01", slug: "AVON 7068.01", thumbnail: tile_Avon_706801, 
      },
      { name: "AVON 7068.02", slug: "AVON 7068.02", thumbnail: tile_Avon_706802,
      },
      { name: "AVON 7068.04", slug: "AVON 7068.04", thumbnail: tile_Avon_706804,
      },
      { name: "AVON 7068.05", slug: "AVON 7068.05", thumbnail: tile_Avon_706805,
      },
      { name: "AVON 7068.06", slug: "AVON 7068.06", thumbnail: tile_Avon_706806,
      },
      { name: "AVON 7068.07", slug: "AVON 7068.07", thumbnail: tile_Avon_706807,
      },
      { name: "AVON 7068.08", slug: "AVON 7068.08", thumbnail: tile_Avon_706808,
      },
      { name: "AVON 7068.09", slug: "AVON 7068.09", thumbnail: tile_Avon_706809,
      },
      { name: "AVON 7068.10", slug: "AVON 7068.10", thumbnail: tile_Avon_706810,
      },
      { name: "AVON 7068.11", slug: "AVON 7068.11", thumbnail: tile_Avon_706811,
      },
      { name: "AVON 7068.12", slug: "AVON 7068.12", thumbnail: tile_Avon_706812,
      },
      { name: "AVON 7068.13", slug: "AVON 7068.13", thumbnail: tile_Avon_706813,
      },
      { name: "AVON 7068.14", slug: "AVON 7068.14", thumbnail: tile_Avon_706814,
      }
    ]
  },
  {
    category: 'cat. A',
    collection: 'Crazy',
    items: [
      { name: "CRAZY 1001", slug: "CRAZY 1001", thumbnail: crazy_1001,
      },
      {   name: "CRAZY 1002",   slug: "CRAZY 1002",   thumbnail: crazy_1002,
      },
      {   name: "CRAZY 1003",   slug: "CRAZY 1003",   thumbnail: crazy_1003,
      },
      {   name: "CRAZY 1004",   slug: "CRAZY 1004",   thumbnail: crazy_1004,
      },
      {   name: "CRAZY 1005",   slug: "CRAZY 1005",   thumbnail: crazy_1005,
      },
      {   name: "CRAZY 1006",   slug: "CRAZY 1006",   thumbnail: crazy_1006,
      },
      {   name: "CRAZY 1007",   slug: "CRAZY 1007",   thumbnail: crazy_1007,
      },
      {   name: "CRAZY 1009",   slug: "CRAZY 1009",   thumbnail: crazy_1009,
      },
      {   name: "CRAZY 1010",   slug: "CRAZY 1010",   thumbnail: crazy_1010,
      },
      {   name: "CRAZY 1011",   slug: "CRAZY 1011",   thumbnail: crazy_1011,
      },
      {   name: "CRAZY 1012",   slug: "CRAZY 1012",   thumbnail: crazy_1012,
      },
      {   name: "CRAZY 1014",   slug: "CRAZY 1014",   thumbnail: crazy_1014,
      },
      {   name: "CRAZY 1015",   slug: "CRAZY 1015",   thumbnail: crazy_1015,
      }
    ]
  },
  {
    category: 'cat. A',
    collection: 'Magnum',
    items: [
      { name: "MAGNUM 001", slug: "MAGNUM 001", thumbnail: magnum_001,
      },
      { name: "MAGNUM 005", slug: "MAGNUM 005", thumbnail: magnum_005,
      },
      { name: "MAGNUM 006", slug: "MAGNUM 006", thumbnail: magnum_006,
      },
      { name: "MAGNUM 100", slug: "MAGNUM 100", thumbnail: magnum_100,
      },
      { name: "MAGNUM 101", slug: "MAGNUM 101", thumbnail: magnum_101,
      },
      { name: "MAGNUM 102", slug: "MAGNUM 102", thumbnail: magnum_102,
      },
      { name: "MAGNUM 105", slug: "MAGNUM 105", thumbnail: magnum_105,
      },
      { name: "MAGNUM 111", slug: "MAGNUM 111", thumbnail: magnum_111,
      },
      { name: "MAGNUM 300", slug: "MAGNUM 300", thumbnail: magnum_300,
      },
      { name: "MAGNUM 302", slug: "MAGNUM 302", thumbnail: magnum_302,
      },
      { name: "MAGNUM 304", slug: "MAGNUM 304", thumbnail: magnum_304,
      },
      { name: "MAGNUM 307", slug: "MAGNUM 307", thumbnail: magnum_307,
      },
      { name: "MAGNUM 310", slug: "MAGNUM 310", thumbnail: magnum_310,
      },
      { name: "MAGNUM 400", slug: "MAGNUM 400", thumbnail: magnum_400,
      },
      { name: "MAGNUM 401", slug: "MAGNUM 401", thumbnail: magnum_401,
      },
      { name: "MAGNUM 402", slug: "MAGNUM 402", thumbnail: magnum_402,
      },
      { name: "MAGNUM 403", slug: "MAGNUM 403", thumbnail: magnum_403,
      },
      { name: "MAGNUM 404", slug: "MAGNUM 404", thumbnail: magnum_404,
      },
      { name: "MAGNUM 405", slug: "MAGNUM 405", thumbnail: magnum_405,
      },
      { name: "MAGNUM 406", slug: "MAGNUM 406", thumbnail: magnum_406,
      },
      { name: "MAGNUM 407", slug: "MAGNUM 407", thumbnail: magnum_407,
      },
      { name: "MAGNUM 500", slug: "MAGNUM 500", thumbnail: magnum_500,
      },
      { name: "MAGNUM 501", slug: "MAGNUM 501", thumbnail: magnum_501,
      },
      { name: "MAGNUM 503", slug: "MAGNUM 503", thumbnail: magnum_503,
      },
      { name: "MAGNUM 508", slug: "MAGNUM 508", thumbnail: magnum_508,
      },
      { name: "MAGNUM 600", slug: "MAGNUM 600", thumbnail: magnum_600,
      },
      { name: "MAGNUM 601", slug: "MAGNUM 601", thumbnail: magnum_601,
      },
      { name: "MAGNUM 602", slug: "MAGNUM 602", thumbnail: magnum_602,
      },
      { name: "MAGNUM 603", slug: "MAGNUM 603", thumbnail: magnum_603,
      },
      { name: "MAGNUM 604", slug: "MAGNUM 604", thumbnail: magnum_604,
      },
      { name: "MAGNUM 606", slug: "MAGNUM 606", thumbnail: magnum_606,
      },
      { name: "MAGNUM 607", slug: "MAGNUM 607", thumbnail: magnum_607,
      },
      { name: "MAGNUM 701", slug: "MAGNUM 701", thumbnail: magnum_701,
      },
      { name: "MAGNUM 703", slug: "MAGNUM 703", thumbnail: magnum_703,
      },
      { name: "MAGNUM 707", slug: "MAGNUM 707", thumbnail: magnum_707,
      },
      { name: "MAGNUM 708", slug: "MAGNUM 708", thumbnail: magnum_708,
      },
      { name: "MAGNUM 802", slug: "MAGNUM 802", thumbnail: magnum_802,
      },
      { name: "MAGNUM 803", slug: "MAGNUM 803", thumbnail: magnum_803,
      },
      { name: "MAGNUM 804", slug: "MAGNUM 804", thumbnail: magnum_804,
      },
      { name: "MAGNUM 805", slug: "MAGNUM 805", thumbnail: magnum_805,
      },
      { name: "MAGNUM 806", slug: "MAGNUM 806", thumbnail: magnum_806,
      },
      { name: "MAGNUM 808", slug: "MAGNUM 808", thumbnail: magnum_808,
      },
      { name: "MAGNUM 810", slug: "MAGNUM 810", thumbnail: magnum_810,
      },
    ]
  },
  {
    category: 'cat. A',
    collection: 'Samar',
    items: [
      {   name: "SAMAR 7052.01",   slug: "SAMAR 7052.01",   thumbnail: tile_Samar_705201,
      },
      {   name: "SAMAR 7052.02",   slug: "SAMAR 7052.02",   thumbnail: tile_Samar_705202,
      },
      {   name: "SAMAR 7052.04",   slug: "SAMAR 7052.04",   thumbnail: tile_Samar_705204,
      },
      {   name: "SAMAR 7052.05",   slug: "SAMAR 7052.05",   thumbnail: tile_Samar_705205,
      },
      {   name: "SAMAR 7052.07",   slug: "SAMAR 7052.07",   thumbnail: tile_Samar_705207,
      },
      {   name: "SAMAR 7052.08",   slug: "SAMAR 7052.08",   thumbnail: tile_Samar_705208,
      },
      {   name: "SAMAR 7052.09",   slug: "SAMAR 7052.09",   thumbnail: tile_Samar_705209,
      },
      {   name: "SAMAR 7052.10",   slug: "SAMAR 7052.10",   thumbnail: tile_Samar_705210,
      },
      {   name: "SAMAR 7052.11",   slug: "SAMAR 7052.11",   thumbnail: tile_Samar_705211,
      },
      {   name: "SAMAR 7052.12",   slug: "SAMAR 7052.12",   thumbnail: tile_Samar_705212,
      },
      {   name: "SAMAR 7052.13",   slug: "SAMAR 7052.13",   thumbnail: tile_Samar_705213,
      },
      {   name: "SAMAR 7052.14",   slug: "SAMAR 7052.14",   thumbnail: tile_Samar_705214,
      },
      {   name: "SAMAR 7052.16",   slug: "SAMAR 7052.16",   thumbnail: tile_Samar_705216,
      },
      {   name: "SAMAR 7052.17",   slug: "SAMAR 7052.17",   thumbnail: tile_Samar_705217,
      },
      {   name: "SAMAR 7052.18",   slug: "SAMAR 7052.18",   thumbnail: tile_Samar_705218,
      },
      {   name: "SAMAR 7052.19",   slug: "SAMAR 7052.19",   thumbnail: tile_Samar_705219,
      },
      {   name: "SAMAR 7052.21",   slug: "SAMAR 7052.21",   thumbnail: tile_Samar_705221,
      },
      {   name: "SAMAR 7052.22",   slug: "SAMAR 7052.22",   thumbnail: tile_Samar_705222,
      },
      {   name: "SAMAR 7052.23",   slug: "SAMAR 7052.23",   thumbnail: tile_Samar_705223,
      },// samar 24 texture not online?
      {   name: "SAMAR 7052.24",   slug: "SAMAR 7052.24",   thumbnail: tile_Samar_705224,
      },
      {   name: "SAMAR 7052.25",   slug: "SAMAR 7052.25",   thumbnail: tile_Samar_705225,
      },
      {   name: "SAMAR 7052.26",   slug: "SAMAR 7052.26",   thumbnail: tile_Samar_705226,
      },
      {   name: "SAMAR 7052.27",   slug: "SAMAR 7052.27",   thumbnail: tile_Samar_705227,
      },
      {   name: "SAMAR 7052.29",   slug: "SAMAR 7052.29",   thumbnail: tile_Samar_705229,
      },
      {   name: "SAMAR 7052.30",   slug: "SAMAR 7052.30",   thumbnail: tile_Samar_705230,
      },
      {   name: "SAMAR 7052.31",   slug: "SAMAR 7052.31",   thumbnail: tile_Samar_705231,
      },
      {   name: "SAMAR 7052.33",   slug: "SAMAR 7052.33",   thumbnail: tile_Samar_705233,
      },
      {   name: "SAMAR 7052.34",   slug: "SAMAR 7052.34",   thumbnail: tile_Samar_705234,
      },
      {   name: "SAMAR 7052.35",   slug: "SAMAR 7052.35",   thumbnail: tile_Samar_705235,
      },
      {   name: "SAMAR 7052.36",   slug: "SAMAR 7052.36",   thumbnail: tile_Samar_705236,
      },
      {   name: "SAMAR 7052.37",   slug: "SAMAR 7052.37",   thumbnail: tile_Samar_705237,
      },
      {   name: "SAMAR 7052.38",   slug: "SAMAR 7052.38",   thumbnail: tile_Samar_705238,
      },
      {   name: "SAMAR 7052.39",   slug: "SAMAR 7052.39",   thumbnail: tile_Samar_705239,
      },
      {   name: "SAMAR 7052.40",   slug: "SAMAR 7052.40",   thumbnail: tile_Samar_705240,
      },
      {   name: "SAMAR 7052.41",   slug: "SAMAR 7052.41",   thumbnail: tile_Samar_705241,
      },
      {   name: "SAMAR 7052.42",   slug: "SAMAR 7052.42",   thumbnail: tile_Samar_705242,
      },
      {   name: "SAMAR 7052.43",   slug: "SAMAR 7052.43",   thumbnail: tile_Samar_705243,
      },
      {   name: "SAMAR 7052.44",   slug: "SAMAR 7052.44",   thumbnail: tile_Samar_705244,
      },
      {   name: "SAMAR 7052.46",   slug: "SAMAR 7052.46",   thumbnail: tile_Samar_705246,
      },
      {   name: "SAMAR 7052.47",   slug: "SAMAR 7052.47",   thumbnail: tile_Samar_705247,
      },
      {   name: "SAMAR 7052.48",   slug: "SAMAR 7052.48",   thumbnail: tile_Samar_705248,
      },
      {   name: "SAMAR 7052.49",   slug: "SAMAR 7052.49",   thumbnail: tile_Samar_705249,
      },
      {   name: "SAMAR 7052.50",   slug: "SAMAR 7052.50",   thumbnail: tile_Samar_705250,
      },
      {   name: "SAMAR 7052.51",   slug: "SAMAR 7052.51",   thumbnail: tile_Samar_705251,
      },
    ]
  },
  {
    category: 'cat. A',
    collection: 'Tailor',
    items: [
      {   name: "TAILOR 1050",   slug: "TAILOR 1050",   thumbnail: tile_1050_TAILOR,
      },
      {   name: "TAILOR 1150",   slug: "TAILOR 1150",   thumbnail: tile_1150_TAILOR,
      },
      {   name: "TAILOR 1250",   slug: "TAILOR 1250",   thumbnail: tile_1250_TAILOR,
      },
      {   name: "TAILOR 1350",   slug: "TAILOR 1350",   thumbnail: tile_1350_TAILOR,
      },
      {   name: "TAILOR 1450",   slug: "TAILOR 1450",   thumbnail: tile_1450_TAILOR,
      },
      {   name: "TAILOR 150",   slug: "TAILOR 150",   thumbnail: tile_150_TAILOR,
      },
      {   name: "TAILOR 1550",   slug: "TAILOR 1550",   thumbnail: tile_1550_TAILOR,
      },
      {   name: "TAILOR 1650",   slug: "TAILOR 1650",   thumbnail: tile_1650_TAILOR,
      },
      {   name: "TAILOR 1750",   slug: "TAILOR 1750",   thumbnail: tile_1750_TAILOR,
      },
      {   name: "TAILOR 1850",   slug: "TAILOR 1850",   thumbnail: tile_1850_TAILOR,
      },
      {   name: "TAILOR 1950",   slug: "TAILOR 1950",   thumbnail: tile_1950_TAILOR,
      },
      {   name: "TAILOR 2050",   slug: "TAILOR 2050",   thumbnail: tile_2050_TAILOR,
      },
      {   name: "TAILOR 2150",   slug: "TAILOR 2150",   thumbnail: tile_2150_TAILOR,
      },
      {   name: "TAILOR 2250",   slug: "TAILOR 2250",   thumbnail: tile_2250_TAILOR,
      },
      {   name: "TAILOR 2350",   slug: "TAILOR 2350",   thumbnail: tile_2350_TAILOR,
      },
      {   name: "TAILOR 2450",   slug: "TAILOR 2450",   thumbnail: tile_2450_TAILOR,
      },
      {   name: "TAILOR 250",   slug: "TAILOR 250",   thumbnail: tile_250_TAILOR,
      },
      {   name: "TAILOR 2550",   slug: "TAILOR 2550",   thumbnail: tile_2550_TAILOR,
      },
      {   name: "TAILOR 2650",   slug: "TAILOR 2650",   thumbnail: tile_2650_TAILOR,
      },
      {   name: "TAILOR 2750",   slug: "TAILOR 2750",   thumbnail: tile_2750_TAILOR,
      },
      {   name: "TAILOR 2950",   slug: "TAILOR 2950",   thumbnail: tile_2950_TAILOR,
      },
      {   name: "TAILOR 3050",   slug: "TAILOR 3050",   thumbnail: tile_3050_TAILOR,
      },
      {   name: "TAILOR 3150",   slug: "TAILOR 3150",   thumbnail: tile_3150_TAILOR,
      },
      {   name: "TAILOR 3250",   slug: "TAILOR 3250",   thumbnail: tile_3250_TAILOR,
      },
      {   name: "TAILOR 3350",   slug: "TAILOR 3350",   thumbnail: tile_3350_TAILOR,
      },
      {   name: "TAILOR 3450",   slug: "TAILOR 3450",   thumbnail: tile_3450_TAILOR,
      },
      {   name: "TAILOR 350",   slug: "TAILOR 350",   thumbnail: tile_350_TAILOR,
      },
      {   name: "TAILOR 3550",   slug: "TAILOR 3550",   thumbnail: tile_3550_TAILOR,
      },
      {   name: "TAILOR 3650",   slug: "TAILOR 3650",   thumbnail: tile_3650_TAILOR,
      },
      {   name: "TAILOR 3750",   slug: "TAILOR 3750",   thumbnail: tile_3750_TAILOR,
      },
      {   name: "TAILOR 3850",   slug: "TAILOR 3850",   thumbnail: tile_3850_TAILOR,
      },
      {   name: "TAILOR 3950",   slug: "TAILOR 3950",   thumbnail: tile_3950_TAILOR,
      },
      {   name: "TAILOR 4050",   slug: "TAILOR 4050",   thumbnail: tile_4050_TAILOR,
      },
      {   name: "TAILOR 450",   slug: "TAILOR 450",   thumbnail: tile_450_TAILOR,
      },
      {   name: "TAILOR 550",   slug: "TAILOR 550",   thumbnail: tile_550_TAILOR,
      },
      {   name: "TAILOR 650",   slug: "TAILOR 650",   thumbnail: tile_650_TAILOR,
      },
      {   name: "TAILOR 750",   slug: "TAILOR 750",   thumbnail: tile_750_TAILOR,
      },
      {   name: "TAILOR 850",   slug: "TAILOR 850",   thumbnail: tile_850_TAILOR,
      },
      {   name: "TAILOR 950",   slug: "TAILOR 950",   thumbnail: tile_950_TAILOR,
      }
    ]
  },
  {
    category: 'cat. A',
    collection: 'Xtreme',
    items: [
      {   name: "XTREME YS004",   slug: "XTREME YS004",   thumbnail: YS004_TILE,
      },
      {   name: "XTREME YS005",   slug: "XTREME YS005",   thumbnail: YS005_TILE,
      },
      {   name: "XTREME YS009",   slug: "XTREME YS009",   thumbnail: YS009_TILE,
      },
      {   name: "XTREME YS024",   slug: "XTREME YS024",   thumbnail: YS024_TILE,
      },
      {   name: "XTREME YS026",   slug: "XTREME YS026",   thumbnail: YS026_TILE,
      },
      {   name: "XTREME YS030",   slug: "XTREME YS030",   thumbnail: YS030_TILE,
      },
      {   name: "XTREME YS035",   slug: "XTREME YS035",   thumbnail: YS035_TILE,
      },
      {   name: "XTREME YS045",   slug: "XTREME YS045",   thumbnail: YS045_TILE,
      },
      {   name: "XTREME YS046",   slug: "XTREME YS046",   thumbnail: YS046_TILE,
      },
      {   name: "XTREME YS047",   slug: "XTREME YS047",   thumbnail: YS047_TILE,
      },
      {   name: "XTREME YS071",   slug: "XTREME YS071",   thumbnail: YS071_TILE,
      },
      {   name: "XTREME YS072",   slug: "XTREME YS072",   thumbnail: YS072_TILE,
      },
      {   name: "XTREME YS073",   slug: "XTREME YS073",   thumbnail: YS073_TILE,
      },
      {   name: "XTREME YS074",   slug: "XTREME YS074",   thumbnail: YS074_TILE,
      },
      {   name: "XTREME YS076",   slug: "XTREME YS076",   thumbnail: YS076_TILE,
      },
      {   name: "XTREME YS077",   slug: "XTREME YS077",   thumbnail: YS077_TILE,
      },
      {   name: "XTREME YS079",   slug: "XTREME YS079",   thumbnail: YS079_TILE,
      },
      {   name: "XTREME YS081",   slug: "XTREME YS081",   thumbnail: YS081_TILE,
      },
      {   name: "XTREME YS082",   slug: "XTREME YS082",   thumbnail: YS082_TILE,
      },
      {   name: "XTREME YS084",   slug: "XTREME YS084",   thumbnail: YS084_TILE,
      },
      {   name: "XTREME YS091",   slug: "XTREME YS091",   thumbnail: YS091_TILE,
      },
      {   name: "XTREME YS094",   slug: "XTREME YS094",   thumbnail: YS094_TILE,
      },
      {   name: "XTREME YS095",   slug: "XTREME YS095",   thumbnail: YS095_TILE,
      },
      {   name: "XTREME YS096",   slug: "XTREME YS096",   thumbnail: YS096_TILE,
      },
      {   name: "XTREME YS097",   slug: "XTREME YS097",   thumbnail: YS097_TILE,
      },
      {   name: "XTREME YS100",   slug: "XTREME YS100",   thumbnail: YS100_TILE,
      },
      {   name: "XTREME YS101",   slug: "XTREME YS101",   thumbnail: YS101_TILE,
      },
      {   name: "XTREME YS102",   slug: "XTREME YS102",   thumbnail: YS102_TILE,
      },
      {   name: "XTREME YS105",   slug: "XTREME YS105",   thumbnail: YS105_TILE,
      },
      {   name: "XTREME YS108",   slug: "XTREME YS108",   thumbnail: YS108_TILE,
      },
      {   name: "XTREME YS136",   slug: "XTREME YS136",   thumbnail: YS136_TILE,
      },
      {   name: "XTREME YS141",   slug: "XTREME YS141",   thumbnail: YS141_TILE,
      },
      {   name: "XTREME YS145",   slug: "XTREME YS145",   thumbnail: YS145_TILE,
      },
      {   name: "XTREME YS156",   slug: "XTREME YS156",   thumbnail: YS156_TILE,
      },
      {   name: "XTREME YS159",   slug: "XTREME YS159",   thumbnail: YS159_TILE,
      },
      {   name: "XTREME YS160",   slug: "XTREME YS160",   thumbnail: YS160_TILE,
      },
      {   name: "XTREME YS165",   slug: "XTREME YS165",   thumbnail: YS165_TILE,
      },
      {   name: "XTREME YS168",   slug: "XTREME YS168",   thumbnail: YS168_TILE,
      },
      {   name: "XTREME YS171",   slug: "XTREME YS171",   thumbnail: YS171_TILE,
      },
      {   name: "XTREME YS172",   slug: "XTREME YS172",   thumbnail: YS172_TILE,
      },
      {   name: "XTREME YS173",   slug: "XTREME YS173",   thumbnail: YS173_TILE,
      }
    ]
  },
  {
    category: 'cat. A',
    collection: 'Angel',
    items: [
      {   name: "ANGEL 101",   slug: "ANGEL 101",   thumbnail: angel101,
      },
      {   name: "ANGEL 102",   slug: "ANGEL 102",   thumbnail: angel102,
      },
      {   name: "ANGEL 111",   slug: "ANGEL 111",   thumbnail: angel111,
      },
      {   name: "ANGEL 200",   slug: "ANGEL 200",   thumbnail: angel200,
      },
      {   name: "ANGEL 201",   slug: "ANGEL 201",   thumbnail: angel201,
      },
      {   name: "ANGEL 203",   slug: "ANGEL 203",   thumbnail: angel203,
      },
      {   name: "ANGEL 300",   slug: "ANGEL 300",   thumbnail: angel300,
      },
      {   name: "ANGEL 302",   slug: "ANGEL 302",   thumbnail: angel302,
      },
      {   name: "ANGEL 303",   slug: "ANGEL 303",   thumbnail: angel303,
      },
      {   name: "ANGEL 304",   slug: "ANGEL 304",   thumbnail: angel304,
      },
      {   name: "ANGEL 305",   slug: "ANGEL 305",   thumbnail: angel305,
      },
      {   name: "ANGEL 306",   slug: "ANGEL 306",   thumbnail: angel306,
      },
      {   name: "ANGEL 307",   slug: "ANGEL 307",   thumbnail: angel307,
      },
      {   name: "ANGEL 308",   slug: "ANGEL 308",   thumbnail: angel308,
      },
      {   name: "ANGEL 310",   slug: "ANGEL 310",   thumbnail: angel310,
      },
      {   name: "ANGEL 400",   slug: "ANGEL 400",   thumbnail: angel400,
      },
      {   name: "ANGEL 401",   slug: "ANGEL 401",   thumbnail: angel401,
      },
      {   name: "ANGEL 402",   slug: "ANGEL 402",   thumbnail: angel402,
      },
      {   name: "ANGEL 403",   slug: "ANGEL 403",   thumbnail: angel403,
      },
      {   name: "ANGEL 404",   slug: "ANGEL 404",   thumbnail: angel404,
      },
      {   name: "ANGEL 405",   slug: "ANGEL 405",   thumbnail: angel405,
      },
      {   name: "ANGEL 406",   slug: "ANGEL 406",   thumbnail: angel406,
      },
      {   name: "ANGEL 407",   slug: "ANGEL 407",   thumbnail: angel407,
      },
      {   name: "ANGEL 500",   slug: "ANGEL 500",   thumbnail: angel500,
      },
      {   name: "ANGEL 501",   slug: "ANGEL 501",   thumbnail: angel501,
      },
      {   name: "ANGEL 503",   slug: "ANGEL 503",   thumbnail: angel503,
      },
      {   name: "ANGEL 600",   slug: "ANGEL 600",   thumbnail: angel600,
      },
      {   name: "ANGEL 601",   slug: "ANGEL 601",   thumbnail: angel601,
      },
      {   name: "ANGEL 700",   slug: "ANGEL 700",   thumbnail: angel700,
      },
      {   name: "ANGEL 701",   slug: "ANGEL 701",   thumbnail: angel701,
      },
      {   name: "ANGEL 702",   slug: "ANGEL 702",   thumbnail: angel702,
      },
      {   name: "ANGEL 703",   slug: "ANGEL 703",   thumbnail: angel703,
      },
      {   name: "ANGEL 705",   slug: "ANGEL 705",   thumbnail: angel705,
      },
      {   name: "ANGEL 706",   slug: "ANGEL 706",   thumbnail: angel706,
      },
      {   name: "ANGEL 707",   slug: "ANGEL 707",   thumbnail: angel707,
      },
      {   name: "ANGEL 800",   slug: "ANGEL 800",   thumbnail: angel800,
      },
      {   name: "ANGEL 801",   slug: "ANGEL 801",   thumbnail: angel801,
      },
      {   name: "ANGEL 802",   slug: "ANGEL 802",   thumbnail: angel802,
      },
      {   name: "ANGEL 803",   slug: "ANGEL 803",   thumbnail: angel803,
      },
      {   name: "ANGEL 901",   slug: "ANGEL 901",   thumbnail: angel901,
      },
    ]
  }
]

export default catA