const products = [
    {
        name: "ACCURSIO TAVOLINO CM.30 X 40 H.45", 
        family: "ACCURSIO", 
        id: "9031", 
        code: "U2WT3XFMGY",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.35 X 35 H.40", 
        family: "ACCURSIO", 
        id: "9030", 
        code: "YQG2HAR8HF",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.35 X 50 H.40", 
        family: "ACCURSIO", 
        id: "9032", 
        code: "NY4X3CNL9J",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.35 X 60 H.50", 
        family: "ACCURSIO", 
        id: "9034", 
        code: "AQQYEHGG1O",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.40 X 140 H.75", 
        family: "ACCURSIO", 
        id: "9039", 
        code: "MT21PKA6MM",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.40 X 40 H.45", 
        family: "ACCURSIO", 
        id: "9033", 
        code: "8RNXM4ET3H",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.40 X 80 H.35", 
        family: "ACCURSIO", 
        id: "9037", 
        code: "ZI9388LIAK",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.45 X 45 H.85", 
        family: "ACCURSIO", 
        id: "9038", 
        code: "RC3JSFM376",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.50 X 50 H.35", 
        family: "ACCURSIO", 
        id: "9035", 
        code: "NPEE2191ZT",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO CM.70 X 70 H.40", 
        family: "ACCURSIO", 
        id: "9036", 
        code: "G3BXH6RIHZ",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO DIAMETRO 35 H.40", 
        family: "ACCURSIO", 
        id: "9040", 
        code: "ZTRT9CRZFC",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO DIAMETRO 40 H.45", 
        family: "ACCURSIO", 
        id: "9041", 
        code: "OYJQ5Z76TX",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO DIAMETRO 45 H.85", 
        family: "ACCURSIO", 
        id: "9045", 
        code: "1A3XT8DVUH",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO DIAMETRO 50 H.35", 
        family: "ACCURSIO", 
        id: "9042", 
        code: "OYJQ5Z76TX",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO DIAMETRO 60 H.60", 
        family: "ACCURSIO", 
        id: "9044", 
        code: "LDYKTWLMZK",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "ACCURSIO TAVOLINO DIAMETRO 80 H.40", 
        family: "ACCURSIO", 
        id: "9043", 
        code: "85URGX0BBB",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: [
            'METAL AVORIO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL AZZURRO FIORDALISO', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME', 'METAL PITCH BLACK', 'METAL PRUGNA', 'METAL TE VERDE'
        ]
    },
    {
        name: "AGETTI POLTRONA", 
        id: "1580", 
        code: "5SBW34RHAC",
        family: "Agetti",
        hasFabric: true,
        hasWood: true,
        // presenta un piccolo bug nella mesh o texture, sotto la seduta
        customMaterial: [
            'FAGGIO NATURALE', 'FRASSINO TINTA GRAFITE'
        ],
    },
    {
        name: "AGETTI DIVANO 2 POSTI", 
        id: "1581", 
        code: "X8L02VUZO5",
        family: "Agetti",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'FAGGIO NATURALE', 'FRASSINO TINTA GRAFITE'
        ],
    },
    {
        name: "ALA DIVANO ALTO - BASE LEGNO", 
        id: "8886N", 
        code: "6BKD8HGGB0",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO ALTO - BASE METALLO", 
        id: "8876N", 
        code: "H6XTFJW3S5",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO ALTO CAPITONNE - BASE METALLO", 
        id: "8903N", 
        code: "3J83QT095R",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO ALTO CAPITONNE - BASE LEGNO", 
        id: "8908N", 
        code: "KKJU766X2H",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO ALTO CON BRACCIOLI - BASE LEGNO", 
        id: "8887N", 
        code: "M2Z26H56BX",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO ALTO CON BRACCIOLI - BASE METALLO", 
        id: "8877N", 
        code: "YVWJDA5LZ8",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO BASSO - BASE LEGNO", 
        id: "8882N", 
        code: "AJWUKGUA3S",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO BASSO - BASE METALLO", 
        id: "8872N", 
        code: "VJ2813IWQO",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO BASSO CAPITONNE - BASE LEGNO", 
        id: "8906N", 
        code: "SK6GFVVUFN",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO BASSO CAPITONNE - BASE METALLO", 
        id: "8901N", 
        code: "5BONGXODB1",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO BASSO CON BRACCIOLI - BASE LEGNO", 
        id: "8883N", 
        code: "WCNKBTCH8B",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ALA DIVANO BASSO CON BRACCIOLI - BASE METALLO", 
        id: "8873N", 
        code: "P6TUZ5FLSP",
        family: "Ala",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'METAL AVORIO', 'FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL PITCH BLACK', 'METAL PRUGNA'
        ],
    },
    {
        name: "ANYTIME POLTRONA", 
        id: "1224NN", 
        code: "EMYRI1B4XQ",
        family: "Anytime",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'CROMATO LUCIDO', 'METAL RAL 9005'
        ]
    },
    {
        name: "ANYTIME DIVANO 2 POSTI", 
        id: "1225NN", 
        code: "R1PNLXYVGG",
        family: "Anytime",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'CROMATO LUCIDO', 'METAL RAL 9005'
        ]
    },
    {
        name: "ANYTIME DIVANO 2 POSTI MAJOR", 
        id: "1226NN", 
        code: "2ZX8SXRNML",
        family: "Anytime",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'CROMATO LUCIDO', 'METAL RAL 9005'
        ]
    },
    {
        name: "ANYTIME DIVANO 3 POSTI", 
        id: "1227NN", 
        code: "BE62HVRLZ2",
        family: "Anytime",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'CROMATO LUCIDO', 'METAL RAL 9005'
        ]
    },
    {
        name: "ANYTIME POUFF 90x60", 
        id: "1502NN", 
        code: "G7Q0VHGRMG",
        family: "Anytime",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'CROMATO LUCIDO', 'METAL RAL 9005'
        ]
    },
    {
        name: "ANYTIME POUFF 180x60", 
        id: "1503NN", 
        code: "Q1GBLL8DTA",
        family: "Anytime",
        hasFabric: true,
        hasWood: true,
        customMaterial: [
            'CROMATO LUCIDO', 'METAL RAL 9005'
        ]
    },
    {
        name: "APPER POLTRONA",
        // pdf mancante? 
        id: "1460", 
        code: "WS60HI4ATT",
        hasFabric: true,
        hasWood: true,
        family: "Apper",
    },
    {
        name: "ARIA POLTRONA",
        id: "8780", 
        code: "WU4V0XXVNY",
        hasFabric: true,
        hasWood: true,
        customCollection: [
            'Saddle Leather',
        ],
        // default sbagliato rispetto al pdf
        customMaterial: [
            'METAL RAL 9005'  ],
            family: "Aria"
    },
    {
        name: "BENNETT POLTRONCINA CON BASE GIREVOLE", 
        id: "8785N", 
        code: "7UP2U0UZFL",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005'],
        family: "Bennet"
    },
    {
        name: "BENNETT POLTRONCINA CON GAMBE", 
        id: "8760N", 
        code: "YF5SVWJM36",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005'],
        family: "Bennet"
    },
    {
        name: "BERNARD POLTRONA CORNER SMALL", 
        id: "1120", 
        code: "WU2B0LFW5N",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POLTRONA CENTRALE SMALL", 
        id: "1121", 
        code: "5VXSK2B2HR",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POLTRONA SMALL", 
        id: "1122", 
        code: "97VFWH25ST",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POUF MEDIUM", 
        id: "1125", 
        code: "LR4M3JDB26",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POLTRONA", 
        id: "1130", 
        code: "IELL1C3OCN",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POLTRONA CENTRALE", 
        id: "1131", 
        code: "85KIKLVAHU",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD DIVANO 2 POSTI", 
        id: "1132", 
        code: "NF3EGCMOHC",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD DIVANO 3 POSTI", 
        id: "1133", 
        code: "T3LA92IGSN",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD DORMEUSE", 
        id: "1134", 
        code: "WKKB4APGOG",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POUF LARGE", 
        id: "1135", 
        code: "PU2Q5GIQEN",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD PANCA", 
        id: "1136", 
        code: "CTD9CTZWVB",
        family: "Bernard",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD TAVOLINO MEDIUM 61X61", 
        id: "1141", 
        code: "7DQLPLZZF7",
        family: "Bernard",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customPiano: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'ROVERE SPAZZOLATO BIANCO', 'WOOD RAL 9016', 'WOOD RAL 9005', 'MARMO MARQUINA', 'MARMO CALACATTA', 'MARMO CARRARA'],
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD TAVOLINO SMALL 35X35", 
        id: "1140", 
        code: "D8J5E7RTXJ",
        family: "Bernard",
        hasFabric: false,
        hasPiano: true,
        hasWood: true,
        customPiano: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'ROVERE SPAZZOLATO BIANCO', 'WOOD RAL 9016', 'WOOD RAL 9005', 'MARMO MARQUINA', 'MARMO CALACATTA', 'MARMO CARRARA'],
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BERNARD POUF SMALL", 
        id: "1124", 
        code: "G6SKERT9BQ",
        family: "Bernard",
        hasFabric: false,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "BORGO TAVOLINO 40 X 40 X H.43", 
        id: "1430", 
        code: "76LNXOV7LP",
        family: "Borgo",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "BORGO TAVOLINO 50 X 50 X H.35", 
        id: "1431", 
        code: "CN5FOD4PZ6",
        family: "Borgo",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "BORGO TAVOLINO 60 X 35 X H.50", 
        id: "1432", 
        code: "Q40N3CL7PA",
        family: "Borgo",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
/*     {
        name: "COUCHETTE SOFA", 
        code: "17RO0OCMJB",
        ID MANCANTE
        family: "Couchette",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['METAL RAL 9005', 'METAL PITCH BLACK', 'METAL RAL 9016']
    }, */
    {
        name: "COUCHETTE POLTRONA", 
        id: "1170", 
        code: "UI357RKW7W",
        family: "Couchette",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['METAL RAL 9005', 'METAL PITCH BLACK', 'METAL RAL 9016']
    },
    {
        name: "COUCHETTE DIVANO 2 POSTI", 
        id: "1171", 
        code: "E3E2G1ELJV",
        family: "Couchette",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['METAL RAL 9005', 'METAL PITCH BLACK', 'METAL RAL 9016']
    },
    {
        name: "COUCHETTE DIVANO 3 POSTI", 
        id: "1172", 
        code: "A9HL5LIX37",
        family: "Couchette",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['METAL RAL 9005', 'METAL PITCH BLACK', 'METAL RAL 9016']
    },

    {
        name: "CUBUS DIVANO 2 POSTI", 
        id: "2102NN", 
        code: "TJH4JNO635",
        family: "Cubus",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FAGGIO TINTA NOCE', 'CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "CUBUS DIVANO 3 POSTI", 
        id: "2103NN", 
        code: "C4NAQAASUU",
        family: "Cubus",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FAGGIO TINTA NOCE', 'CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "CUBUS POLTRONA", 
        id: "2101NN", 
        code: "30DTH0M6FZ",
        family: "Cubus",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FAGGIO TINTA NOCE', 'CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "CUBUS POLTRONA CON RUOTE", 
        id: "2104NN", 
        code: "H3JCXL2221",
        family: "Cubus",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FAGGIO TINTA NOCE', 'CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "DEP DIVANETTO", 
        id: "8402", 
        code: "RZS850R4DR",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP DIVANO 2 POSTI cm. 160", 
        id: "8421", 
        code: "R42HJOVVUR",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP DIVANO 2 POSTI MAJOR cm. 190", 
        id: "8422", 
        code: "5R8J5P57DH",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP DIVANO 3 POSTI cm. 220", 
        id: "8423", 
        code: "XT6HWZX4OR",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP DIVANO 3 POSTI MAJOR cm. 250", 
        id: "8424", 
        code: "8DM8T5NKX7",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP POLTRONA", 
        id: "8410", 
        code: "WR3994B9Z1",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP POLTRONA CON BASE GIREVOLE", 
        id: "8425", 
        code: "OSSNXE0181",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP POLTRONA PICCOLA", 
        id: "8401", 
        code: "3TMUSGZ64O",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DEP POLTRONA PICCOLA CON BASE GIREVOLE", 
        id: "8403", 
        code: "NDXOLVEAK2",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINO OPACO'],
        family: "Dep",
    },
    {
        name: "DOROTHEA SOFA", 
        code: "VA78WX6Y02",
        hasFabric: true,
        hasWood: true,
        family: "Dorothea",
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA SOFA PAR 16", 
        code: "PDGKLZUFY0",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR01", 
        id: "DOR01", 
        code: "9HKUZUKAW0",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR02", 
        id: "DOR02", 
        code: "MR0TDKNEAT",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR03", 
        id: "DOR03", 
        code: "N5DFQZJS4Z",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR04", 
        id: "DOR04", 
        code: "VQ34NVNIOW",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR05", 
        id: "DOR05", 
        code: "N2S7KFSKHF",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR06", 
        id: "DOR06", 
        code: "M32MWSG9QJ",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR07", 
        id: "DOR07", 
        code: "R0HRXA5WXT",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR08", 
        id: "DOR08", 
        code: "WQZSV48K9V",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR09", 
        id: "DOR09", 
        code: "ZQDU0PL9KW",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR10", 
        id: "DOR10", 
        code: "056WENIYTB",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR11", 
        id: "DOR11", 
        code: "66JTD84BRI",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR12", 
        id: "DOR12", 
        code: "VCFXPZ9N7X",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR13", 
        id: "DOR13", 
        code: "6YT3KWQ2XV",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR14", 
        id: "DOR14", 
        code: "62UC30082Y",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR15", 
        id: "DOR15", 
        code: "URVHYCWI3U",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR16", 
        id: "DOR16", 
        code: "NYVEBINREW",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR17", 
        id: "DOR17", 
        code: "P858TNVYJM",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR18", 
        id: "DOR18", 
        code: "4EGJ4UZJG3",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR19", 
        id: "DOR19", 
        code: "W1MVF27VXI",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR20", 
        id: "DOR20", 
        code: "3UBW6ZKIJQ",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "DOROTHEA COMPOSIZIONE DOR21", 
        id: "DOR21", 
        code: "YXXK0J4ZMZ",
        family: "Dorothea",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE']
    },
    {
        name: "ELLE DIVANO 2P CON PIEDI SENZA CUSCINI", 
        id: "8751N", 
        code: "LCHAB83ODH",
        hasFabric: true,
        hasWood: false,
        family: "Elle P",
    },
    {
        name: "ELLE DIVANO 2P MAJOR CON PIEDI SENZA CUSCINI", 
        id: "8752N", 
        code: "NCC7TTG6ZO",
        hasFabric: true,
        hasWood: false,
        family: "Elle P",
    },
    {
        name: "ELLE DIVANO 3P CON PIEDI SENZA CUSCINI", 
        id: "8753N", 
        code: "8EXQ8WFQW3",
        hasFabric: true,
        hasWood: false,
        family: "Elle P",
    },
    {
        name: "ELLE POLTRONA CON PIEDI SENZA CUSCINOI", 
        id: "8750N", 
        code: "CKCM5P042B",
        hasFabric: true,
        hasWood: false,
        family: "Elle P",
    },
    {
        name: "ELLE POLTRONCINA CON PIEDI SENZA CUSCINO", 
        id: "8790N", 
        code: "PLCO3HT0D1",
        hasFabric: true,
        hasWood: false,
        family: "Elle P",
    },
    {
        name: "FOLIES BERGER POLTRONA",
        id: "2201N", 
        code: "RRRGYVZR97",
        family: "Folies Berger",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "FOLIES MIDI DIVANO 2 POSTI", 
        id: "2205NN", 
        code: "H95QEWFIJE",
        family: "Folies",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINIO LUCIDO', 'ALLUMINO OPACO', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "FOLIES MIDI DIVANO 3 POSTI", 
        id: "2207NN", 
        code: "N78Z9650LB",
        family: "Folies",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINIO LUCIDO', 'ALLUMINO OPACO', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "FOLIES MIDI POLTRONA CON BASE GIREVOLE", 
        id: "2204N", 
        code: "FSVV6B7YT3",
        family: "Folies",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINIO LUCIDO', 'ALLUMINO OPACO', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "FOLIES MIDI POLTRONA CON GAMBE", 
        id: "2203NN", 
        code: "DZ8TOITYB7",
        family: "Folies",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINIO LUCIDO', 'ALLUMINO OPACO', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "FOLIES MIDI POUFF FINO A TERRA", 
        id: "2206N", 
        code: "YCDCM0Z6PU",
        family: "Folies",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ALLUMINIO LUCIDO', 'ALLUMINO OPACO', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "GUEST POLTRONA", 
        code: "FUYMAAGCR5",
        id: "9000",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST DIVANO 2 POSTI", 
        code: "GLKJK0CISW",
        id: "9001",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST DIVANO 3 POSTI", 
        code: "H1W8Y1KLFE",
        id: "9003",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST PENISOLA DESTRA", 
        code: "SWJ2X6MADA",
        id: "9004",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST PENISOLA SINISTRA", 
        code: "UECYH8XVQA",
        id: "9005",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST POUF DIAM. 100", 
        code: "HMOYHE4M55",
        id: "9020",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST POUF DIAM. 130", 
        code: "O9M7XUCJEO",
        id: "9021",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "GUEST POUF DIAM. 70", 
        code: "X5EXVRVHF5",
        id: "9019",
        family: "Guest",
        hasFabric: true,
        hasWood: false,
    },
    {
        name: "HOLIDAY POLTRONA", 
        code: "4N8H7GPL13",
        id: "2017N",
        family: "Holiday",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "HOLIDAY DIVANO 2 POSTI", 
        code: "7B7WUTVP9I",
        id: "2018N",
        family: "Holiday",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "HOLIDAY DIVANO 3 POSTI", 
        code: "6F2XASWPX0",
        id: "2019N",
        family: "Holiday",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "HOLIDAY POLTRONCINA", 
        code: "7CWCYRG9IZ",
        id: "2015N",
        family: "Holiday",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },

    {
        name: "HOLIDAY POLTRONCINA CON RUOTE", 
        code: "OX6KFJMOGM",
        id: "2035",
        family: "Holiday",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'CROMATO LUCIDO', 'METAL RAL 9005'],
    },
    {
        name: "IDDA TAVOLINO 71 X 76 H.28", 
        code: "ETDVX2UGB2",
        id: "1421",
        family: "IDDA",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "JANGO DIVANO 2 POSTI CON BASE CROCIERA", 
        code: "DYECP6VCA8",
        id: "2031NN",
        family: "Jango",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "JANGO POLTRONA", 
        code: "BK255915CG",
        id: "2030N",
        family: "Jango",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "JOIN DIVANO 2 POSTI SMALL cm.140", 
        code: "6NAXBWE79N",
        id: "8841N",
        hasFabric: true,
        hasWood: true,
        family: "Join",
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'ALLUMINIO LUCIDO'  ]
    },
    {
        name: "JOIN POUFF 180x70", 
        code: "8MIVISS76Y",
        id: "8848N",
        hasFabric: true,
        hasWood: true,
        family: "Join",
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'ALLUMINIO LUCIDO'  ]
    },
    {
        name: "KEISHO TAVOLO", 
        code: "1NKFA13B04",
        id: "1110",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'FRASSINO TINTA NOCE'  ],
        customPiano: ['METAL AVORIO', 'METAL AZZURRO', 'METAL BRONZO', 'METAL CIOCCOLATO', 'METAL FANGO', 'METAL OTTONE', 'METAL PITCH BLACK', 'METAL RAME', 'METAL TE VERDE'  ],
        hasPiano: true,
        family: "Keisho"
    },
    {
        name: "LINE BENCH PANCA 120", 
        code: "DPXPIS8TVE",
        id: "8691N",
        family: "Line Bench",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA NOCE', 'METAL RAL 9016', 'METAL RAL 3003', 'METAL RAL 5014'  ],
        customPiano: ['FRASSINO NATURALE', 'FRASSINO TINTA NOCE', 'METAL RAL 9016', 'METAL RAL 3003', 'METAL RAL 5014'  ]
    },
    {
        name: "LINE BENCH PANCA 180", 
        code: "E105R84ZN6",
        id: "8692N",
        family: "Line Bench",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA NOCE', 'METAL RAL 9016', 'METAL RAL 3003', 'METAL RAL 5014'  ],
        customPiano: ['FRASSINO NATURALE', 'FRASSINO TINTA NOCE', 'METAL RAL 9016', 'METAL RAL 3003', 'METAL RAL 5014'  ]
    },
    {
        name: "MANHATTAN DIVANO 2 POSTI", 
        code: "UF1P5WMGAG",
        id: "5102N",
        family: "Manhattan",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['FAGGIO NATURALE', 'FAGGIO TINTA NOCE', 'ALLUMINIO LUCIDO', 'ALLUMINO OPACO' ]
    },
    {
        name: "MANHATTAN DIVANO 3 POSTI", 
        code: "YDRU7DR3YN",
        id: "5104N",
        family: "Manhattan",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['FAGGIO NATURALE', 'FAGGIO TINTA NOCE', 'ALLUMINIO LUCIDO', 'ALLUMINO OPACO' ]
    },
    {
        name: "MATRIX DIVANO 2 POSTI", 
        code: "Y9JD91ZH9Z",
        id: "4102N",
        family: "Matrix",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "MATRIX DIVANO 2 POSTI MAJOR", 
        code: "D9LKH8JAT8",
        id: "4103N",
        family: "Matrix",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "MATRIX DIVANO 3 POSTI", 
        code: "NHBO95YQZF",
        id: "4104N",
        family: "Matrix",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "MATRIX POLTRONA", 
        code: "CE6D69KPS9",
        id: "4101N",
        family: "Matrix",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "MATRIX POLTRONCINA TRENDY CON BASE", 
        code: "RW4UOIXMAW",
        id: "4202N",
        family: "Matrix",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "MATRIX POLTRONCINA TRENDY GIREVOLE", 
        code: "A6GFRKA923",
        id: "4201N",
        family: "Matrix",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "MIL TAVOLO DIAMETRO 100", 
        code: "BJ0TFF5LY8",
        id: "9097",
        family: "Mil",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'ROVERE SPAZZOLATO BIANCO'  ],
        customPiano: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'VETRO TRASPARENTE', 'VETRO FUME', 'ROVERE SPAZZOLATO BIANCO', 'MARMO MARQUINA', 'MARMO CARRARA'   ]
    },
    {
        name: "MIL TAVOLO DIAMETRO 50", 
        code: "A1BN2N6UJ0",
        id: "9095",
        family: "Mil",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'ROVERE SPAZZOLATO BIANCO'  ],
        customPiano: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'VETRO TRASPARENTE', 'VETRO FUME', 'ROVERE SPAZZOLATO BIANCO', 'MARMO MARQUINA', 'MARMO CARRARA'   ]
    },
    {
        name: "MIL TAVOLO DIAMETRO 75", 
        code: "EOCJBJQJX6",
        id: "9096",
        family: "Mil",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'ROVERE SPAZZOLATO BIANCO'  ],
        customPiano: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'VETRO TRASPARENTE', 'VETRO FUME', 'ROVERE SPAZZOLATO BIANCO', 'MARMO MARQUINA', 'MARMO CARRARA'   ]
    },
    {
        name: "MODO POUFF GRANDE", 
        code: "51RJR3VEHP",
        id: "8560",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['METAL RAL 7021' ],
        family: "Modo"
    },
    {
        name: "MODO POUFF PICCOLO", 
        code: "DKTBCO4RK3",
        id: "8551",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['METAL RAL 7021' ],
        family: "Modo"
    },
    {
        name: "MOLECULE PANCA", 
        code: "O6R5KQFQ8L",
        id: "5605",
        family: "Molecule",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL RAL 9005', 'METAL RAL 9016', 'CROMATO LUCIDO'  ],
        customPiano: ['WOOD RAL 9005', 'WOOD RAL 9016' ]
    },
    {
        name: "MOLECULE POUFF RETTANGOLO", 
        code: "VCKVFWQCVP",
        id: "5608",
        family: "Molecule",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['METAL RAL 9005', 'METAL RAL 9016', 'CROMATO LUCIDO'  ],
        customPiano: ['WOOD RAL 9005', 'WOOD RAL 9016' ]
    },
    {
        name: "MOLECULE TAVOLO", 
        code: "NIUHX6OM4B",
        id: "5606N",
        family: "Molecule",
        hasFabric: false,
        hasWood: true,
        hasPiano: true,
        customMaterial: ['METAL RAL 9005', 'METAL RAL 9016', 'CROMATO LUCIDO'  ],
        customPiano: ['WOOD RAL 9005', 'WOOD RAL 9016' ]
    },
    {
        name: "MRS.BUFFY LARGE DIVANO 2 POSTI", 
        code: "56AGTJ396Z",
        id: "8106N",
        family: "Mrs. Buffy",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['CROMATO LUCIDO'  ]
    },
    {
        name: "MRS.BUFFY POLTRONA", 
        code: "WESIF4JIS5",
        id: "8105N",
        family: "Mrs. Buffy",
        hasFabric: true,
        hasWood: true,
        hasPiano: false,
        customMaterial: ['CROMATO LUCIDO'  ]
    },
    {
        name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO", 
        family: "Myplace",
        id: "9050N",
        code: "7EW7MIKME5",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'METAL PITCH BLACK', 'METAL AVORIO'  ]
    },
    {
        name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO", 
        family: "Myplace",
        id: "9054N",
        code: "QCYB5Y94XA",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'METAL PITCH BLACK', 'METAL AVORIO'  ]
    },
    {
        name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO", 
        family: "Myplace",
        id: "9061N",
        code: "4RXJLY82OA",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'METAL PITCH BLACK', 'METAL AVORIO'  ]
    },
    {
        name: "NAP DIVANO 2 POSTI", 
        family: "Nap",
        id: "1071N",
        code: "CTJUEDJYP8",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE'  ]
    },
    {
        name: "NAP DIVANO 3 POSTI CON CUSCINI", 
        family: "Nap",
        id: "1092N",
        code: "QZYG2B09L3",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE'  ]
    },
    {
        name: "NAP DIVANO 4 POSTI", 
        family: "Nap",
        id: "1074N",
        code: "SSF9ISHRUH",
        hasFabric: true,
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE'  ]
    },
    {
        name: "NAUTILE POLTRONA RIVESTITA", 
        code: "NP7OR4WQQY",
        id: "8702",
        hasFabric: true,
        hasWood: false,
        family: "Nautile",
    },
    {   
        name: "NEWTIME SOFA", 
        // non presente tra i pdf prodotti
        code: "F64LZMALPH",
        hasFabric: true,
        hasWood: false,
        family: "Newtime"
    },
    {
        name: "NODE PLUS SOFA", 
        code: "SEFV479SM5",
        hasFabric: true,
        hasWood: true,
        // manca "FRASSINO TINTA BIANCA" tra i nostri materiali - presente nel pdf
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', ],
        family: "Node Plus"
    },
    {
        name: "OSAKA SOFA", 
        code: "ENUQUNXR4G",
        hasFabric: true,
        hasWood: true,
        customCollection: [
            'Stretchy', 'Bit', 'Mosaic 2', 'Uniform Mèlange', 'Triangle', 'Gentle 2'
        ],
        customMaterial: ['METAL RAL 9005'],
        family: "Osaka"
    },
    {
        name: "OSAKA DIVANO 180", 
        code: "F5LZBQUIQA",
        id: "8801", 
        hasFabric: true,
        hasWood: true,
        customCollection: [
            'Stretchy', 'Bit', 'Mosaic 2', 'Uniform Mèlange', 'Triangle', 'Gentle 2'
        ],
        customMaterial: ['METAL RAL 9005'],
        family: "Osaka"
    },
    {
        name: "OSAKA DIVANO 230", 
        code: "THWSW9VF11",
        id: "8802", 
        hasFabric: true,
        hasWood: true,
        customCollection: [
            'Stretchy', 'Bit', 'Mosaic 2', 'Uniform Mèlange', 'Triangle', 'Gentle 2'
        ],
        customMaterial: ['METAL RAL 9005'],
        family: "Osaka"
    },    
    {
        name: "SCANCARO TAVOLINO DIAMETRO 110 H.25 INCLINATO", 
        id: "8836", 
        code: "JX6WOQJY99",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "SCANCARO TAVOLINO DIAMETRO 35 H.40", 
        id: "8830", 
        code: "8NX9JMDJGF",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "SCANCARO TAVOLINO DIAMETRO 35 H.40 INCLINATO", 
        id: "8832", 
        code: "DPY4P3ZBFF",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "SCANCARO TAVOLINO DIAMETRO 35 H.45", 
        id: "8831", 
        code: "7UHYX6NGRC",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "SCANCARO TAVOLINO DIAMETRO 35 H.50 INCLINATO", 
        id: "8833", 
        code: "URDE9DHAI9",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "SCANCARO TAVOLINO DIAMETRO 50 H.30 INCLINATO", 
        id: "8834", 
        code: "I9OK6AM3WJ",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "SCANCARO TAVOLINO DIAMETRO 80 H.25 INCLINATO", 
        id: "8835", 
        code: "ZV7VXTR7HY",
        family: "Scancaro",
        hasFabric: false,
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK', 'METAL CIOCCOLATO', 
            'METAL AZZURRO FIORDALISO', 'METAL TE VERDE', 'METAL BRONZO', 'METAL OTTONE', 'METAL RAME' ]
    },
    {
        name: "PHAR LAP SEDIA LOUNGE", 
        code: "9TJXJS3710",
        id: "1160",
        hasFabric: true,
        family: "Phar Lap",
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "PINCH SOFA", 
        code: "D2KVHLEDBI",
        hasFabric: true,
        hasWood: true,
        family: "Pinch",
        customMaterial: ['METAL AVORIO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "PLOFF POUF", 
        code: "70HPOYVSL3",
        hasFabric: false,
        hasWood: false,
        family: "Ploff",
    },
    {
        name: "PUZZLE DIVANO", 
        code: "ML610RF36S",
        hasFabric: true,
        // struttura non presente nel pdf
        hasWood: false,
        family: "Puzzle"
    },
    {
        name: "SERIE 50 POLTRONA", 
        code: "XT4G1FBA1L",
        hasFabric: true,
        family: "Serie 50",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA WENGE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 7021'  ]
    },
    {
        name: "SHES SGABELLO", 
        code: "MGSCUT1TEA",
        hasFabric: true,
        family: "Shes",
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "SOAVE SOFA", 
        code: "1DEBTZULK3",
        hasFabric: true,
        family: "Soave",
        hasWood: true,
        customMaterial: ['METAL PITCH BLACK', 'METAL PRUGNA',
         'METAL OTTONE', 'METAL RAME' ],
    },
    {
        name: "SPEAK EASY POLTRONA", 
        code: "G7QP066HJ5",
        hasFabric: true,
        family: "Speak Easy",
        hasWood: true,
        // nel pdf non è specificato l'alluminio
        customMaterial: ['CROMATO LUCIDO', 'ALLUMINIO LUCIDO']
    },
    {
        name: "STONES DORMEUSE", 
        code: "SXZQP2FWAZ",
        hasFabric: true,
        family: "Stones",
        id: "5705",
        hasWood: true,
        // 1 struttura - ma default a WOOD RAL 9005
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "STONES SEDIA", 
        code: "F4CK46BXI1",
        hasFabric: true,
        family: "Stones",
        id: "5701",
        hasWood: true,
        // 1 struttura - ma default a WOOD RAL 9005
        customMaterial: ['CROMATO LUCIDO' ]
    },
    {
        name: "SUISEKI SOFA", 
        code: "HX3AB2CTDZ",
        hasFabric: true,
        family: "Suiseki",
        hasWood: false,
    },
    {
        name: "SUSHI SOFA", 
        code: "W2BLVUNHZR",
        hasFabric: true,
        family: "Sushi",
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "SWALE POLTRONA", 
        code: "UEXIP8S7XV",
        hasFabric: true,
        family: "Swale",
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL FANGO', 'METAL PRUGNA', 'METAL PITCH BLACK']
    },
    {
        name: "TACO SEDIA", 
        code: "UGSYZT2GH7",
        hasFabric: true,
        hasWood: true,
        customCollection: [
            'Jaak',
        ],
        customMaterial: [
            'METAL PITCH BLACK', 'METAL AVORIO',
        ],
        family: "Taco",
    },
    {
        name: "TAILOR POLTRONA", 
        code: "UEXIP8S7XV",
        hasFabric: true,
        family: "Tailor",
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'METAL RAL 9005'],
    },
    {
        name: "TAKIMI SOFA", 
        code: "QKDAKEUT9A",
        hasFabric: true,
        family: "Takimi",
        hasWood: true,
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9016', 'METAL RAL 9005'],
    },
    {
        name: "TIMO POLTRONA", 
        code: "WDFLRHHNW2",
        hasFabric: true,
        family: "Timo",
        hasWood: true,
        customMaterial: ['ROVERE SPAZZOLATO NATURALE', 'ROVERE SPAZZOLATO GRAFITE', 'METAL AVORIO', 'METAL PITCH BLACK'],
    },
    {
        name: "TWINGO POLTRONA", 
        code: "3R89SF74YM",
        hasFabric: true,
        family: "Twingo",
        hasWood: true,
        customMaterial: ['ALLUMINIO LUCIDO', 'ALLUMINO OPACO', 'METAL RAL 9005']
    },
    {
        name: "TWIRL POLTRONA", 
        code: "4AJKB7CRKP",
        hasFabric: true,
        family: "Twirl",
        hasWood: true,
        customMaterial: ['METAL AVORIO', 'METAL PITCH BLACK', 'MARMO MARQUINA', 'MARMO CARRARA' ]
    },
    {
        name: "VELOUR DIVANO 2 POSTI H.80", 
        id: "3402/B80",
        code: "QB97A0ZQQZ",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR DIVANO 3 POSTI H.110", 
        id: "3503/B110",
        code: "SOQ22JIK7H",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR DIVANO 3 POSTI H.80", 
        id: "3403/B80",
        code: "ZCMEZNOGEY",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR DIVANO 3 POSTI MAJOR H.110", 
        id: "3504/B110",
        code: "HWUDROAG4R",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR DIVANO 3 POSTI MAJOR H.80", 
        id: "3404/B80",
        code: "IRY47QCI7N",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR DORMEUSE 165 DX H.80", 
        id: "3417/B80DX",
        code: "4V3LY5PZ3B",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR DORMEUSE 165 SX H.80", 
        id: "3419/B80SX",
        code: "GOKNPZ35D1",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR POLTRONA CON PIEDI H.110", 
        id: "3500/B110",
        code: "Q5NYQJAA6H",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR POLTRONA GIREVOLE H.110", 
        id: "3501/B110N",
        code: "0RQ3IEDF9L",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "VELOUR POUF 150 X 60", 
        id: "3592",
        code: "3ABCPRHYI2",
        hasFabric: true,
        family: "Velour",
        hasWood: true,
        customMaterial: ['FRASSINO NATURALE', 'FRASSINO TINTA GRAFITE', 'METAL RAL 9016', 'METAL RAL 9005', 'CROMATO LUCIDO'  ]
    },
    {
        name: "WAVES POUF", 
        code: "XJWHTDQ3CR",
        hasFabric: true,
        hasWood: true,
        family: "Waves",
        // default impostato a METAL RAL 5014 unica struttura
        customMaterial: ['METAL RAL 7021' ]
    },
    {
        name: "WHY NOT DIVANO 4 POSTI", 
        code: "5Q395CKGOC",
        id: "8452N",
        hasFabric: true,
        hasWood: true,
        family: "Why not",
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "WHY NOT POUFF 190", 
        code: "RMZZ82Q1ZE",
        id: "8466N",
        hasFabric: true,
        hasWood: true,
        family: "Why not",
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "WHY NOT POUFF R180", 
        code: "U3LSMODTRH",
        id: "8467N",
        hasFabric: true,
        hasWood: true,
        family: "Why not",
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "WHY NOT POUFF R395", 
        code: "OO02O5OD06",
        id: "8468N",
        hasFabric: true,
        hasWood: true,
        family: "Why not",
        customMaterial: ['CROMATO LUCIDO', 'METAL RAL 9005']
    },
    {
        name: "WINDMILL POUF", 
        code: "6HVMQ8HMXM",
      /*   code: "XO37I03AOQ", */
        hasFabric: true,
        hasWood: false,
        family: "Windmill",
        customCollection: [
            'Hallingdal 65', 'Fame'
        ],
        customMaterial: []
    },
];

export default products