import React, { useCallback, useEffect, useRef, useState } from 'react';
import icon_camera from './assets/camera.svg';
import icon_plus from './assets/plus.svg';
import icon_minus from './assets/minus.svg';
import icon_autoRotate from './assets/autoRotate.svg';
import icon_arrows_in from './assets/arrowsIn.svg';
import icon_back from './assets/arrowCounterClockwise.svg';

function Player({  productName, selectedProductCode  }) {
  const emersyaIframeRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [viewerActive, setViewerActive] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.emersya.com/f/emersyaLoader.js';
    script.id = 'emersya';

    script.setAttribute('routing', selectedProductCode);
    script.setAttribute('containerId', 'emersyaIframe');

    document.head.appendChild(script);

    function initializeEmersyaAPI() {
      const emersyaViewer = emViewers['emersyaIframe'];
    }

    document.addEventListener('emersyaViewerInitialized', initializeEmersyaAPI, false);

    return () => {
      document.removeEventListener('emersyaViewerInitialized', initializeEmersyaAPI);
    };
  }, [selectedProductCode]);

  const zoomIn = useCallback(() => {
   
      const emersyaViewer = emViewers['emersyaIframe'];
  
      
      // Consider adding a promise-based approach like in the Emersya docs:
      emersyaViewer.startZoomIn()
        .then(() => {
          setTimeout(() => { 
            emersyaViewer.stopZoomIn(); 
          }, 800);
        })

  }, [viewerActive]);

  const zoomOut = useCallback(() => {
    const emersyaViewer = emViewers['emersyaIframe'];
    emersyaViewer.startZoomOut();
    setTimeout(() => {
      emersyaViewer.stopZoomOut();
    }, 600);
  
  }, []);

  
  const playRotation = useCallback(() => {
    const emersyaViewer = emViewers['emersyaIframe'];
    emersyaViewer.play()
  
  }, []);

    

  // Reset Camera
  const resetCamera = useCallback(() => {
    const emersyaViewer = emViewers['emersyaIframe'];
    emersyaViewer.resetCamera({}); 
    emersyaViewer.play()
  }, []);

  // Fullscreen Functions
  const openFullscreen = useCallback(() => {
    const element = document.querySelector(".emersya_block"); // Adjust if needed
    const arrowOut = document.querySelector(".arrow_out"); // Adjust if needed
    const arrowIn = document.querySelector(".arrow_in"); // Adjust if needed

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) { /* Safari */
      element.webkitRequestFullscreen();
    }  else if (element.msRequestFullscreen) { /* IE11 */
      element.msRequestFullscreen();
    }

    arrowOut.classList.add("hidden");
    arrowIn.classList.remove("hidden");
    setIsFullscreen(true);
  }, []);

  const closeFullscreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen();
    }

    // ... update arrow visibility ...
    setIsFullscreen(false);
  }, []);


  // Screenshot Functionality
  function downloadBase64File(contentBase64, fileName) {
    const downloadLink = document.createElement('a');
    downloadLink.href = contentBase64;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink); 
    downloadLink.click(); 
    document.body.removeChild(downloadLink); // Cleanup
  }
  
  const getScreenshot = useCallback(() => {
    const emersyaViewer = emViewers['emersyaIframe'];
    const date = new Date();
    const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    emersyaViewer.getCamera()
        .then(cameraData => {
            emersyaViewer.getScreenshot({
                width: 1024,
                height: 1024,
                transparentBackground: false,
                camera: cameraData 
            })
            .then(imageData => {
                downloadBase64File(imageData, `${formattedDate}-${productName}.png`);
            });
        });
  }, [productName]); // Add productName dependency

  


  return (
    <div className="ay-col-span-4 md:ay-col-span-3 md:ay-sticky ay-top-0 ay-h-fit ay-relative">
      {/* ... Your other JSX elements */}
      <div className="ay-z-10 ay-absolute ay-left-5 ay-bottom-5 ay-flex ay-flex-col ay-gap-3">
        <img onClick={playRotation} className="hover:ay-cursor-pointer" src={icon_autoRotate} alt=""/>
        <img onClick={zoomIn} className="hover:ay-cursor-pointer" src={icon_plus} alt=""/>
        <img onClick={zoomOut} className="hover:ay-cursor-pointer" src={icon_minus} alt=""/>
        <img onClick={resetCamera} className="hover:ay-cursor-pointer" src={icon_back} alt=""/>
        <img onClick={getScreenshot} className="hover:ay-cursor-pointer" src={icon_camera} alt=""/>
      </div>
    <div className="ay-h-[60vh] md:ay-h-screen ay-w-full ay-relative" id="emersyaIframe"></div>
    </div>
  );
}

export default Player;