
import remix_0113 from '../assets/catSuper/Remix3/0113.jpg'
import remix_0123 from '../assets/catSuper/Remix3/0123.jpg'
import remix_0133 from '../assets/catSuper/Remix3/0133.jpg'
import remix_0143 from '../assets/catSuper/Remix3/0143.jpg'
import remix_0152 from '../assets/catSuper/Remix3/0152.jpg'
import remix_0163 from '../assets/catSuper/Remix3/0163.jpg'
import remix_0173 from '../assets/catSuper/Remix3/0173.jpg'
import remix_0183 from '../assets/catSuper/Remix3/0183.jpg'
import remix_0223 from '../assets/catSuper/Remix3/0223.jpg'
import remix_0233 from '../assets/catSuper/Remix3/0233.jpg'
import remix_0242 from '../assets/catSuper/Remix3/0242.jpg'
import remix_0252 from '../assets/catSuper/Remix3/0252.jpg'
import remix_0362 from '../assets/catSuper/Remix3/0362.jpg'
import remix_0373 from '../assets/catSuper/Remix3/0373.jpg'
import remix_0383 from '../assets/catSuper/Remix3/0383.jpg'
import remix_0393 from '../assets/catSuper/Remix3/0393.jpg'
import remix_0412 from '../assets/catSuper/Remix3/0412.jpg'
import remix_0422 from '../assets/catSuper/Remix3/0422.jpg'
import remix_0433 from '../assets/catSuper/Remix3/0433.jpg'
import remix_0443 from '../assets/catSuper/Remix3/0443.jpg'
import remix_0452 from '../assets/catSuper/Remix3/0452.jpg'
/* import remix_0453 from '../assets/catSuper/Remix3/0453.jpg' */
import remix_0612 from '../assets/catSuper/Remix3/0612.jpg'
import remix_0632 from '../assets/catSuper/Remix3/0632.jpg'
import remix_0643 from '../assets/catSuper/Remix3/0643.jpg'
import remix_0653 from '../assets/catSuper/Remix3/0653.jpg'
import remix_0662 from '../assets/catSuper/Remix3/0662.jpg'
import remix_0672 from '../assets/catSuper/Remix3/0672.jpg'
import remix_0682 from '../assets/catSuper/Remix3/0682.jpg'
import remix_0692 from '../assets/catSuper/Remix3/0692.jpg'
import remix_0722 from '../assets/catSuper/Remix3/0722.jpg'
import remix_0733 from '../assets/catSuper/Remix3/0733.jpg'
import remix_0743 from '../assets/catSuper/Remix3/0743.jpg'
import remix_0762 from '../assets/catSuper/Remix3/0762.jpg'
import remix_0773 from '../assets/catSuper/Remix3/0773.jpg'
import remix_0783 from '../assets/catSuper/Remix3/0783.jpg'
import remix_0823 from '../assets/catSuper/Remix3/0823.jpg'
import remix_0842 from '../assets/catSuper/Remix3/0842.jpg'
import remix_0852 from '../assets/catSuper/Remix3/0852.jpg'
import remix_0873 from '../assets/catSuper/Remix3/0873.jpg'
import remix_0912 from '../assets/catSuper/Remix3/0912.jpg'
import remix_0923 from '../assets/catSuper/Remix3/0923.jpg'
import remix_0933 from '../assets/catSuper/Remix3/0933.jpg'
import remix_0942 from '../assets/catSuper/Remix3/0942.jpg'
import remix_0954 from '../assets/catSuper/Remix3/0954.jpg'
import remix_0962 from '../assets/catSuper/Remix3/0962.jpg'
import remix_0973 from '../assets/catSuper/Remix3/0973.jpg'
import remix_0982 from '../assets/catSuper/Remix3/0982.jpg'
/* import remix_0383 from '../assets/catSuper/Remix3/0383.jpg' */

import terraskin_103 from '../assets/catSuper/Teraskin/Terraskin-Color-103.png'
import terraskin_105 from '../assets/catSuper/Teraskin/Terraskin-Color-105.png'
import terraskin_111 from '../assets/catSuper/Teraskin/Terraskin-Color-111.png'
import terraskin_200 from '../assets/catSuper/Teraskin/Terraskin-Color-200.png'
import terraskin_302 from '../assets/catSuper/Teraskin/Terraskin-Color-302.png'
import terraskin_307 from '../assets/catSuper/Teraskin/Terraskin-Color-307.png'
import terraskin_400 from '../assets/catSuper/Teraskin/Terraskin-Color-400.png'
import terraskin_403 from '../assets/catSuper/Teraskin/Terraskin-Color-403.png'
import terraskin_405 from '../assets/catSuper/Teraskin/Terraskin-Color-405.png'
import terraskin_408 from '../assets/catSuper/Teraskin/Terraskin-Color-408.png'
import terraskin_607 from '../assets/catSuper/Teraskin/Terraskin-Color-607.png'
import terraskin_700 from '../assets/catSuper/Teraskin/Terraskin-Color-700.png'
import terraskin_701 from '../assets/catSuper/Teraskin/Terraskin-Color-701.png'
import terraskin_708 from '../assets/catSuper/Teraskin/Terraskin-Color-708.png'
import terraskin_801 from '../assets/catSuper/Teraskin/Terraskin-Color-801.png'
import terraskin_810 from '../assets/catSuper/Teraskin/Terraskin-Color-810.png'

import foster_01 from '../assets/catSuper/Foster/7072.01_highres.jpg'
import foster_02 from '../assets/catSuper/Foster/7072.02_highres.jpg'
import foster_03 from '../assets/catSuper/Foster/7072.03_highres.jpg'
import foster_04 from '../assets/catSuper/Foster/7072.04_highres.jpg'
import foster_05 from '../assets/catSuper/Foster/7072.05_highres.jpg'
import foster_06 from '../assets/catSuper/Foster/7072.06_highres.jpg'
import foster_07 from '../assets/catSuper/Foster/7072.07_highres.jpg'
import foster_08 from '../assets/catSuper/Foster/7072.08_highres.jpg'
import foster_09 from '../assets/catSuper/Foster/7072.09_highres.jpg'
import foster_10 from '../assets/catSuper/Foster/7072.10_highres.jpg'
import foster_11 from '../assets/catSuper/Foster/7072.11_highres.jpg'
import foster_12 from '../assets/catSuper/Foster/7072.12_highres.jpg'
import foster_13 from '../assets/catSuper/Foster/7072.13_highres.jpg'
import foster_14 from '../assets/catSuper/Foster/7072.14_highres.jpg'
import foster_15 from '../assets/catSuper/Foster/7072.15_highres.jpg'
import foster_16 from '../assets/catSuper/Foster/7072.16_highres.jpg'
import foster_17 from '../assets/catSuper/Foster/7072.17_highres.jpg'
import foster_18 from '../assets/catSuper/Foster/7072.18_highres.jpg'
import foster_19 from '../assets/catSuper/Foster/7072.19_highres.jpg'
import foster_20 from '../assets/catSuper/Foster/7072.20_highres.jpg'
import foster_21 from '../assets/catSuper/Foster/7072.21_highres.jpg'
import foster_22 from '../assets/catSuper/Foster/7072.22_highres.jpg'
import foster_23 from '../assets/catSuper/Foster/7072.23_highres.jpg'
import foster_24 from '../assets/catSuper/Foster/7072.24_highres.jpg'
import foster_25 from '../assets/catSuper/Foster/7072.25_highres.jpg'
import foster_26 from '../assets/catSuper/Foster/7072.26_highres.jpg'
import foster_27 from '../assets/catSuper/Foster/7072.27_highres.jpg'

const catSuper = [
  {
    category: 'cat. Super',
    collection: 'Foster',
    items: [
      {
        name: "FOSTER 01",
        slug: "FOSTER 01",
        thumbnail: foster_01,
      },
      {
        name: "FOSTER 02",
        slug: "FOSTER 02",
        thumbnail: foster_02,
      },
      {
        name: "FOSTER 03",
        slug: "FOSTER 03",
        thumbnail: foster_03,
      },
      {
        name: "FOSTER 04",
        slug: "FOSTER 04",
        thumbnail: foster_04,
      },
      {
        name: "FOSTER 05",
        slug: "FOSTER 05",
        thumbnail: foster_05,
      },
      {
        name: "FOSTER 06",
        slug: "FOSTER 06",
        thumbnail: foster_06,
      },
      {
        name: "FOSTER 07",
        slug: "FOSTER 07",
        thumbnail: foster_07,
      },
      {
        name: "FOSTER 08",
        slug: "FOSTER 08",
        thumbnail: foster_08,
      },
      {
        name: "FOSTER 09",
        slug: "FOSTER 09",
        thumbnail: foster_09,
      },
      {
        name: "FOSTER 10",
        slug: "FOSTER 10",
        thumbnail: foster_10,
      },
      {
        name: "FOSTER 11",
        slug: "FOSTER 11",
        thumbnail: foster_11,
      },
      {
        name: "FOSTER 12",
        slug: "FOSTER 12",
        thumbnail: foster_12,
      },
      {
        name: "FOSTER 13",
        slug: "FOSTER 13",
        thumbnail: foster_13,
      },
   /*    {
        name: "FOSTER 14",
        slug: "FOSTER 14",
        thumbnail: foster_14,
      }, */
      {
        name: "FOSTER 15",
        slug: "FOSTER 15",
        thumbnail: foster_15,
      },
      {
        name: "FOSTER 16",
        slug: "FOSTER 16",
        thumbnail: foster_16,
      },
      /* {
        name: "FOSTER 17",
        slug: "FOSTER 17",
        thumbnail: foster_17,
      }, */
      {
        name: "FOSTER 18",
        slug: "FOSTER 18",
        thumbnail: foster_18,
      },
     /*  {
        name: "FOSTER 19",
        slug: "FOSTER 19",
        thumbnail: foster_19,
      }, 
      {
        name: "FOSTER 20",
        slug: "FOSTER 20",
        thumbnail: foster_20,
      },
      {
        name: "FOSTER 21",
        slug: "FOSTER 21",
        thumbnail: foster_21,
      },
      {
        name: "FOSTER 22",
        slug: "FOSTER 22",
        thumbnail: foster_22,
      },
      {
        name: "FOSTER 23",
        slug: "FOSTER 23",
        thumbnail: foster_23,
      },*/
      {
        name: "FOSTER 24",
        slug: "FOSTER 24",
        thumbnail: foster_24,
      },
      {
        name: "FOSTER 25",
        slug: "FOSTER 25",
        thumbnail: foster_25,
      },
      {
        name: "FOSTER 26",
        slug: "FOSTER 26",
        thumbnail: foster_26,
      },
      /* {
        name: "FOSTER 27",
        slug: "FOSTER 27",
        thumbnail: foster_27,
      }, */
    ]
  },
  {
    category: 'cat. Super',
    collection: 'Terraskin',
    items: [
      /* {
        name: "TERASKIN 103",
        slug: "TERASKIN 103",
        thumbnail: terraskin_103,
      }, */
      {
        name: "TERASKIN 105",
        slug: "TERASKIN 105",
        thumbnail: terraskin_105,
      },
      {
        name: "TERASKIN 111",
        slug: "TERASKIN 111",
        thumbnail: terraskin_111,
      },
      {
        name: "TERASKIN 200",
        slug: "TERASKIN 200",
        thumbnail: terraskin_200,
      },
      {
        name: "TERASKIN 302",
        slug: "TERASKIN 302",
        thumbnail: terraskin_302,
      },
      {
        name: "TERASKIN 307",
        slug: "TERASKIN 307",
        thumbnail: terraskin_307,
      },
      {
        name: "TERASKIN 400",
        slug: "TERASKIN 400",
        thumbnail: terraskin_400,
      },
      {
        name: "TERASKIN 403",
        slug: "TERASKIN 403",
        thumbnail: terraskin_403,
      },
      {
        name: "TERASKIN 405",
        slug: "TERASKIN 405",
        thumbnail: terraskin_405,
      },
      {
        name: "TERASKIN 408",
        slug: "TERASKIN 408",
        thumbnail: terraskin_408,
      },
      {
        name: "TERASKIN 607",
        slug: "TERASKIN 607",
        thumbnail: terraskin_607,
      },
      {
        name: "TERASKIN 700",
        slug: "TERASKIN 700",
        thumbnail: terraskin_700,
      },
      {
        name: "TERASKIN 701",
        slug: "TERASKIN 701",
        thumbnail: terraskin_701,
      },
      {
        name: "TERASKIN 708",
        slug: "TERASKIN 708",
        thumbnail: terraskin_708,
      },
      {
        name: "TERASKIN 801",
        slug: "TERASKIN 801",
        thumbnail: terraskin_801,
      },
      {
        name: "TERASKIN 810",
        slug: "TERASKIN 810",
        thumbnail: terraskin_810,
      },
    ]
  },
  {
    category: 'cat. Super',
    collection: 'Remix 3',
    items: [
      {
        name: "REMIX 3 0113",
        slug: "REMIX 3 0113",
        thumbnail: remix_0113,
      },
      {
        name: "REMIX 3 0123",
        slug: "REMIX 3 0123",
        thumbnail: remix_0123,
      },
      {
        name: "REMIX 3 0133",
        slug: "REMIX 3 0133",
        thumbnail: remix_0133,
      },
      {
        name: "REMIX 3 0143",
        slug: "REMIX 3 0143",
        thumbnail: remix_0143,
      },
      {
        name: "REMIX 3 0152",
        slug: "REMIX 3 0152",
        thumbnail: remix_0152,
      },
      {
        name: "REMIX 3 0163",
        slug: "REMIX 3 0163",
        thumbnail: remix_0163,
      },
      {
        name: "REMIX 3 0173",
        slug: "REMIX 3 0173",
        thumbnail: remix_0173,
      },
      {
        name: "REMIX 3 0183",
        slug: "REMIX 3 0183",
        thumbnail: remix_0183,
      },
      {
        name: "REMIX 3 0233",
        slug: "REMIX 3 0233",
        thumbnail: remix_0233,
      },
      {
        name: "REMIX 3 0242",
        slug: "REMIX 3 0242",
        thumbnail: remix_0242,
      },
      {
        name: "REMIX 3 0252",
        slug: "REMIX 3 0252",
        thumbnail: remix_0252,
      },
      {
        name: "REMIX 3 0362",
        slug: "REMIX 3 0362",
        thumbnail: remix_0362,
      },
      {
        name: "REMIX 3 0373",
        slug: "REMIX 3 0373",
        thumbnail: remix_0373,
      },
      {
        name: "REMIX 3 0383",
        slug: "REMIX 3 0383",
        thumbnail: remix_0383,
      },
      {
        name: "REMIX 3 0393",
        slug: "REMIX 3 0393",
        thumbnail: remix_0393,
      },
      {
        name: "REMIX 3 0412",
        slug: "REMIX 3 0412",
        thumbnail: remix_0412,
      },
      {
        name: "REMIX 3 0422",
        slug: "REMIX 3 0422",
        thumbnail: remix_0422,
      },
      {
        name: "REMIX 3 0433",
        slug: "REMIX 3 0433",
        thumbnail: remix_0433,
      },
      {
        name: "REMIX 3 0443",
        slug: "REMIX 3 0443",
        thumbnail: remix_0443,
      },
      {
        name: "REMIX 3 0452",
        slug: "REMIX 3 0452",
        thumbnail: remix_0452,
      },
     /*  {
        name: "REMIX 3 0453",
        slug: "REMIX 3 0453",
        thumbnail: remix_0453,
      }, */
      {
        name: "REMIX 3 0612",
        slug: "REMIX 3 0612",
        thumbnail: remix_0612,
      },
      {
        name: "REMIX 3 0632",
        slug: "REMIX 3 0632",
        thumbnail: remix_0632,
      },
      {
        name: "REMIX 3 0643",
        slug: "REMIX 3 0643",
        thumbnail: remix_0643,
      },
      {
        name: "REMIX 3 0653",
        slug: "REMIX 3 0653",
        thumbnail: remix_0653,
      },
      {
        name: "REMIX 3 0662",
        slug: "REMIX 3 0662",
        thumbnail: remix_0662,
      },
      {
        name: "REMIX 3 0672",
        slug: "REMIX 3 0672",
        thumbnail: remix_0672,
      },
      {
        name: "REMIX 3 0682",
        slug: "REMIX 3 0682",
        thumbnail: remix_0682,
      },
      {
        name: "REMIX 3 0692",
        slug: "REMIX 3 0692",
        thumbnail: remix_0692,
      },
      {
        name: "REMIX 3 0722",
        slug: "REMIX 3 0722",
        thumbnail: remix_0722,
      },
      {
        name: "REMIX 3 0733",
        slug: "REMIX 3 0733",
        thumbnail: remix_0733,
      },
      {
        name: "REMIX 3 0743",
        slug: "REMIX 3 0743",
        thumbnail: remix_0743,
      },
      {
        name: "REMIX 3 0762",
        slug: "REMIX 3 0762",
        thumbnail: remix_0762,
      },
      {
        name: "REMIX 3 0773",
        slug: "REMIX 3 0773",
        thumbnail: remix_0773,
      },
      {
        name: "REMIX 3 0783",
        slug: "REMIX 3 0783",
        thumbnail: remix_0783,
      },
      {
        name: "REMIX 3 0823",
        slug: "REMIX 3 0823",
        thumbnail: remix_0823,
      },
      {
        name: "REMIX 3 0842",
        slug: "REMIX 3 0842",
        thumbnail: remix_0842,
      },
      {
        name: "REMIX 3 0852",
        slug: "REMIX 3 0852",
        thumbnail: remix_0852,
      },
      {
        name: "REMIX 3 0873",
        slug: "REMIX 3 0873",
        thumbnail: remix_0873,
      },
      {
        name: "REMIX 3 0912",
        slug: "REMIX 3 0912",
        thumbnail: remix_0912,
      },
      {
        name: "REMIX 3 0923",
        slug: "REMIX 3 0923",
        thumbnail: remix_0923,
      },
      {
        name: "REMIX 3 0933",
        slug: "REMIX 3 0933",
        thumbnail: remix_0933,
      },
      {
        name: "REMIX 3 0942",
        slug: "REMIX 3 0942",
        thumbnail: remix_0942,
      },
      {
        name: "REMIX 3 0954",
        slug: "REMIX 3 0954",
        thumbnail: remix_0954,
      },
      {
        name: "REMIX 3 0962",
        slug: "REMIX 3 0962",
        thumbnail: remix_0962,
      },
      {
        name: "REMIX 3 0973",
        slug: "REMIX 3 0973",
        thumbnail: remix_0973,
      },
      {
        name: "REMIX 3 0982",
        slug: "REMIX 3 0982",
        thumbnail: remix_0982,
      },
    /*   {
        name: "REMIX 3 0383",
        slug: "REMIX 3 0383",
        thumbnail: remix_0383,
      }, */
    ]
  },
  {
    category: 'cat. Super',
    collection: 'Orsetto',
    items: [
      { name: "ORSETTO 01-1", slug: "ORSETTO 01-1", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 01-2", slug: "ORSETTO 01-2", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 02-26", slug: "ORSETTO 02-26", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 05-10", slug: "ORSETTO 05-10", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 05-121", slug: "ORSETTO 05-121", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 05-6", slug: "ORSETTO 05-6", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 05-9", slug: "ORSETTO 05-9", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 07-11", slug: "ORSETTO 07-11", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 07-24", slug: "ORSETTO 07-24", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 09-64", slug: "ORSETTO 09-64", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 09-92", slug: "ORSETTO 09-92", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 2-22", slug: "ORSETTO 2-22", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 39-128", slug: "ORSETTO 39-128", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 9-12", slug: "ORSETTO 9-12", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 9-23", slug: "ORSETTO 9-23", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 98-125", slug: "ORSETTO 98-125", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 98-16", slug: "ORSETTO 98-16", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 98-18", slug: "ORSETTO 98-18", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 98-20", slug: "ORSETTO 98-20", thumbnail: '../assets/placeholder.jpg' },
      { name: "ORSETTO 98-21", slug: "ORSETTO 98-21", thumbnail: '../assets/placeholder.jpg' },

    ]
  },
  {
    category: 'cat. Super',
    collection: 'Amelia',
    items: [
      { name: "AMELIA 100", slug: "AMELIA 100", thumbnail: '../assets/placeholder.jpg' },
      { name: "AMELIA 401", slug: "AMELIA 401", thumbnail: '../assets/placeholder.jpg' },
      { name: "AMELIA 602", slug: "AMELIA 602", thumbnail: '../assets/placeholder.jpg' },
      { name: "AMELIA 610", slug: "AMELIA 610", thumbnail: '../assets/placeholder.jpg' },
      { name: "AMELIA 701", slug: "AMELIA 701", thumbnail: '../assets/placeholder.jpg' },
      { name: "AMELIA 706", slug: "AMELIA 706", thumbnail: '../assets/placeholder.jpg' },
      { name: "AMELIA 711", slug: "AMELIA 711", thumbnail: '../assets/placeholder.jpg' },
    ]
  }
 ]

export default catSuper