import catA from './collections/catA'
import catB from './collections/catB'
import catC from './collections/catC'
import catExtra from './collections/catExtra'
import catImperial from './collections/catImperial'
import catJaak from './collections/catJaak'
import catLux from './collections/catLux'
import catMaxi from './collections/catMaxi'
import catSaddleLeather from './collections/catSaddleLeather'
import catSpecial from './collections/catSpecial'
import catSuper from './collections/catSuper'
import catTop from './collections/catTop'


const materiali = catA.
concat(catB).
concat(catC).
concat(catSuper).
concat(catExtra).
concat(catMaxi).
concat(catTop).
concat(catSpecial).
concat(catJaak).
concat(catLux).
concat(catImperial).concat(catSaddleLeather)

// .concat(catSaddleLeather) non mi funziona




export default materiali