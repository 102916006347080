import imperial1_0504 from '../assets/catImperial/Imperial2/0504.jpg'
import imperial1_0505 from '../assets/catImperial/Imperial2/0505.jpg'
import imperial1_0506 from '../assets/catImperial/Imperial2/0506.jpg'
import imperial1_0507 from '../assets/catImperial/Imperial2/0507.jpg'
import imperial1_0508 from '../assets/catImperial/Imperial2/0508.jpg'
import imperial1_0509 from '../assets/catImperial/Imperial2/0509.jpg'
import imperial1_0510 from '../assets/catImperial/Imperial2/0510.jpg'
import imperial1_0512 from '../assets/catImperial/Imperial2/0512.jpg'
import imperial1_0522 from '../assets/catImperial/Imperial2/0522.jpg'
import imperial1_0532 from '../assets/catImperial/Imperial2/0532.jpg'
import imperial1_0533 from '../assets/catImperial/Imperial2/0533.jpg'
import imperial1_0534 from '../assets/catImperial/Imperial2/0534.jpg'
import imperial1_0541 from '../assets/catImperial/Imperial2/0541.jpg'
import imperial1_0543 from '../assets/catImperial/Imperial2/0543.jpg'
import imperial1_0554 from '../assets/catImperial/Imperial2/0554.jpg'
import imperial1_0556 from '../assets/catImperial/Imperial2/0556.jpg'
import imperial1_0557 from '../assets/catImperial/Imperial2/0557.jpg'
import imperial1_0558 from '../assets/catImperial/Imperial2/0558.jpg'
import imperial1_0559 from '../assets/catImperial/Imperial2/0559.jpg'
import imperial1_0560 from '../assets/catImperial/Imperial2/0560.jpg'
import imperial1_0563 from '../assets/catImperial/Imperial2/0563.jpg'
import imperial1_0564 from '../assets/catImperial/Imperial2/0564.jpg'
import imperial1_0565 from '../assets/catImperial/Imperial2/0565.jpg'
import imperial1_0566 from '../assets/catImperial/Imperial2/0566.jpg'
import imperial1_0569 from '../assets/catImperial/Imperial2/0569.jpg'
import imperial1_0574 from '../assets/catImperial/Imperial2/0574.jpg'
import imperial1_0576 from '../assets/catImperial/Imperial2/0576.jpg'
import imperial1_0597 from '../assets/catImperial/Imperial2/0597.jpg'
import imperial1_N040 from '../assets/catImperial/Imperial2/N040.jpg'
import imperial1_N041 from '../assets/catImperial/Imperial2/N041.jpg'
import imperial1_N047 from '../assets/catImperial/Imperial2/N047.jpg'
import imperial1_N053 from '../assets/catImperial/Imperial2/N053.jpg'
import imperial1_N079 from '../assets/catImperial/Imperial2/N079.jpg'
import imperial1_N092 from '../assets/catImperial/Imperial2/N092.jpg'
import imperial1_NH03 from '../assets/catImperial/Imperial2/NH03.jpg'



import imperial1_0701 from '../assets/catImperial/Imperial1/0701.jpg';
import imperial1_0703 from '../assets/catImperial/Imperial1/0703.jpg';
import imperial1_0704 from '../assets/catImperial/Imperial1/0704.jpg';
import imperial1_0708 from '../assets/catImperial/Imperial1/0708.jpg';
import imperial1_0709 from '../assets/catImperial/Imperial1/0709.jpg';
import imperial1_0715 from '../assets/catImperial/Imperial1/0715.jpg';
import imperial1_0720 from '../assets/catImperial/Imperial1/0720.jpg';
import imperial1_0721 from '../assets/catImperial/Imperial1/0721.jpg';
import imperial1_0722 from '../assets/catImperial/Imperial1/0722.jpg';
import imperial1_0724 from '../assets/catImperial/Imperial1/0724.jpg';
import imperial1_0725 from '../assets/catImperial/Imperial1/0725.jpg';
import imperial1_0727 from '../assets/catImperial/Imperial1/0727.jpg';
import imperial1_0728 from '../assets/catImperial/Imperial1/0728.jpg';
import imperial1_0729 from '../assets/catImperial/Imperial1/0729.jpg';
import imperial1_0730 from '../assets/catImperial/Imperial1/0730.jpg';
import imperial1_0731 from '../assets/catImperial/Imperial1/0731.jpg';
import imperial1_0732 from '../assets/catImperial/Imperial1/0732.jpg';
import imperial1_0734 from '../assets/catImperial/Imperial1/0734.jpg';
import imperial1_0735 from '../assets/catImperial/Imperial1/0735.jpg';
import imperial1_0737 from '../assets/catImperial/Imperial1/0737.jpg';
import imperial1_0741 from '../assets/catImperial/Imperial1/0741.jpg';
import imperial1_0746 from '../assets/catImperial/Imperial1/0746.jpg';
import imperial1_0747 from '../assets/catImperial/Imperial1/0747.jpg';
import imperial1_0749 from '../assets/catImperial/Imperial1/0749.jpg';
import imperial1_0755 from '../assets/catImperial/Imperial1/0755.jpg';
import imperial1_0763 from '../assets/catImperial/Imperial1/0763.jpg';
import imperial1_0764 from '../assets/catImperial/Imperial1/0764.jpg';
import imperial1_0766 from '../assets/catImperial/Imperial1/0766.jpg';
import imperial1_0769 from '../assets/catImperial/Imperial1/0769.jpg';
import imperial1_0771 from '../assets/catImperial/Imperial1/0771.jpg';
import imperial1_0775 from '../assets/catImperial/Imperial1/0775.jpg';
import imperial1_0776 from '../assets/catImperial/Imperial1/0776.jpg';
import imperial1_0778 from '../assets/catImperial/Imperial1/0778.jpg';
import imperial1_0780 from '../assets/catImperial/Imperial1/0780.jpg';
import imperial1_0781 from '../assets/catImperial/Imperial1/0781.jpg';
import imperial1_0782 from '../assets/catImperial/Imperial1/0782.jpg';



const catImperial = [
  
  {
    category: 'cat. Imperial',
    collection: 'Imperial 1',
    items: [
      { slug: "IMPERIAL 1 0701", name: "IMPERIAL 1 0701", thumbnail: imperial1_0701 },
      { slug: "IMPERIAL 1 0703", name: "IMPERIAL 1 0703", thumbnail: imperial1_0703 },
      { slug: "IMPERIAL 1 0704", name: "IMPERIAL 1 0704", thumbnail: imperial1_0704 },
      { slug: "IMPERIAL 1 0708", name: "IMPERIAL 1 0708", thumbnail: imperial1_0708 },
      { slug: "IMPERIAL 1 0709", name: "IMPERIAL 1 0709", thumbnail: imperial1_0709 },
      { slug: "IMPERIAL 1 0715", name: "IMPERIAL 1 0715", thumbnail: imperial1_0715 },
      { slug: "IMPERIAL 1 0720", name: "IMPERIAL 1 0720", thumbnail: imperial1_0720 },
      { slug: "IMPERIAL 1 0721", name: "IMPERIAL 1 0721", thumbnail: imperial1_0721 },
      { slug: "IMPERIAL 1 0722", name: "IMPERIAL 1 0722", thumbnail: imperial1_0722 },
      { slug: "IMPERIAL 1 0724", name: "IMPERIAL 1 0724", thumbnail: imperial1_0724 },
      { slug: "IMPERIAL 1 0725", name: "IMPERIAL 1 0725", thumbnail: imperial1_0725 },
      { slug: "IMPERIAL 1 0727", name: "IMPERIAL 1 0727", thumbnail: imperial1_0727 },
      { slug: "IMPERIAL 1 0728", name: "IMPERIAL 1 0728", thumbnail: imperial1_0728 },
      { slug: "IMPERIAL 1 0729", name: "IMPERIAL 1 0729", thumbnail: imperial1_0729 },
      { slug: "IMPERIAL 1 0730", name: "IMPERIAL 1 0730", thumbnail: imperial1_0730 },
      { slug: "IMPERIAL 1 0731", name: "IMPERIAL 1 0731", thumbnail: imperial1_0731 },
      { slug: "IMPERIAL 1 0732", name: "IMPERIAL 1 0732", thumbnail: imperial1_0732 },
      { slug: "IMPERIAL 1 0734", name: "IMPERIAL 1 0734", thumbnail: imperial1_0734 },
      { slug: "IMPERIAL 1 0735", name: "IMPERIAL 1 0735", thumbnail: imperial1_0735 },
      { slug: "IMPERIAL 1 0737", name: "IMPERIAL 1 0737", thumbnail: imperial1_0737 },
      { slug: "IMPERIAL 1 0741", name: "IMPERIAL 1 0741", thumbnail: imperial1_0741 },
      { slug: "IMPERIAL 1 0746", name: "IMPERIAL 1 0746", thumbnail: imperial1_0746 },
      { slug: "IMPERIAL 1 0747", name: "IMPERIAL 1 0747", thumbnail: imperial1_0747 },
      { slug: "IMPERIAL 1 0749", name: "IMPERIAL 1 0749", thumbnail: imperial1_0749 },
      { slug: "IMPERIAL 1 0755", name: "IMPERIAL 1 0755", thumbnail: imperial1_0755 },
      { slug: "IMPERIAL 1 0763", name: "IMPERIAL 1 0763", thumbnail: imperial1_0763 },
      { slug: "IMPERIAL 1 0764", name: "IMPERIAL 1 0764", thumbnail: imperial1_0764 },
      { slug: "IMPERIAL 1 0766", name: "IMPERIAL 1 0766", thumbnail: imperial1_0766 },
      { slug: "IMPERIAL 1 0769", name: "IMPERIAL 1 0769", thumbnail: imperial1_0769 },
      { slug: "IMPERIAL 1 0771", name: "IMPERIAL 1 0771", thumbnail: imperial1_0771 },
      { slug: "IMPERIAL 1 0775", name: "IMPERIAL 1 0775", thumbnail: imperial1_0775 },
      { slug: "IMPERIAL 1 0776", name: "IMPERIAL 1 0776", thumbnail: imperial1_0776 },
      { slug: "IMPERIAL 1 0778", name: "IMPERIAL 1 0778", thumbnail: imperial1_0778 },
      { slug: "IMPERIAL 1 0780", name: "IMPERIAL 1 0780", thumbnail: imperial1_0780 },
      { slug: "IMPERIAL 1 0781", name: "IMPERIAL 1 0781", thumbnail: imperial1_0781 },
      { slug: "IMPERIAL 1 0782", name: "IMPERIAL 1 0782", thumbnail: imperial1_0782 },
    ]
  },
  {
    category: 'cat. Imperial',
    collection: 'Imperial 2',
    items: [
      { slug: "IMPERIAL 2 0504", name: "IMPERIAL 2 0504", thumbnail: imperial1_0504 },
      { slug: "IMPERIAL 2 0505", name: "IMPERIAL 2 0505", thumbnail: imperial1_0505 },
      { slug: "IMPERIAL 2 0506", name: "IMPERIAL 2 0506", thumbnail: imperial1_0506 },
      { slug: "IMPERIAL 2 0507", name: "IMPERIAL 2 0507", thumbnail: imperial1_0507 },
      { slug: "IMPERIAL 2 0508", name: "IMPERIAL 2 0508", thumbnail: imperial1_0508 },
      { slug: "IMPERIAL 2 0509", name: "IMPERIAL 2 0509", thumbnail: imperial1_0509 },
      { slug: "IMPERIAL 2 0510", name: "IMPERIAL 2 0510", thumbnail: imperial1_0510 },
      { slug: "IMPERIAL 2 0512", name: "IMPERIAL 2 0512", thumbnail: imperial1_0512 },
      { slug: "IMPERIAL 2 0522", name: "IMPERIAL 2 0522", thumbnail: imperial1_0522 },
      { slug: "IMPERIAL 2 0532", name: "IMPERIAL 2 0532", thumbnail: imperial1_0532 },
      { slug: "IMPERIAL 2 0533", name: "IMPERIAL 2 0533", thumbnail: imperial1_0533 },
      { slug: "IMPERIAL 2 0534", name: "IMPERIAL 2 0534", thumbnail: imperial1_0534 },
      { slug: "IMPERIAL 2 0541", name: "IMPERIAL 2 0541", thumbnail: imperial1_0541 },
      { slug: "IMPERIAL 2 0543", name: "IMPERIAL 2 0543", thumbnail: imperial1_0543 },
      { slug: "IMPERIAL 2 0554", name: "IMPERIAL 2 0554", thumbnail: imperial1_0554 },
      { slug: "IMPERIAL 2 0556", name: "IMPERIAL 2 0556", thumbnail: imperial1_0556 },
      { slug: "IMPERIAL 2 0557", name: "IMPERIAL 2 0557", thumbnail: imperial1_0557 },
      { slug: "IMPERIAL 2 0558", name: "IMPERIAL 2 0558", thumbnail: imperial1_0558 },
      { slug: "IMPERIAL 2 0559", name: "IMPERIAL 2 0559", thumbnail: imperial1_0559 },
      { slug: "IMPERIAL 2 0560", name: "IMPERIAL 2 0560", thumbnail: imperial1_0560 },
      { slug: "IMPERIAL 2 0563", name: "IMPERIAL 2 0563", thumbnail: imperial1_0563 },
      { slug: "IMPERIAL 2 0564", name: "IMPERIAL 2 0564", thumbnail: imperial1_0564 },
      { slug: "IMPERIAL 2 0565", name: "IMPERIAL 2 0565", thumbnail: imperial1_0565 },
      { slug: "IMPERIAL 2 0566", name: "IMPERIAL 2 0566", thumbnail: imperial1_0566 },
      { slug: "IMPERIAL 2 0569", name: "IMPERIAL 2 0569", thumbnail: imperial1_0569 },
      { slug: "IMPERIAL 2 0574", name: "IMPERIAL 2 0574", thumbnail: imperial1_0574 },
      { slug: "IMPERIAL 2 0576", name: "IMPERIAL 2 0576", thumbnail: imperial1_0576 },
      { slug: "IMPERIAL 2 0597", name: "IMPERIAL 2 0597", thumbnail: imperial1_0597 },
      { slug: "IMPERIAL 2 N040", name: "IMPERIAL 2 N040", thumbnail: imperial1_N040 },
      { slug: "IMPERIAL 2 N041", name: "IMPERIAL 2 N041", thumbnail: imperial1_N041 },
      { slug: "IMPERIAL 2 N047", name: "IMPERIAL 2 N047", thumbnail: imperial1_N047 },
      { slug: "IMPERIAL 2 N053", name: "IMPERIAL 2 N053", thumbnail: imperial1_N053 },
      { slug: "IMPERIAL 2 N079", name: "IMPERIAL 2 N079", thumbnail: imperial1_N079 },
      { slug: "IMPERIAL 2 N092", name: "IMPERIAL 2 N092", thumbnail: imperial1_N092 },
      { slug: "IMPERIAL 2 NH03", name: "IMPERIAL 2 NH03", thumbnail: imperial1_NH03 },
    ]
  }
]

export default catImperial