

import placeholder from '../assets/placeholder.jpg';
import saddleBlack from '../assets/catSaddle/saddle-black.jpg'
import saddleBrown from '../assets/catSaddle/saddle-brown.jpg'
import saddleNatural from '../assets/catSaddle/saddle-natural.jpg'

const catSaddleLeather = [
  
  {
    category: 'cat. Saddle Leather',
    collection: 'Saddle Leather',
    items: [
    { slug: " SADDLE LEATHER  BLACK", name: "SADDLE LEATHER BLACK", thumbnail: saddleBlack },
    { slug: " SADDLE LEATHER NATURAL", name: "SADDLE LEATHER NATURAL", thumbnail: saddleNatural },
    { slug: " SADDLE LEATHER BROWN", name: "SADDLE LEATHER BROWN", thumbnail: saddleBrown },
    ]
  }
]

export default catSaddleLeather