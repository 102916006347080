import lux2_0810 from '../assets/catLux/lux2/0810.jpg';
import lux2_0811 from '../assets/catLux/lux2/0811.jpg';
import lux2_0812 from '../assets/catLux/lux2/0812.jpg';
import lux2_0815 from '../assets/catLux/lux2/0815.jpg';
import lux2_0818 from '../assets/catLux/lux2/0818.jpg';
import lux2_0819 from '../assets/catLux/lux2/0819.jpg';
import lux2_0820 from '../assets/catLux/lux2/0820.jpg';
import lux2_0822 from '../assets/catLux/lux2/0822.jpg';
import lux2_0823 from '../assets/catLux/lux2/0823.jpg';
import lux2_0824 from '../assets/catLux/lux2/0824.jpg';
import lux2_0825 from '../assets/catLux/lux2/0825.jpg';
import lux2_0826 from '../assets/catLux/lux2/0826.jpg';
import lux2_0827 from '../assets/catLux/lux2/0827.jpg';
import lux2_0830 from '../assets/catLux/lux2/0830.jpg';
import lux2_0832 from '../assets/catLux/lux2/0832.jpg';
import lux2_0835 from '../assets/catLux/lux2/0835.jpg';
import lux2_0836 from '../assets/catLux/lux2/0836.jpg';
import lux2_0837 from '../assets/catLux/lux2/0837.jpg';
import lux2_0838 from '../assets/catLux/lux2/0838.jpg';
import lux2_0839 from '../assets/catLux/lux2/0839.jpg';
import lux2_0842 from '../assets/catLux/lux2/0842.jpg';
import lux2_0843 from '../assets/catLux/lux2/0843.jpg';
import lux2_0844 from '../assets/catLux/lux2/0844.jpg';
import lux2_0845 from '../assets/catLux/lux2/0845.jpg';
import lux2_0847 from '../assets/catLux/lux2/0847.jpg';
import lux2_0851 from '../assets/catLux/lux2/0851.jpg';
import lux2_0852 from '../assets/catLux/lux2/0852.jpg';
import lux2_0853 from '../assets/catLux/lux2/0853.jpg';
import lux2_0854 from '../assets/catLux/lux2/0854.jpg';
import lux2_0855 from '../assets/catLux/lux2/0855.jpg';
import lux2_0856 from '../assets/catLux/lux2/0856.jpg';
import lux2_0858 from '../assets/catLux/lux2/0858.jpg';
import lux2_0859 from '../assets/catLux/lux2/0859.jpg';
import lux2_0860 from '../assets/catLux/lux2/0860.jpg';
import lux2_0864 from '../assets/catLux/lux2/0864.jpg';
import lux2_0881 from '../assets/catLux/lux2/0881.jpg';

import lux1_0060 from '../assets/catLux/lux1/0060.jpg';
import lux1_0062 from '../assets/catLux/lux1/0062.jpg';
import lux1_0063 from '../assets/catLux/lux1/0063.jpg';
import lux1_0400 from '../assets/catLux/lux1/0400.jpg';
import lux1_0402 from '../assets/catLux/lux1/0402.jpg';
import lux1_0403 from '../assets/catLux/lux1/0403.jpg';
import lux1_0404 from '../assets/catLux/lux1/0404.jpg';
import lux1_0408 from '../assets/catLux/lux1/0408.jpg';
import lux1_0412 from '../assets/catLux/lux1/0412.jpg';
import lux1_0413 from '../assets/catLux/lux1/0413.jpg';
import lux1_0421 from '../assets/catLux/lux1/0421.jpg';
import lux1_0423 from '../assets/catLux/lux1/0423.jpg';
import lux1_0424 from '../assets/catLux/lux1/0424.jpg';
import lux1_0425 from '../assets/catLux/lux1/0425.jpg';
import lux1_0426 from '../assets/catLux/lux1/0426.jpg';
import lux1_0427 from '../assets/catLux/lux1/0427.jpg';
import lux1_0428 from '../assets/catLux/lux1/0428.jpg';
import lux1_0429 from '../assets/catLux/lux1/0429.jpg';
import lux1_0431 from '../assets/catLux/lux1/0431.jpg';
import lux1_0437 from '../assets/catLux/lux1/0437.jpg';
import lux1_0438 from '../assets/catLux/lux1/0438.jpg';
import lux1_0439 from '../assets/catLux/lux1/0439.jpg';
import lux1_0441 from '../assets/catLux/lux1/0441.jpg';
import lux1_0444 from '../assets/catLux/lux1/0444.jpg';
import lux1_0446 from '../assets/catLux/lux1/0446.jpg';
import lux1_0448 from '../assets/catLux/lux1/0448.jpg';
import lux1_0449 from '../assets/catLux/lux1/0449.jpg';
import lux1_0451 from '../assets/catLux/lux1/0451.jpg';
import lux1_0452 from '../assets/catLux/lux1/0452.jpg';
import lux1_0453 from '../assets/catLux/lux1/0453.jpg';
import lux1_0454 from '../assets/catLux/lux1/0454.jpg';
import lux1_0455 from '../assets/catLux/lux1/0455.jpg';
import lux1_0456 from '../assets/catLux/lux1/0456.jpg';
import lux1_0471 from '../assets/catLux/lux1/0471.jpg';
import lux1_0472 from '../assets/catLux/lux1/0472.jpg';
import lux1_0478 from '../assets/catLux/lux1/0478.jpg';


const catLux = [
  
  {
    category: 'cat. Lux',
    collection: 'Lux 1',
    items: [
      { slug: "LUX 1 0060", name: "LUX 1 0060", thumbnail: lux1_0060 },
      { slug: "LUX 1 0062", name: "LUX 1 0062", thumbnail: lux1_0062 },
      { slug: "LUX 1 0063", name: "LUX 1 0063", thumbnail: lux1_0063 },
      { slug: "LUX 1 0400", name: "LUX 1 0400", thumbnail: lux1_0400 },
      { slug: "LUX 1 0402", name: "LUX 1 0402", thumbnail: lux1_0402 },
      { slug: "LUX 1 0403", name: "LUX 1 0403", thumbnail: lux1_0403 },
      { slug: "LUX 1 0404", name: "LUX 1 0404", thumbnail: lux1_0404 },
      { slug: "LUX 1 0408", name: "LUX 1 0408", thumbnail: lux1_0408 },
      { slug: "LUX 1 0412", name: "LUX 1 0412", thumbnail: lux1_0412 },
      { slug: "LUX 1 0413", name: "LUX 1 0413", thumbnail: lux1_0413 },
      { slug: "LUX 1 0421", name: "LUX 1 0421", thumbnail: lux1_0421 },
      { slug: "LUX 1 0423", name: "LUX 1 0423", thumbnail: lux1_0423 },
      { slug: "LUX 1 0424", name: "LUX 1 0424", thumbnail: lux1_0424 },
      { slug: "LUX 1 0425", name: "LUX 1 0425", thumbnail: lux1_0425 },
      { slug: "LUX 1 0426", name: "LUX 1 0426", thumbnail: lux1_0426 },
      { slug: "LUX 1 0427", name: "LUX 1 0427", thumbnail: lux1_0427 },
      { slug: "LUX 1 0428", name: "LUX 1 0428", thumbnail: lux1_0428 },
      { slug: "LUX 1 0429", name: "LUX 1 0429", thumbnail: lux1_0429 },
      { slug: "LUX 1 0431", name: "LUX 1 0431", thumbnail: lux1_0431 },
      { slug: "LUX 1 0437", name: "LUX 1 0437", thumbnail: lux1_0437 },
      { slug: "LUX 1 0438", name: "LUX 1 0438", thumbnail: lux1_0438 },
      { slug: "LUX 1 0439", name: "LUX 1 0439", thumbnail: lux1_0439 },
      { slug: "LUX 1 0441", name: "LUX 1 0441", thumbnail: lux1_0441 },
      { slug: "LUX 1 0444", name: "LUX 1 0444", thumbnail: lux1_0444 },
      { slug: "LUX 1 0446", name: "LUX 1 0446", thumbnail: lux1_0446 },
      { slug: "LUX 1 0448", name: "LUX 1 0448", thumbnail: lux1_0448 },
      { slug: "LUX 1 0449", name: "LUX 1 0449", thumbnail: lux1_0449 },
      { slug: "LUX 1 0451", name: "LUX 1 0451", thumbnail: lux1_0451 },
      { slug: "LUX 1 0452", name: "LUX 1 0452", thumbnail: lux1_0452 },
      { slug: "LUX 1 0453", name: "LUX 1 0453", thumbnail: lux1_0453 },
      { slug: "LUX 1 0454", name: "LUX 1 0454", thumbnail: lux1_0454 },
      { slug: "LUX 1 0455", name: "LUX 1 0455", thumbnail: lux1_0455 },
      { slug: "LUX 1 0456", name: "LUX 1 0456", thumbnail: lux1_0456 },
      { slug: "LUX 1 0471", name: "LUX 1 0471", thumbnail: lux1_0471 },
      { slug: "LUX 1 0472", name: "LUX 1 0472", thumbnail: lux1_0472 },
      { slug: "LUX 1 0478", name: "LUX 1 0478", thumbnail: lux1_0478 },
    ]
  },
  {
    category: 'cat. Lux',
    collection: 'Lux 2',
    items: [
      { slug: "LUX 2 0810", name: "LUX 2 0810", thumbnail: lux2_0810 },
      { slug: "LUX 2 0811", name: "LUX 2 0811", thumbnail: lux2_0811 },
      { slug: "LUX 2 0812", name: "LUX 2 0812", thumbnail: lux2_0812 },
      { slug: "LUX 2 0815", name: "LUX 2 0815", thumbnail: lux2_0815 },
      { slug: "LUX 2 0818", name: "LUX 2 0818", thumbnail: lux2_0818 },
      { slug: "LUX 2 0819", name: "LUX 2 0819", thumbnail: lux2_0819 },
      { slug: "LUX 2 0820", name: "LUX 2 0820", thumbnail: lux2_0820 },
      { slug: "LUX 2 0822", name: "LUX 2 0822", thumbnail: lux2_0822 },
      { slug: "LUX 2 0823", name: "LUX 2 0823", thumbnail: lux2_0823 },
      { slug: "LUX 2 0824", name: "LUX 2 0824", thumbnail: lux2_0824 },
      { slug: "LUX 2 0825", name: "LUX 2 0825", thumbnail: lux2_0825 },
      { slug: "LUX 2 0826", name: "LUX 2 0826", thumbnail: lux2_0826 },
      { slug: "LUX 2 0827", name: "LUX 2 0827", thumbnail: lux2_0827 },
      { slug: "LUX 2 0830", name: "LUX 2 0830", thumbnail: lux2_0830 },
      { slug: "LUX 2 0832", name: "LUX 2 0832", thumbnail: lux2_0832 },
      { slug: "LUX 2 0835", name: "LUX 2 0835", thumbnail: lux2_0835 },
      { slug: "LUX 2 0836", name: "LUX 2 0836", thumbnail: lux2_0836 },
      { slug: "LUX 2 0837", name: "LUX 2 0837", thumbnail: lux2_0837 },
      { slug: "LUX 2 0838", name: "LUX 2 0838", thumbnail: lux2_0838 },
      { slug: "LUX 2 0839", name: "LUX 2 0839", thumbnail: lux2_0839 },
      { slug: "LUX 2 0842", name: "LUX 2 0842", thumbnail: lux2_0842 },
      { slug: "LUX 2 0843", name: "LUX 2 0843", thumbnail: lux2_0843 },
      { slug: "LUX 2 0844", name: "LUX 2 0844", thumbnail: lux2_0844 },
      { slug: "LUX 2 0845", name: "LUX 2 0845", thumbnail: lux2_0845 },
      { slug: "LUX 2 0847", name: "LUX 2 0847", thumbnail: lux2_0847 },
      { slug: "LUX 2 0851", name: "LUX 2 0851", thumbnail: lux2_0851 },
      { slug: "LUX 2 0852", name: "LUX 2 0852", thumbnail: lux2_0852 },
      { slug: "LUX 2 0853", name: "LUX 2 0853", thumbnail: lux2_0853 },
      { slug: "LUX 2 0854", name: "LUX 2 0854", thumbnail: lux2_0854 },
      { slug: "LUX 2 0855", name: "LUX 2 0855", thumbnail: lux2_0855 },
      { slug: "LUX 2 0856", name: "LUX 2 0856", thumbnail: lux2_0856 },
      { slug: "LUX 2 0858", name: "LUX 2 0858", thumbnail: lux2_0858 },
      { slug: "LUX 2 0859", name: "LUX 2 0859", thumbnail: lux2_0859 },
      { slug: "LUX 2 0860", name: "LUX 2 0860", thumbnail: lux2_0860 },
      { slug: "LUX 2 0864", name: "LUX 2 0864", thumbnail: lux2_0864 },
      { slug: "LUX 2 0881", name: "LUX 2 0881", thumbnail: lux2_0881 },
    ]
  }
]

export default catLux