


import SISU_0105 from '../assets/catSpecial/Sisu/F_8088_C0105_W24.tif.jpg'
import SISU_0125 from '../assets/catSpecial/Sisu/F_8088_C0125_W24.tif.jpg'
import SISU_0165 from '../assets/catSpecial/Sisu/F_8088_C0165_W24.tif.jpg'
import SISU_0175 from '../assets/catSpecial/Sisu/F_8088_C0175_W24.tif.jpg'
import SISU_0195 from '../assets/catSpecial/Sisu/F_8088_C0195_W24.tif.jpg'
import SISU_0225 from '../assets/catSpecial/Sisu/F_8088_C0225_W24.tif.jpg'
import SISU_0375 from '../assets/catSpecial/Sisu/F_8088_C0375_W24.tif.jpg'
import SISU_0405 from '../assets/catSpecial/Sisu/F_8088_C0405_W24.tif.jpg'
import SISU_0525 from '../assets/catSpecial/Sisu/F_8088_C0525_W24.tif.jpg'
import SISU_0645 from '../assets/catSpecial/Sisu/F_8088_C0645_W24.tif.jpg'
import SISU_0655 from '../assets/catSpecial/Sisu/F_8088_C0655_W24.tif.jpg'
import SISU_0765 from '../assets/catSpecial/Sisu/F_8088_C0765_W24.tif.jpg'
import SISU_0775 from '../assets/catSpecial/Sisu/F_8088_C0775_W24.tif.jpg'
import SISU_0785 from '../assets/catSpecial/Sisu/F_8088_C0785_W24.tif.jpg'
import SISU_0805 from '../assets/catSpecial/Sisu/F_8088_C0805_W24.tif.jpg'
import SISU_0885 from '../assets/catSpecial/Sisu/F_8088_C0885_W24.tif.jpg'



import GENTLE_0113 from '../assets/catSpecial/Gentle2/F_13018_C0113_W24.jpg'
import GENTLE_0133 from '../assets/catSpecial/Gentle2/F_13018_C0133_W24.jpg'
import GENTLE_0183 from '../assets/catSpecial/Gentle2/F_13018_C0183_W24.jpg'
import GENTLE_0193 from '../assets/catSpecial/Gentle2/F_13018_C0193_W24.jpg'
import GENTLE_0203 from '../assets/catSpecial/Gentle2/F_13018_C0203_W24.jpg'
import GENTLE_0223 from '../assets/catSpecial/Gentle2/F_13018_C0223_W24.jpg'
import GENTLE_0373 from '../assets/catSpecial/Gentle2/F_13018_C0373_W24.jpg'
import GENTLE_0443 from '../assets/catSpecial/Gentle2/F_13018_C0443_W24.jpg'
import GENTLE_0553 from '../assets/catSpecial/Gentle2/F_13018_C0553_W24.jpg'
import GENTLE_0573 from '../assets/catSpecial/Gentle2/F_13018_C0573_W24.jpg'
import GENTLE_0623 from '../assets/catSpecial/Gentle2/F_13018_C0623_W24.jpg'
import GENTLE_0663 from '../assets/catSpecial/Gentle2/F_13018_C0663_W24.jpg'
import GENTLE_0673 from '../assets/catSpecial/Gentle2/F_13018_C0673_W24.jpg'
import GENTLE_0683 from '../assets/catSpecial/Gentle2/F_13018_C0683_W24.jpg'
import GENTLE_0733 from '../assets/catSpecial/Gentle2/F_13018_C0733_W24.jpg'
import GENTLE_0753 from '../assets/catSpecial/Gentle2/F_13018_C0753_W24.jpg'
import GENTLE_0783 from '../assets/catSpecial/Gentle2/F_13018_C0783_W24.jpg'
import GENTLE_0873 from '../assets/catSpecial/Gentle2/F_13018_C0873_W24.jpg'
import GENTLE_0933 from '../assets/catSpecial/Gentle2/F_13018_C0933_W24.jpg'
import GENTLE_0963 from '../assets/catSpecial/Gentle2/F_13018_C0963_W24.jpg'
import GENTLE_0973 from '../assets/catSpecial/Gentle2/F_13018_C0973_W24.jpg'


import TRIANGLE_0422 from '../assets/catSpecial/Triangle/F_13005_C0422_W24.tif.jpg'
import TRIANGLE_0672 from '../assets/catSpecial/Triangle/F_13005_C0672_W24.tif.jpg'
import TRIANGLE_0532 from '../assets/catSpecial/Triangle/F_13005_C0532_W24.tif.jpg'
import TRIANGLE_0292 from '../assets/catSpecial/Triangle/F_13005_C0292_W24.tif.jpg'
import TRIANGLE_0152 from '../assets/catSpecial/Triangle/F_13005_C0152_W24.tif.jpg'
import TRIANGLE_0192 from '../assets/catSpecial/Triangle/F_13005_C0192_W24.tif.jpg'
import TRIANGLE_0252 from '../assets/catSpecial/Triangle/F_13005_C0252_W24.tif.jpg'
import TRIANGLE_0572 from '../assets/catSpecial/Triangle/F_13005_C0572_W24.tif.jpg'
import TRIANGLE_0772 from '../assets/catSpecial/Triangle/F_13005_C0772_W24.tif.jpg'
import TRIANGLE_0732 from '../assets/catSpecial/Triangle/F_13005_C0732_W24.tif.jpg'
import TRIANGLE_0932 from '../assets/catSpecial/Triangle/F_13005_C0932_W24.tif.jpg'
import TRIANGLE_0232 from '../assets/catSpecial/Triangle/F_13005_C0232_W24.tif.jpg'
import TRIANGLE_0592 from '../assets/catSpecial/Triangle/F_13005_C0592_W24.tif.jpg'
import TRIANGLE_0792 from '../assets/catSpecial/Triangle/F_13005_C0792_W24.tif.jpg'
import TRIANGLE_0122 from '../assets/catSpecial/Triangle/F_13005_C0122_W24.tif.jpg'
import TRIANGLE_0972 from '../assets/catSpecial/Triangle/F_13005_C0972_W24.tif.jpg'
import TRIANGLE_0662 from '../assets/catSpecial/Triangle/F_13005_C0662_W24.tif.jpg'

const catSpecial = [
  
  {
    category: 'cat. Special',
    collection: 'Triangle',
    items: [
      { slug: "TRIANGLE BANANA 0422", name: "TRIANGLE BANANA 0422", thumbnail: TRIANGLE_0422 },
      { slug: "TRIANGLE BLUEBERRY 0672", name: "TRIANGLE BLUEBERRY 0672", thumbnail: TRIANGLE_0672 },
      { slug: "TRIANGLE BLUSH 0532", name: "TRIANGLE BLUSH 0532", thumbnail: TRIANGLE_0532 },
      { slug: "TRIANGLE BROWNIE 0292", name: "TRIANGLE BROWNIE 0292", thumbnail: TRIANGLE_0292 },
      { slug: "TRIANGLE CHALK 0152", name: "TRIANGLE CHALK 0152", thumbnail: TRIANGLE_0152 },
      { slug: "TRIANGLE CHARCOAL 0192", name: "TRIANGLE CHARCOAL 0192", thumbnail: TRIANGLE_0192 },
      { slug: "TRIANGLE COPPER 0252", name: "TRIANGLE COPPER 0252", thumbnail: TRIANGLE_0252 },
      { slug: "TRIANGLE FLAME 0572", name: "TRIANGLE FLAME 0572", thumbnail: TRIANGLE_0572 },
      { slug: "TRIANGLE HYDRO 0772", name: "TRIANGLE HYDRO 0772", thumbnail: TRIANGLE_0772 },
      { slug: "TRIANGLE ICE 0732", name: "TRIANGLE ICE 0732", thumbnail: TRIANGLE_0732 },
      { slug: "TRIANGLE OASE 0932", name: "TRIANGLE OASE 0932", thumbnail: TRIANGLE_0932 },
      { slug: "TRIANGLE PEBBLE 0232", name: "TRIANGLE PEBBLE 0232", thumbnail: TRIANGLE_0232 },
      { slug: "TRIANGLE RUMBA 0592", name: "TRIANGLE RUMBA 0592", thumbnail: TRIANGLE_0592 },
      { slug: "TRIANGLE SAFFIER 0792", name: "TRIANGLE SAFFIER 0792", thumbnail: TRIANGLE_0792 },
      { slug: "TRIANGLE SMOKE 0122", name: "TRIANGLE SMOKE 0122", thumbnail: TRIANGLE_0122 },
      { slug: "TRIANGLE SWAMP 0972", name: "TRIANGLE SWAMP 0972", thumbnail: TRIANGLE_0972 },
      { slug: "TRIANGLE VIOLET 0662", name: "TRIANGLE VIOLET 0662", thumbnail: TRIANGLE_0662 },
    ]
  },
  {
    category: 'cat. Special',
    collection: 'Gentle 2',
    items: [
      { slug: "GENTLE 2 0113", name: "GENTLE 2 0113", thumbnail: GENTLE_0113 },
      { slug: "GENTLE 2 0133", name: "GENTLE 2 0133", thumbnail: GENTLE_0133 },
      { slug: "GENTLE 2 0183", name: "GENTLE 2 0183", thumbnail: GENTLE_0183 },
      { slug: "GENTLE 2 0193", name: "GENTLE 2 0193", thumbnail: GENTLE_0193 },
      { slug: "GENTLE 2 0203", name: "GENTLE 2 0203", thumbnail: GENTLE_0203 },
      { slug: "GENTLE 2 0223", name: "GENTLE 2 0223", thumbnail: GENTLE_0223 },
      { slug: "GENTLE 2 0373", name: "GENTLE 2 0373", thumbnail: GENTLE_0373 },
      { slug: "GENTLE 2 0443", name: "GENTLE 2 0443", thumbnail: GENTLE_0443 },
      { slug: "GENTLE 2 0553", name: "GENTLE 2 0553", thumbnail: GENTLE_0553 },
      { slug: "GENTLE 2 0573", name: "GENTLE 2 0573", thumbnail: GENTLE_0573 },
      { slug: "GENTLE 2 0623", name: "GENTLE 2 0623", thumbnail: GENTLE_0623 },
      { slug: "GENTLE 2 0663", name: "GENTLE 2 0663", thumbnail: GENTLE_0663 },
      { slug: "GENTLE 2 0673", name: "GENTLE 2 0673", thumbnail: GENTLE_0673 },
      { slug: "GENTLE 2 0683", name: "GENTLE 2 0683", thumbnail: GENTLE_0683 },
      { slug: "GENTLE 2 0733", name: "GENTLE 2 0733", thumbnail: GENTLE_0733 },
      { slug: "GENTLE 2 0753", name: "GENTLE 2 0753", thumbnail: GENTLE_0753 },
      { slug: "GENTLE 2 0783", name: "GENTLE 2 0783", thumbnail: GENTLE_0783 },
      { slug: "GENTLE 2 0873", name: "GENTLE 2 0873", thumbnail: GENTLE_0873 },
      { slug: "GENTLE 2 0933", name: "GENTLE 2 0933", thumbnail: GENTLE_0933 },
      { slug: "GENTLE 2 0963", name: "GENTLE 2 0963", thumbnail: GENTLE_0963 },
      { slug: "GENTLE 2 0973", name: "GENTLE 2 0973", thumbnail: GENTLE_0973 },
    ]
  },
  {
    category: 'cat. Special',
    collection: 'Sisu',
    items: [
        { slug: "SISU 0105", name: "SISU 0105", thumbnail: SISU_0105 },
        { slug: "SISU 0125", name: "SISU 0125", thumbnail: SISU_0125 },
        { slug: "SISU 0165", name: "SISU 0165", thumbnail: SISU_0165 },
        { slug: "SISU 0175", name: "SISU 0175", thumbnail: SISU_0175 },
        { slug: "SISU 0195", name: "SISU 0195", thumbnail: SISU_0195 },
        { slug: "SISU 0225", name: "SISU 0225", thumbnail: SISU_0225 },
        { slug: "SISU 0375", name: "SISU 0375", thumbnail: SISU_0375 },
        { slug: "SISU 0405", name: "SISU 0405", thumbnail: SISU_0405 },
        { slug: "SISU 0525", name: "SISU 0525", thumbnail: SISU_0525 },
        { slug: "SISU 0645", name: "SISU 0645", thumbnail: SISU_0645 },
        { slug: "SISU 0655", name: "SISU 0655", thumbnail: SISU_0655 },
        { slug: "SISU 0765", name: "SISU 0765", thumbnail: SISU_0765 },
        { slug: "SISU 0775", name: "SISU 0775", thumbnail: SISU_0775 },
        { slug: "SISU 0785", name: "SISU 0785", thumbnail: SISU_0785 },
        { slug: "SISU 0805", name: "SISU 0805", thumbnail: SISU_0805 },
        { slug: "SISU 0885", name: "SISU 0885", thumbnail: SISU_0885 },
      ]
    }

]





export default catSpecial